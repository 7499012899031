import { Breadcrumb, Container } from "react-bootstrap";
import NavBar from "../Navbar/Navbar"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import img from '../../src/Images/img.png';
import Services from '../../src/Service/Service';
import moment from 'moment';
import quizImage from '../assets/img/values-1.png'
import swal from "sweetalert"
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../assets/Style.css'
import NewForm from "../NewForm/NewForm";
import { Logout } from "../Logout/Logout";

//import Globalfunction from '../config/Globalfunction';

const QuizDashboard = () => {
    let navigate = useNavigate();
    const [listQuizzes, setlistQuizzes] = useState([])
    // const deleteById = (id) => {
    //     swal({
    //         title: "Are you sure?",
    //         text: "Once deleted, you will not be able to recover this Quiz!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 Services.DeleteQuiz(id)
    //                     .then(async response => {
    //                         if (response.data.status == true) {
    //                             await swal("Success", response.data.msg, "success");
    //                             const updatedData = listQuizzes.filter(item => item.quizId !== id);
    //                             setlistQuizzes(updatedData)
    //                         } else {
    //                             await swal("Message!", response.data.msg, "info")
    //                         }
    //                     }).catch(async err => {
    //                         await swal("Message!", err.response.data.msg, "warning")
    //                     })
    //             } else {
    //                 swal("Your Quiz is safe!");
    //             }
    //         })
    // }

    // const fetchQuizData = () => {

    //     Services.GetQuizzes()
    //     .then(response => {
    //         setlistQuizzes(response.data);
    //         console.log(response.data);
    //     }).catch((err) => {
    //         //  swal("Message!", err.response.data, "warning");
    //         // alert("Server Error 500")
    //     })
    // }
    useEffect(() => {

        Services.GetQuizzes()
            .then(response => {
                setlistQuizzes(response.data);
                console.log(response.data);
            }).catch(err => {
                // if (err.response && err.response.status === 401) {
                //     const errorMessage = err.response.data.errors.UnAuthorized;

                //     swal("Error", errorMessage, "error");
                //     Services.Logout()
                //     .then(response => {
                //         if (response.status === 200) {
                //             sessionStorage.removeItem("token");
                //             sessionStorage.removeItem("LoggedIn");
                //             navigate("/");
                //         }
                //     })
                //     .catch(err => {
                //         // Handle error if needed
                //     });
                // }
                // else 
                if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    //swal("Message!", errorMessages, "warning");
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            });
    }, [])

    return (
        <div>
            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            Quiz
                        </li>
                    </ol>
                    <h2>Quiz</h2>

                </div>
            </section>

            <Container fluid className="wrapper">
                {/* <>
                    <h3 className="mt-3 text-secondary">Dashboard</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                </Link>
                            </li>

                            <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </ol>
                    </nav>
                    <hr />
                </> */}


                <Row>
                    <Col>

                        {/* <div className="container mt-2"> */}
                        {/* <div className="row"> */}
                        {
                            listQuizzes.length !== 0 ? (
                                <div id="services" className="services pricing">
                                    <div className="container" data-aos="fade-up">
                                        <div className="row gy-4">
                                            {listQuizzes.map((item, index) => {
                                                const cardClasses = ['blue', 'orange', 'green', 'red', 'purple', 'pink'];
                                                const className = cardClasses[index % cardClasses.length];
                                                return (
                                                    <div
                                                        className="col-lg-4 col-md-6"
                                                        data-aos="fade-up"
                                                        data-aos-delay={200}
                                                        key={index}
                                                    >
                                                        <div className={`service-box ${className}`}>
                                                            {/* <img src={quizImage} className="img-fluid" alt="" /> */}
                                                            <h3 className="text-capitalize mt-3">{item.quizTitle} Quiz</h3>
                                                            <div className="quiz-info d-flex fw-bold text-black text-center my-2">
                                                                <div className="questions border-end w-50">
                                                                    <p className="w-100 mb-0">{item.quizSize}</p>
                                                                    <small className="text-secondary hover-effect">Questions</small>
                                                                </div>
                                                                <div className="timer w-50">
                                                                    <p className="mb-0">{item.totalDuration} &nbsp;<small className="text-secondary hover-effect">min</small></p>
                                                                    <small className="text-secondary hover-effect">Duration</small>
                                                                </div>
                                                            </div>
                                                            <div className="quiz-info d-flex fw-bold text-black text-center my-2 p-2">
                                                                <div className="questions border-end w-50">
                                                                    <p className="w-100 mb-0 fs-6 text-success hover-effect">{item.startDate}</p>
                                                                    <small className="text-secondary hover-effect">Start Date</small>
                                                                </div>
                                                                <div className="timer w-50">
                                                                    <p className="mb-0 fs-6 text-danger hover-effect">{item.endDate}</p>
                                                                    <small className="text-secondary hover-effect">End Date</small>
                                                                </div>
                                                            </div>
                                                            <div className='text-center m-2'>
                                                                <Link to={`/dashboard/quiz/update-quiz/${item.quizId}`}>
                                                                    <button type="button" className="btn-buy"> <i className="fas fa-pen pl-1"></i> Edit</button>
                                                                </Link>
                                                            </div>
                                                            {/* <button className="btn btn-danger" onClick={() => deleteById(item.quizId)}>
                                                                <i className='fas fa-trash'>&nbsp;</i>Delete
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                    // </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="container">
                                    <div className="alert alert-info text-center mt-3 mb-3" role="alert">
                                        <h3>No Quizzes </h3>
                                    </div>
                                </div>
                            )
                        }

                        {/* </div> */}

                        {/* </div> */}

                    </Col>
                </Row>


            </Container>
        </div>
    )
}
export default QuizDashboard;
