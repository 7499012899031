import '../assets/Style.css'
import Quizz from '../../src/Images/Quizz.jpg'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ClientCaptcha from 'react-client-captcha';
import Services from '../../src/Service/Service'
import { useFormik, ErrorMessage } from 'formik';
import axios from 'axios';
import swal from "sweetalert"


const SignUp = () => {
  const [imageSrc, setImageSrc] = useState('');
  const [token, setcaptchaToken] = useState('');
  const [hash, setcaptchahash] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [randomString, setrandomString] = useState('');
  let navigate = useNavigate();


  const validate = (values) => {
    const errors = {}

    if (!values.name) {
      errors.name = 'Please Enter Full Name'
    } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
      errors.name = 'Only characters and spaces are allowed.';
    }


    if (!values.email) {
      errors.email = 'Please Enter Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (!values.mobile) {
      errors.mobile = 'Please Enter Mobile Number'
    } else if (!/^(91)?[6-9]\d{9}$/.test(values.mobile)) {
      errors.mobile = 'Mobile Number should contain 10 digits [0-9]'
    }

    if (!values.password) {
      errors.password = 'Please Enter Password'
    } else if (!/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,20}$/.test(values.password)) {
      errors.password = 'Password must contain atleast 8 charachters,1 Uppercase,1 lowercase,1 digit,1 special symbol and no white spaces'
    }

    if (!values.confirmpassword) {
      errors.confirmpassword = 'Please ReEnter Password'
    } else if (values.password != values.confirmpassword) {
      errors.confirmpassword = 'Password does not matches'
    }

    if (!values.captcha) {
      errors.captcha = 'Please Enter Captcha';
    }


    return errors
  }




  const GenerateCaptchaImage = () => {
    Services.GenerateCaptcha().then(async response => {
      const base64Data = response.data.captchaImage; // Assuming the response contains a 'image' field with base64 data
      const token = response.data.captchaToken;
      const hash = response.data.captchaHash;
      const randomString = response.data.randomString;
      setcaptchaToken(token);
      setcaptchahash(hash);
      setrandomString(randomString);
      const mimeType = 'image/png'; // Set the correct MIME type for the image
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType });

      const imageUrl = URL.createObjectURL(blob);
      setImageSrc(imageUrl);

    }).catch(err => {
      swal("Message!", "Server Error", "info")
    });

  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      password: '',
      confirmpassword: '',
      captcha: ''
    },
    validate,
    onSubmit: async (values, { setFieldValue }) => {
      let user = {
        name: formik.values.name,
        email: formik.values.email,
        mobile: formik.values.mobile,
        password: formik.values.password,
        captchaImage: values.captcha,
        captchaToken: token,
        captchaHash: hash,
        randomString: randomString
      };

      // const data = {
      //   captchaImage: values.captcha,
      //   captchaToken: token,
      //   captchaHash: hash
      // }
      // try {
      //   const response = await Services.ValidateCaptcha(data);

      //   if (response.status === 200) {
      //     const captchaValid = response.data.status;

      // if (captchaValid) {
      Services.SignUp(user)
        .then(async (response) => {
          if (response.status === 200) {
            await swal("Success", response.data.msg, "success");
            console.log("Registered");
            navigate("/login");
          } else {
            await swal("Message", response.data.msg, "info");
          }
        })
        .catch(async (err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            let errorMessages = Object.values(err.response.data.errors).join('\n');
            swal("Message!", errorMessages, "warning");
            GenerateCaptchaImage();
            setFieldValue('captcha', '');
          } else if (err.response && err.response.data && err.response.data.msg) {
            swal("Message!", err.response.data.msg, "warning");
          } else {
            swal("Message!", "Server error occurred", "warning");
          }
        });
      // } else {
      //   swal("Message!", "Server error occurred", "warning");
      // }
      // } else {
      //   // Handle non-200 response status
      //   swal("Message!", "Server error occurred", "warning");
      // }
      // } catch (error) {
      //   const errorDetails = error.response.data.errors;

      //   let displayErrorMessage = '';
      //   if (errorDetails && Object.keys(errorDetails).length > 0) {
      //     Object.keys(errorDetails).forEach((key) => {
      //       displayErrorMessage += `${key}: ${errorDetails[key]}\n`;
      //     });
      //   }

      //   swal("Message!", displayErrorMessage, "info");
      // }
    }
  });


  useEffect(() => {

    GenerateCaptchaImage();
  }, [])

  return (
    <div className="wrapper-home container">
      <form onSubmit={formik.handleSubmit} autocomplete="off">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card my-4">
              <div className="card-body cardbody-color p-lg-5">
                {/* <div className="text-center">
                                    <Link to="/">
                                        <img
                                            src={Quizz}
                                            className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                                            width="200px"
                                            alt="profile"
                                        />
                                    </Link>
                                </div> */}
                <div className='my-4'>
                  <h5 className='text-center'>Sign Up</h5>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className={formik.touched.name && formik.errors.name ? "form-control is-invalid" : "form-control"}
                    id="name"
                    name='name'
                    placeholder="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}

                  />
                  <label for="floatingInput">Name</label>
                  <div id="Namehelp" class="form-text">Your name will be displayed on certificate. Please enter without mistakes.</div>
                  {formik.touched.name && formik.errors.name && (
                    <span style={{ color: "red" }}> {formik.errors.name}</span>
                  )}

                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className={formik.touched.email && formik.errors.email ? "form-control is-invalid" : "form-control"}
                    id="EmailSignup"
                    name='email'
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}

                  />
                  <label for="floatingInput">Email</label>
                  {formik.touched.email && formik.errors.email && (
                    <span style={{ color: "red" }}> {formik.errors.email}</span>
                  )}

                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className={formik.touched.mobile && formik.errors.mobile ? "form-control is-invalid" : "form-control"}
                    id="Mobile"
                    name='mobile'
                    maxLength={10}
                    placeholder="Mobile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                  />
                  <label for="floatingInput">Mobile</label>
                  {formik.touched.mobile && formik.errors.mobile && (
                    <span style={{ color: "red" }}> {formik.errors.mobile}</span>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    className={formik.touched.password && formik.errors.password ? "form-control is-invalid" : "form-control"}
                    id="PasswordSignup"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <label for="floatingInput">Password</label>
                  {formik.touched.password && formik.errors.password && (
                    <span style={{ color: "red" }}> {formik.errors.password}</span>
                  )}

                  <div className="eye-icon-container">

                    {showPassword ? (
                      <i class="ri-eye-off-fill ri-lg" onClick={() => setShowPassword(!showPassword)}></i>
                    ) : (
                      <i className="ri-eye-fill ri-lg" onClick={() => setShowPassword(!showPassword)}></i>
                    )}

                  </div>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={formik.touched.confirmpassword && formik.errors.confirmpassword ? "form-control is-invalid" : "form-control"}
                    id="ConfirmPasswordSignup"
                    name='confirmpassword'
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmpassword}
                  />
                  <label for="floatingInput">Confirm Password</label>
                  {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                    <span style={{ color: "red" }}> {formik.errors.confirmpassword}</span>
                  )}
                </div>
                <div className='form-floating'>

                  <input type="text" className={formik.touched.captcha && formik.errors.captcha ? "form-control is-invalid" : "form-control"} name="captcha" maxLength="5"
                    placeholder='Captcha'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.captcha}
                  />
                  <label for="floatingInput">Captcha</label>
                  {formik.touched.captcha && formik.errors.captcha && (
                    <span style={{ color: "red" }}> {formik.errors.captcha}</span>
                  )}
                </div>
                <div className='d-flex justify-content-end my-3'>

                  {imageSrc && (
                    <>
                      <img src={imageSrc} alt="Image" width={200} />
                      <div className='border border-2'>
                        <i className="fa-solid fa-2xl fa-rotate-right mt-4 mx-3" onClick={GenerateCaptchaImage}></i>
                      </div>
                    </>
                  )}


                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-color px-5 mb-5 w-100">
                    Submit
                  </button>
                </div>
                <div id="emailHelp" className="form-text text-center mb-5 text-dark">
                  Already have an account?{" "}
                  <a href="/login" className="text-dark fw-bold">
                    {" "}
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  )
}

export default SignUp;