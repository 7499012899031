import '../assets/Style.css'
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Editor } from "@tinymce/tinymce-react";
import imageUpload from '../../src/Images/imageupload.png'
import plus from "../../src/Images/plus.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useFormik, ErrorMessage } from 'formik';
import Tooltip from 'react-bootstrap/Tooltip';
import { Multiselect } from "multiselect-react-dropdown";
import swal from "sweetalert"
import Services from '../../src/Service/Service';
import axios from 'axios';
// import Editor from '../Editor/Editor'
import React, { useRef } from 'react';
import API_BASE_URL from '../config/config';

const Fill = () => {
    const [addOption, setAddOption] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedQuizzes, setSelectedQuizzes] = useState([]);
    const [editorData, setEditorData] = useState('');
    const [checkFile, setCheckFile] = useState(false);
    const [checkUpload, setCheckUpload] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [listQuizzes, setListQuizzes] = useState([]);
    const [errors, setErrors] = useState({});
    const [getimagePath, setimagePath] = useState(null);
    const [getimageName, setimageName] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const { qId } = useParams();
    let navigate = useNavigate();
    const [selectedQuiz, setSelectedQuiz] = useState({});
    const editorRef = useRef(null);
    const scoreOptions = [
        {

            label: 'Score',
            value: '',
        },
        {
            label: "1",
            value: "1",
        },

        {
            label: "2",
            value: "2",
        },
        {
            label: "3",
            value: "3",
        },
        {
            label: "4",
            value: "4",
        },
        {
            label: "5",
            value: "5",
        },
    ]

    const levelOptions = [
        {

            label: 'Level',
            value: '',
        },
        {
            label: "Easy",
            value: "easy",
        },

        {
            label: "Medium",
            value: "medium",
        },
        {
            label: "Hard",
            value: "hard",
        },
    ]

    const addOptionHandler = () => {
        setClicked(!clicked);
        setAddOption(!addOption)
    }

    const onCancelHandler = () => {
        // navigate("/dashboard/total-questions");
        navigate(-1);
    }
    const [questionData, setQuestionData] = useState({
        questionTitletext: "",
        score: "",
        selectQuiz: "",
        level: "",
        correctOption: "",
        count: "",

    });

    const renderTooltipQuestion = (props) => (
        <Tooltip id="questionTitleText" {...props}>
            Enter Question
        </Tooltip>
    );

    const renderTooltipScore = (props) => (
        <Tooltip id="Score" {...props}>
            Please Select the Score to be given to the Question
        </Tooltip>
    );

    const renderTooltipLevel = (props) => (
        <Tooltip id="level" {...props}>
            Please Select the Level of the Question
        </Tooltip>
    );

    const renderTooltipSelectQuiz = (props) => (
        <Tooltip id="level" {...props}>
            Please Select the quiz
        </Tooltip>
    );

    const renderTooltipCorrect = (props) => (
        <Tooltip id="level" {...props}>
            Please enter correct answer
        </Tooltip>
    );

    const renderTooltipCount = (props) => (
        <Tooltip id="level" {...props}>
            Please enter total count
        </Tooltip>
    );

    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (name === 'questionTitletext' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        // Call validateField for other fields
        if (name === 'score' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        // if (name === 'selectQuiz' && value.trim().length > 0) {
        //     validateField(name, value, 'blur');
        // }

        if (name === 'level' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        if (name === 'correctOption' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        // if (name === 'option1' && value.trim().length > 0) {
        //     validateField(name, value, 'blur');
        // }
    };

    const validateField = (name, value) => {
        // perform validation logic for each field
        // set error message to errors state if validation fails
        let errorsCopy = { ...errors };

        switch (name) {
            case 'questionTitletext':
                if (!value.trim()) {
                    errorsCopy.questionTitletext = 'Question is required';
                } else {
                    delete errorsCopy.questionTitletext;
                }

                break;
            case 'score':
                if (!value.trim()) {
                    errorsCopy.score = 'Please select score ';
                } else {
                    delete errorsCopy.score;
                }
                break;

            // case 'selectQuiz':
            //     if (!value.trim()) {
            //         errorsCopy.selectQuiz = 'Please select quiz ';
            //     } else {
            //         delete errorsCopy.selectQuiz;
            //     }
            //     break;

            case 'level':
                if (!value.trim()) {
                    errorsCopy.level = 'Please select level ';
                } else {
                    delete errorsCopy.level;
                }
                break;

            case 'correctOption':
                if (!value.trim()) {
                    errorsCopy.correctOption = 'Correct Option is required ';
                } else {
                    delete errorsCopy.correctOption;
                }
                break;

            // case 'option1':
            //     if (!value.trim()) {
            //         errorsCopy.option1 = 'option1 is required ';
            //     } else {
            //         delete errorsCopy.option1;
            //     }
            //     break;

        }
        setErrors(errorsCopy);

    };



    const onValidateHandler = () => {
        const { score, selectQuiz, level, correctOption } = questionData;
        const { questionTitletext } = editorData;
        const errors = {};

        if (!editorData) {
            errors.questionTitletext = "Question is required";
        }


        if (score === "") {
            errors.score = "Please select score";
        }

        // if (selectQuiz === "") {
        //     errors.selectQuiz = "Please select quiz";
        // }

        if (level === "") {
            errors.level = "Please select level";
        }

        if (correctOption === "") {
            errors.correctOption = "Correct Option is required";
        }

        // if (option1 === "") {
        //     errors.option1 = "option1 is required";
        // }

        setErrors(errors);

        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setQuestionData({ ...questionData, [name]: value });

        if (event.target.name === "level") {
            setQuestionData(prevState => { return { ...prevState, level: event.target.value } })
        }

        if (event.target.name === "score") {
            setQuestionData(prevState => { return { ...prevState, score: event.target.value } })
        }
        if (event.target.name === "correctOption") {
            setQuestionData(prevState => { return { ...prevState, correctOption: event.target.value } })
        }

        if (event.target.name === "count") {
            setQuestionData(prevState => { return { ...prevState, count: event.target.value } })
        }

    };

    const imageHandler = (event) => {
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        const allowedExtensions = ["png", "jpeg", "jpg"]; // Allowed file extensions
        const fileExtension = file.name.split(".").pop().toLowerCase();
        const fileSizeLimit = 1 * 1024 * 1024;

        // Check if the file extension has double extensions
        const hasDoubleExtension = file.name.split(".").length > 2;
        if (hasDoubleExtension || !allowedExtensions.includes(fileExtension)) {
            swal("Message!", "Invalid file format", "info");
            setSelectedFile({
                ...selectedFile,
                questionImageUrl: null,
                questionImage: null,
                filename: null
            });
            setCheckFile(false);
            setCheckUpload(false);
            return;
        }



        if (file.size > fileSizeLimit) {
            swal("Message!", "Image size should not be more than 1 MB", "info");
            setSelectedFile({
                ...selectedFile,
                questionImageUrl: null,
                questionImage: null,
                filename: null
            });
            setCheckFile(false);
            setCheckUpload(false);
            return;
        }

        reader.onloadend = () => {
            const image = new Image();
            image.onload = function () {
                if (this.width > 0 && this.height > 0) {
                    setSelectedFile({
                        ...selectedFile,
                        questionImageUrl: reader.result,
                        questionImage: file,
                        filename: file.name
                    });
                    setCheckFile(true);
                    setCheckUpload(true);
                } else {
                    swal("Message!", "Invalid image file", "info");
                    setSelectedFile({
                        ...selectedFile,
                        questionImageUrl: null,
                        questionImage: null,
                        filename: null
                    });
                    setCheckFile(false);
                    setCheckUpload(false);
                }
            };
            image.onerror = function () {
                swal("Message!", "Invalid image file", "info");
                setSelectedFile({
                    ...selectedFile,
                    questionImageUrl: null,
                    questionImage: null,
                    filename: null
                });
                setCheckFile(false);
                setCheckUpload(false);
            };
            image.src = reader.result;
        };

        reader.readAsDataURL(file);
    };


    const onSubmitImageHandler = (events) => {
        events.preventDefault();
        let image = selectedFile.questionImage;

        // Check if the image exists
        if (!image) {
            swal("Message!", "Please choose an image", "info")
            return;
        }

        const allowedExtensions = ["png", "jpeg", "jpg"]; // Allowed file extensions
        const fileExtension = image.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            swal("Message!", "Invalid file format", "info");
            return;
        }




        const allowedMimeTypes = {
            jpeg: "image/jpeg",
            jpg: "image/jpeg",
            png: "image/png"
        };

        const expectedMimeType = allowedMimeTypes[fileExtension];
        if (!expectedMimeType || image.type !== expectedMimeType) {
            swal("Message!", "Invalid file format", "info");
            return;
        }



        let formData = new FormData();
        formData.append("file", image);
        Services.UploadImage(formData)
            .then(async response => {
                if (response.data.status == true) {
                    await swal("Success", response.data.msg, "success");
                    setCheckUpload(false);
                } else {
                    await swal("Message!", response.data.msg, "info")
                }
            }).catch(async err => {
                await swal("Message!", err.response.data.errors, "warning")
            })
    }



    const getEditorValue = (evt) => {
        setEditorData(evt.target.getContent({ format: "html" }));
        //console.log(evt.target.getContent({ format: "text" }));     
    };

    const removeImagePreview = () => {

        if (isEditMode) {

            Services.DeleteImageinQuestion(qId, getimageName)
                .then(async response => {
                    if (response.data.status == true) {
                        await swal("Success", response.data.msg, "success");
                        setimageName('NA');
                        setimagePath(null);
                        setCheckFile(false);
                    } else {
                        await swal("Message!", response.data.msg, "info")
                    }
                }).catch(async err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        Services.Logout()
                            .then(response => {
                                if (response.status === 200) {
                                    sessionStorage.removeItem("token");
                                    sessionStorage.removeItem("LoggedIn");
                                    navigate("/");
                                }
                            })
                            .catch(err => {
                                // Handle error if needed
                            });
                    }
                    else if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        swal("Message!", errorMessages, "warning");
                    } else if (err.response && err.response.data && err.response.data.msg) {
                        swal("Message!", err.response.data.msg, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                })
        } else {
            setSelectedFile({ questionImageUrl: null });
            setimagePath(null);
            setCheckFile(false);
        }
    };



    const quizSelectHandler = (event) => {
        const idx = event.target.selectedIndex;
        const option = event.target.querySelectorAll('option')[idx];
        const title = option.getAttribute('data-title');
        const selectedId = event.target.options[event.target.selectedIndex].id;

        if (event.target.name === "selectQuiz") {
            setQuestionData((prevState) => {
                return { ...prevState, selectQuiz: title, quizId: selectedId };
            });
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const formIsValid = onValidateHandler();
        let hasErrors = false;
        if (!selectedQuizzes || selectedQuizzes.length === 0) {
            setErrorMessage('Please select at least one quiz.');
            hasErrors = true;
        }
        if (hasErrors) {

            return;
        }
        if (formIsValid) {
            const { ...dataWithoutSelectQuiz } = questionData;
            const data = {
                ...dataWithoutSelectQuiz,
                questionTitletext: editorData,
                type: "fill",
                questionId: qId,
                imageName: selectedFile.filename,
                quizId: questionData.quizId,
                selectedQuizIds: selectedQuizzes.map(item => item.id),
            };
            const jsonData = JSON.stringify(data);
            if (isEditMode) {
                console.log("udata", data);
                Services.UpdateFillintheBlanks(data)

                    .then(async response => {

                        if (response.status === 200) {
                            await swal("Success", response.data.msg, "success");
                            setQuestionData({ score: '', level: '', selectQuiz: '', correctOption: '', count: '' })
                            setEditorData('');
                            setIsEditMode(false)
                            setSelectedFile({
                                ...selectedFile,
                                questionImageUrl: null,
                                questionImage: null,
                                filename: null
                            });
                            navigate(-1);
                        }
                        else {
                            await swal("Message", response.data.msg, "info")
                        }
                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            Services.Logout()
                                .then(response => {
                                    if (response.status === 200) {
                                        sessionStorage.removeItem("token");
                                        sessionStorage.removeItem("LoggedIn");
                                        navigate("/");
                                    }
                                })
                                .catch(err => {
                                    // Handle error if needed
                                });
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });


            } else {

                Services.FillintheBlanks(jsonData)

                    .then(async response => {

                        if (response.status === 200) {
                            await swal("Success", response.data.msg, "success");
                            setQuestionData({ score: '', level: '', selectQuiz: '', correctOption: '', count: '' })
                            setEditorData('');
                            setSelectedFile({
                                ...selectedFile,
                                questionImageUrl: null,
                                questionImage: null,
                                filename: null
                            });
                            setCheckFile(false);
                            navigate("/dashboard/quiz/total-questions")
                        }
                        else {
                            await swal("Message", response.data.msg, "info")
                        }
                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            Services.Logout()
                                .then(response => {
                                    if (response.status === 200) {
                                        sessionStorage.removeItem("token");
                                        sessionStorage.removeItem("LoggedIn");
                                        navigate("/");
                                    }
                                })
                                .catch(err => {
                                    // Handle error if needed
                                });
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });

            }
        }
    };



    useEffect(() => {
        Services.GetQuestionsByquestionId(qId)
            .then(response => {
                setQuestionData({ score: response.data.score, quizId: response.data.quizId, level: response.data.level, correctOption: response.data.correctOption, count: response.data.count });
                //   setEditorData(response.data.questionTitletext)
                console.log("sadsdf", response.data)
                const quizIdsAndNames = response.data.quizIdsAndNames;

                const selectedQuizzesArray = Object.keys(quizIdsAndNames).map(quizId => ({
                    id: quizId,
                    name: quizIdsAndNames[quizId]
                }));

                setSelectedQuizzes(selectedQuizzesArray);

                console.log("quizIdsAndNames", quizIdsAndNames);
                const content = response.data.questionTitletext;
                setEditorData(content);
                const imagepath = response.data.imageName;
                const filename = imagepath.substring(imagepath.lastIndexOf('/') + 1);
                if (response.data.imageName !== null && filename !== "NA") {
                    setCheckFile(true);
                    setimagePath(imagepath);
                    setimageName(filename);
                }




                setIsEditMode(true);
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
            });
    }, [qId])

    console.log("dasda", selectedQuizzes);

    const editimageUrl = `${API_BASE_URL}/uploads/${selectedFile.filename}`;
    useEffect(() => {

        Services.GetQuizzes().then(response => {
            const quizOptions = response.data.map(quiz => ({
                id: quiz.quizId,  // Use quizId as id
                name: quiz.quizTitle  // Use quizTitle as the display name
            }));
            setListQuizzes(quizOptions);
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                Services.Logout()
                    .then(response => {
                        if (response.status === 200) {
                            sessionStorage.removeItem("token");
                            sessionStorage.removeItem("LoggedIn");
                            navigate("/");
                        }
                    })
                    .catch(err => {
                        // Handle error if needed
                    });
            } else if (err.response && err.response.data && err.response.data.errors) {
                let errorMessages = Object.values(err.response.data.errors).join('\n');
                swal("Message!", errorMessages, "warning");
            } else if (err.response && err.response.data && err.response.data.msg) {
                swal("Message!", err.response.data.msg, "warning");
            } else {
                swal("Message!", "Server error occurred", "warning");
            }
        })
    }, [])

    const handleSelect = (selectedList) => {
        setSelectedQuizzes(selectedList);
        handleMultiselectBlur(selectedList);
    };

    const handleRemove = (selectedList) => {
        setSelectedQuizzes(selectedList);
        handleMultiselectBlur(selectedList);
    };

    const handleMultiselectBlur = (selectedList) => {
        if (!selectedList || selectedList.length === 0) {
            setErrorMessage('Please select at least one quiz.');
        } else {
            setErrorMessage('');
        }
    };

    return (

        <div>


            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                DashBoard
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/quiz">
                                Quiz
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/quiz/create-questions">
                                Create Question
                            </Link>
                        </li>
                        {!isEditMode ?
                            <li aria-current="page">Fill in the blanks</li>
                            :
                            <li aria-current="page"> Update Fill in the blanks</li>}

                    </ol>
                    {!isEditMode ?
                        <h2>Fill in the blanks</h2>
                        : <h2>Update Fill in the blanks</h2>
                    }

                </div>
            </section>

            <div className="container-xxl wrapper">

                <form onSubmit={handleSubmit} autoComplete="off">

                    <div className="row">
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-leftside-body">
                                    <div className='col-md-12'>

                                        <h3 className='required'>Question
                                            {/* <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipQuestion}
                                            >
                                                <i className="fa fa-info-circle" style={{ paddingLeft: "3px" }}></i>
                                            </OverlayTrigger> */}

                                        </h3>
                                        <Editor
                                            apiKey="uccq416s6f5b64u1f3jtpdocbt3f7120y7w4zfmtzqxfcvyk"
                                            //onInit={(evt, editor) => editorRef.current = editor}
                                            name="questionTitletext"

                                            onBlur={(evt) => handleBlur({ target: { name: 'questionTitletext', value: evt.target.getContent({ format: 'text' }) } })}
                                            initialValue={editorData}
                                            onChange={getEditorValue}
                                            init={{
                                                height: 300,
                                                auto_focus: true,
                                                menubar: false,
                                                max_width: 300,
                                                skin: "oxide-dark",
                                                mobile: {
                                                    menubar: true,
                                                    theme: 'mobile'
                                                },
                                                branding: false,
                                                plugins: [
                                                    'codesample'
                                                ],
                                                toolbar:
                                                    'undo redo codesample| casechange blocks | bold italic backcolor | \
                                                     alignleft aligncenter alignright alignjustify | \
                                                     bullist numlist checklist outdent indent | removeformat |',
                                                elementpath: false,
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                                            }}
                                        />
                                        <small>
                                            Enter Question
                                        </small>
                                        {errors.questionTitletext && <div className='text-danger'>{errors.questionTitletext}</div>}


                                    </div>
                                    <div className='row mb-2 mt-4'>
                                        <div className='col-md-4'>
                                            <h3 className='required'>Score
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipScore}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <div className="custom-select">
                                                <select className={errors.score ? "form-select is-invalid" : "form-select"} data-title="Score" name='score' onBlur={handleBlur} onChange={handleChange} value={questionData.score}>
                                                    {scoreOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <small>
                                                Please select the score to be given to the question
                                            </small>
                                            <div className={errors.score ? "invalid-feedback d-block" : "invalid-feedback d-none"}>
                                                {errors.score && <span>{errors.score}</span>}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <h3 className='required'>Select Quizzes</h3>
                                            <div className='question-multiselect'>
                                                <div className="multiselect-container">
                                                    <div className="input-icon-container">
                                                        <Multiselect
                                                            name="selectQuiz"
                                                            options={listQuizzes}
                                                            selectedValues={selectedQuizzes}
                                                            onSelect={handleSelect}
                                                            onRemove={handleRemove}
                                                            displayValue="name"
                                                            showCheckbox={true}
                                                            searchable={true}
                                                            hidePlaceholder
                                                            onBlur={handleMultiselectBlur}
                                                            className={errorMessage ? 'error-border' : ''}
                                                        />

                                                        <small >
                                                            Please select the quiz
                                                        </small>

                                                        <div className="error-select">
                                                            {errorMessage && <span className='text-danger'>{errorMessage}</span>}
                                                            {errorMessage && <i className="ri-error-warning-line error-icon"></i>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <h3 className='required'>Level
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipLevel}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <div className="custom-select">
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipLevel}
                                                > */}
                                                <select className={errors.level ? "form-select is-invalid" : "form-select"} data-title="level" name='level' onBlur={handleBlur} onChange={handleChange} value={questionData.level}>
                                                    {levelOptions.map((option) => (
                                                        <option style={{ background: "white", color: "black" }} key={option.value} value={option.value}>{option.label}</option>
                                                    ))}

                                                </select>
                                                {/* </OverlayTrigger> */}
                                            </div>
                                            <small>
                                                Please select the level of question
                                            </small>
                                            <div className={errors.level ? "invalid-feedback d-block" : "invalid-feedback d-none"}>
                                                {errors.level && <span>{errors.level}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-4'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>Correct Answer
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipCorrect}
                                                    >
                                                        <i className="fa fa-info-circle" style={{ paddingLeft: "3px" }}></i>
                                                    </OverlayTrigger> */}
                                                </h3>

                                                <input
                                                    type="text"
                                                    className={errors.correctOption ? "form-control is-invalid" : "form-control"}
                                                    id="options"
                                                    name="correctOption"
                                                    value={questionData.correctOption}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <small>
                                                    Please enter correct answer of the question
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.correctOption && <span>{errors.correctOption}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3>Count
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipCount}
                                                    >
                                                        <i className="fa fa-info-circle" style={{ paddingLeft: "3px" }}></i>
                                                    </OverlayTrigger> */}
                                                </h3>
                                                <input
                                                    type="number"
                                                    className={errors.count ? "form-control is-invalid" : "form-control"}
                                                    id="options"
                                                    name='count'
                                                    value={questionData.count}
                                                    // onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <small>
                                                    Please enter count
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.count && <span>{errors.count}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="card">
                                <div className="alert alert-primary" role="alert">
                                    <h5>Instructions :</h5>
                                    <ul>
                                        <li>Accepted file formats are .png,.jpg,.jpeg</li>
                                        <li>Image size should not be more than 1 MB</li>

                                    </ul>
                                </div>
                                <div className="card-body d-flex justify-content-center">

                                    <div>
                                        <h3 className='text-center pb-3'>Image Upload</h3>
                                        <div className='col-md-12 d-flex justify-content-center'>
                                            <div className="drop-area">
                                                <label>

                                                    <img src={imageUpload} />
                                                    <input type="file" name="file" onChange={imageHandler} />
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className='text-center navbar'>
                                            <a className="getstarted m-2 px-5" href="#about">
                                                Upload Image
                                            </a>
                                        </div> */}
                                        <div className="col-md-12 text-center">
                                            <button onClick={onSubmitImageHandler} className='btn-theme px-5 mt-2'><i className="fa-solid fa-upload"></i> Upload</button>
                                        </div>
                                        <div className='col-md-12'>
                                            {isEditMode && (getimagePath && getimageName !== "NA") ? (
                                                // Edit mode with getimagePath and getimageName available
                                                <div className='border border-secondary mt-2 pb-3'>
                                                    <div className='text-end'>
                                                        <i className="fa-fw fa-solid fa-circle-xmark" onClick={removeImagePreview}></i>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img
                                                            src={getimagePath}
                                                            alt="Preview"
                                                            className="img-responsive text-center"
                                                            width={250}
                                                            height={200}
                                                        />
                                                    </div>
                                                    {checkFile ? (
                                                        <div className="text-center mt-3">
                                                            <div>
                                                                <h3>{getimageName}</h3>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : !isEditMode && selectedFile.questionImageUrl ? (
                                                // Not in edit mode with selectedFile.questionImageUrl available
                                                <div className='border border-secondary mt-2 pb-3'>
                                                    <div className='text-end'>
                                                        <i className="fa-fw fa-solid fa-circle-xmark" onClick={removeImagePreview}></i>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img
                                                            src={selectedFile.questionImageUrl}
                                                            alt="Preview"
                                                            className="img-responsive"
                                                            width={250}
                                                            height={200}
                                                        />
                                                    </div>
                                                    {checkFile ? (
                                                        <div className="text-center mt-3">
                                                            <div>
                                                                <h3>{selectedFile.filename}</h3>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : (isEditMode && selectedFile.questionImageUrl ? (
                                                // Edit mode with selectedFile.questionImageUrl available
                                                <div className='border border-secondary mt-2 pb-3'>
                                                    <div className='text-end'>
                                                        <i className="fa-fw fa-solid fa-circle-xmark" onClick={removeImagePreview}></i>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img
                                                            src={selectedFile.questionImageUrl}
                                                            alt="Preview"
                                                            className="img-responsive"
                                                            width={250}
                                                            height={200}
                                                        />
                                                    </div>
                                                    {checkFile ? (
                                                        <div className="text-center mt-3">
                                                            <div>
                                                                <h3>{selectedFile.filename}</h3>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null)}
                                        </div>

                                        {checkFile && checkUpload ? (
                                            <div className="alert alert-primary fw-bold mt-4" role="alert">
                                                <i className="bi bi-exclamation-circle-fill p-1"></i>
                                                To upload a picture, please click on the "Upload" button. The image displayed above is a preview.
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end mt-4">
                        {isEditMode ? (
                            <>
                                <div className='m-1'>
                                    <button type="button" className="btn btn-lg btn-danger" onClick={onCancelHandler}>
                                        Cancel
                                    </button>
                                </div>

                                <div className='m-1'>
                                    <button type="submit" className="btn btn-lg btn-success">
                                        Update
                                    </button>
                                </div>

                            </>
                        ) : (
                            <button type="submit" className="btn btn-lg btn-success">
                                Submit
                            </button>
                        )}
                    </div>



                    {/* <div className='row'>
                        <div className='col-md-12'>
                          

                        </div>
                        <div className='col-md-12'>
                            <button type="button" class="btn btn-success">
                                Submit
                            </button>

                        </div>
                    </div> */}
                </form>
            </div>
        </div >

    )
}
export default Fill;