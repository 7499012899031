import Sample from "../File/sample.xlsx"
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Multiselect } from "multiselect-react-dropdown";
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import { read, utils, writeFile } from 'xlsx';
import ExcelJS from 'exceljs';



const BulkUpload = () => {
    const fileInputRef = React.createRef();
    const [listQuizzes, setListQuizzes] = useState([]);
    const [selectedQuizzes, setSelectedQuizzes] = useState([]);
    const [excelData, setExcelData] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [excel, setExcel] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorCheckMessage, setErrorCheckMessage] = useState(false);
    const [errorFileMessage, setErrorFileMessage] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [checkBox, setCheckBox] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState({});
    const [error, setError] = useState(null);
    const SampleHeaderValues = ["questionTitletext", "option1", "option2", "option3", "option4", "option5", "option6", "correctOption", "level", "type", "imageName", "score"];
    let navigate = useNavigate();

    const quizSelectHandler = (event) => {
        const idx = event.target.selectedIndex;
        const option = event.target.querySelectorAll('option')[idx];
        const title = option.getAttribute('data-title');
        const selectedId = event.target.options[event.target.selectedIndex].id;

        if (event.target.name === "selectQuiz") {
            console.log("Yes! I am getting the Quiz ID After Select : ", event.target.value)
            setSelectedQuiz(prevState => { return { ...prevState, quizId: selectedId } })
        }

        if (event.target.name === "selectQuiz") {
            setSelectedQuiz(prevState => { return { ...prevState, selectQuiz: title } })
        }


    }



    const handleBlur = (event) => {
        switch (event.target.name) {
            case "selectQuiz":
                if (!event.target.value) {
                    setErrorMessage(true);
                } else {
                    setErrorMessage(false);
                }
                break;
            case "choosefile":
                if (!event.target.value) {
                    setErrorFileMessage("Please choose a file");
                } else {
                    const file = event.target.files[0];
                    const fileSize = file.size / 1024 / 1024; // Size in MB
                    const fileName = file.name;
                    const allowedExtensions = ['xlsx'];

                    if (fileName.includes(".")) {
                        const extensions = fileName.split(".");
                        const fileExtension = extensions[extensions.length - 1];
                        const allowedMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

                        if (extensions.length > 2 || fileExtension.includes(".")) {
                            setErrorFileMessage("File should not have a double extension");
                        }
                        else if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
                            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                        }
                        else if (!allowedMimeTypes.includes(file.type)) {
                            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                        }
                        else if (fileSize > 2) {
                            setErrorFileMessage("File size should not exceed 2MB");
                        }
                        else {
                            // Verify the file contents using XLSX library
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                try {
                                    const data = new Uint8Array(e.target.result);
                                    const workbook = XLSX.read(data, { type: "array" });
                                    setErrorFileMessage(false);
                                } catch (error) {
                                    setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                                }
                            };
                            reader.readAsArrayBuffer(file);
                        }
                    } else {
                        setErrorFileMessage(false);
                    }
                }
                break;
        }
    };




    const handleImport = (e) => {
        const file = e.target.files[0];
        const fileSizeLimit = 2 * 1024 * 1024; // 2MB
        const expectedFileExtension = '.xlsx';
        const expectedMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        console.log("type", file.type);



        if (file.type !== expectedMimeType || !file.name.toLowerCase().endsWith(expectedFileExtension)) {
            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
            setExcelData(null);
            setFileData(file);
            setCheckBox(false);
            return;
        }


        const fileName = file.name;
        const hasDoubleExtension = fileName.includes(".", fileName.indexOf(".") + 1);

        // Check for double extension
        if (hasDoubleExtension) {
            setErrorFileMessage("File should not have a double extension");
            setExcelData(null);
            setFileData(file);
            setCheckBox(false);
            return;
        }

        if (file.size > fileSizeLimit) {
            setErrorFileMessage("File size should not exceed 2MB");
            setExcelData(null);
            setFileData(file);
            setCheckBox(false);
            return;
        }


        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            try {
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                setFileData({
                    ...fileData,
                    name: file.name,
                    size: file.size,
                    file: file,

                });


                // Extract headers from the first row of the sheet         
                const headers = [];
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                let C;
                const R = range.s.r; /* start in the first row */
                for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                    const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
                    const hdr = XLSX.utils.format_cell(cell); /* format the cell to get the header */
                    headers.push(hdr);
                }

                // Compare header values with sample values
                const isMatch = headers.every((header, index) => header === SampleHeaderValues[index]);
                if (!isMatch) {
                    setError("Improper file. Try with sample file");
                    setExcelData(null);
                    setCheckBox(false);
                    return;
                }

                // Extract data from the sheet, skipping the first row
                const dataRows = XLSX.utils.sheet_to_json(worksheet, { header: headers, range: 1 });
                setExcelData(dataRows);
                setCheckBox(true);
                setExcel(true)
                setError(null);
            } catch (error) {
                setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                setFileData(file);
                setExcelData(null);
                setCheckBox(false);
            }
        };

        reader.readAsArrayBuffer(file);


    };




    const onValidateHandler = (event) => {
        event.preventDefault();

        if (!selectedQuizzes || selectedQuizzes.length === 0 || !fileData || !fileData.name) {
            if (!selectedQuizzes || selectedQuizzes.length === 0) {
                setErrorMessage('Please select at least one quiz.');

            }
            if (!fileData || !fileData.name) {
                setErrorFileMessage("Please choose a file");
            }
            return; // Stop form submission if validation fails
        }

        const fileName = fileData.name;
        const doubleExtensionRegex = /\.[^.\\/]+?\.[^.\\/]+$/;

        const fileExtension = fileName.split('.').pop();
        if (fileExtension.toLowerCase() !== "xlsx") {
            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
            return; // Stop form submission if validation fails
        }

        if (doubleExtensionRegex.test(fileName)) {
            setErrorFileMessage("File should not have a double extension");
            return; // Stop form submission if validation fails
        }


        if (fileData.size > 2 * 1024 * 1024) {
            setErrorFileMessage("File size should not exceed 2MB");
            return; // Stop form submission if validation fails
        }




        handleSubmit();
    };





    const handleSubmit = (e) => {


        let fileexcel = fileData.file;
        const { quizId } = selectedQuiz;
        const excelUploadData = {
            quizId: quizId
        };
        const myString = JSON.stringify(excelUploadData);
        let formData = new FormData();
        formData.append("file", fileexcel);
        formData.append("excelUpload", myString);




        if (excel === true) {
            if (isChecked) { // Check if isChecked is true
                Services.BulkUpload(formData)
                    .then(async response => {
                        if (response.status === 200) {
                            await swal("Success", response.data.msg, "success");
                            setSelectedQuiz({ selectQuiz: "" });
                            fileInputRef.current.value = '';
                            setExcelData(false);
                            setCheckBox(false);
                            setIsChecked(false);
                        } else {
                            await swal("Message", response.data.msg, "info");
                        }
                    })
                    .catch(err => {
                        swal("Message!", err.response.data.msg, "warning");
                    });
            } else {
                setErrorCheckMessage(true); // Set setErrorCheckmessage to true
            }
        } else {
            swal("Oops!", "Improper file. Try with a sample file", "error");
        }
    };



    // const columns = SampleHeaderValues.map(header => ({
    //     name: header,
    //     selector: header,
    // }));

    // const data = excelData.map((row, index) => {
    //     const rowData = {};
    //     SampleHeaderValues.forEach(header => {
    //         rowData[header] = row[header];
    //     });
    //     return rowData;
    // });

    const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            }
        },
        headCells: {
            style: {
                fontSize: '17px',
                fontWeight: '500',
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false'
            },
        },
        headRow: {
            style: {
                backgroundColor: 'darkgray', // Set the background color of the header row
            },
        },
    };

    const downloadFile = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = Sample;
        link.download = 'sample.xlsx';
        link.click();
    };

    useEffect(() => {

        Services.GetQuizzes().then(response => {
            const quizOptions = response.data.map(quiz => ({
                id: quiz.quizId,  // Use quizId as id
                name: quiz.quizTitle  // Use quizTitle as the display name
            }));
            setListQuizzes(quizOptions);
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                Services.Logout()
                    .then(response => {
                        if (response.status === 200) {
                            sessionStorage.removeItem("token");
                            sessionStorage.removeItem("LoggedIn");
                            navigate("/");
                        }
                    })
                    .catch(err => {
                        // Handle error if needed
                    });
            }
        })
    }, [])

    const handleSelect = (selectedList) => {
        setSelectedQuizzes(selectedList);
        handleMultiselectBlur(selectedList);
    };

    const handleRemove = (selectedList) => {
        setSelectedQuizzes(selectedList);
        handleMultiselectBlur(selectedList);
    };

    const handleMultiselectBlur = (selectedList) => {
        if (!selectedList || selectedList.length === 0) {
            setErrorMessage('Please select at least one quiz.');
        } else {
            setErrorMessage('');
        }
    };

    return (
        <div className="Quiz-admin">
            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                DashBoard
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/quiz">
                                Quiz
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/quiz/create-questions">
                                Create Question
                            </Link>
                        </li>
                        <li aria-current="page">Bulk Upload</li>

                    </ol>
                    <h2>Bulk Upload</h2>

                </div>
            </section>
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="alert alert-primary mt-3" role="alert">
                        <h5>Instructions</h5>
                        <ul>
                            <li>Only .xlsx file format is accepted</li>
                            <li>Allowed file size is upto 2 MB</li>
                            <li>Minimum two options are compulsory.Questions with no two options will be skipped</li>
                            <li>If score value is empty then the default score value will be taken as 1</li>
                            <li>If level value is empty then the default level value will be taken as easy</li>
                            <li>If type value is empty then the default type value will be taken as single</li>
                            <li>Images cannot be added through bulk upload, if images need to be included in a question, they must be added manually.</li>

                        </ul>
                    </div>
                    <div className="card">
                        <form onSubmit={onValidateHandler}>
                            <div className="card-leftside-body">
                                <div className="row">
                                    <div className="col-md-4 custom-select-quiz">
                                        <h3 className="required">Quiz Name</h3>
                                        <div className="multiselect-container">
                                            <div className="input-icon-container">
                                                <Multiselect
                                                    name="selectQuiz"
                                                    options={listQuizzes}
                                                    selectedValues={selectedQuizzes}
                                                    onSelect={handleSelect}
                                                    onRemove={handleRemove}
                                                    displayValue="name"
                                                    showCheckbox={true}
                                                    searchable={true}
                                                    hidePlaceholder
                                                    onBlur={handleMultiselectBlur}
                                                    className={errorMessage ? 'error-border' : ''}
                                                />

                                                <small >
                                                    Please select the quiz
                                                </small>
                                                <div className="error-select">
                                                    {errorMessage && <span className='text-danger'>{errorMessage}</span>}
                                                    {errorMessage &&<i className="ri-error-warning-line error-icon"></i>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <h3 className="required">Quiz Questions</h3>
                                        <input className={errorFileMessage ? "form-control is-invalid" : "form-control"} ref={fileInputRef} type="file" name="choosefile" onChange={handleImport} onBlur={handleBlur} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                        <div className="form-text">Allowed file type is .xlsx with size limit upto 2MB</div>
                                        <div className="invalid-feedback">
                                            {errorFileMessage && <span className='text-danger'>{errorFileMessage}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <h3 className="required">Sample File</h3>
                                        <div>
                                            <button className="btn-theme-download" onClick={downloadFile}><i className="fas fa-download p-1"></i>Download Sample Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <strong>{error}</strong>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-md-12 table-responsive mt-4 ">
                                        {excelData && (
                                            // <table className="table">
                                            //     <thead>
                                            //         {SampleHeaderValues.map(header => <th key={header}>{header}</th>)}
                                            //     </thead>
                                            //     <tbody>
                                            //         {excelData.map((row, index) => (
                                            //             <tr className="table-info" key={index}>
                                            //                 {SampleHeaderValues.map((header, index) => <td key={header}>{row[header]}</td>)}
                                            //             </tr>
                                            //         ))}
                                            //     </tbody>
                                            // </table>

                                            <DataTable
                                                columns={[
                                                    {
                                                        name: "Sr No.",
                                                        selector: (row, index) => index + 1,
                                                        sortable: false,
                                                    },
                                                    ...SampleHeaderValues.map(header => ({
                                                        name: header,
                                                        selector: row => {
                                                            if (header === "score" && !row[header]) {
                                                                return 1;
                                                            } else if (header === "level" && !row[header]) {
                                                                return "easy";
                                                            } else if (header === "type" && !row[header]) {
                                                                return "single";
                                                            }
                                                            return row[header];
                                                        },
                                                        sortable: true,
                                                    })),
                                                ]}
                                                data={excelData}
                                                customStyles={customStyles}
                                                responsive
                                                pagination
                                                paginationPerPage={10}
                                            />
                                        )}
                                        {checkBox === true && !error ?
                                            <div className="mb-3 form-check">
                                                <input type="checkbox" className={errorCheckMessage ? "form-check-input is-invalid" : "form-check-input"} id="exampleCheck1" checked={isChecked} onChange={(event) => setIsChecked(event.target.checked)} />

                                                I,confirm that i have read the instructions and verified the questions
                                                <div className="invalid-feedback">
                                                    {errorCheckMessage && <span className='text-danger'>Please select the checkbox</span>}
                                                </div>
                                            </div>
                                            : ""}
                                    </div>

                                </div>
                                <div className="text-center">
                                    <button className="btn btn-lg btn-success" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
        </div>
    )
}

export default BulkUpload;