import Home from "../Home/Home";
import { Routes, Route, Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import SignUp from "../../src/SignUp/SignUp.js";
import Login from "../../src/Login/Login.js";
import NavBar from "../Navbar/Navbar";
import ResetPassword from "../ResetPassword/ResetPassword";
import ForgetPassword from "../ForgetPassword/ForgetPassword";
import Header from "../Header/Header";
const HomeRouter = () => {
    return (
        <div>
            <Header/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path ="/login/:qId" element={<Login />} /> 
                <Route path ="/forgot-password" element={<ForgetPassword />} /> 
                <Route path="/reset-password/:code" element={<ResetPassword/>} />
            </Routes>
            <Footer />
        </div>
    )
}

export default HomeRouter;