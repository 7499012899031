import Keycloak from "keycloak-js";
import QuizResponseRouter from "../QuizResponse/QuizResponseRouter";



const _kc = new Keycloak('/keycloak.json');


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    // onLoad: 'login-required',
    // onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {     
      // onAuthenticatedCallback();
      if (authenticated) {      
      //  const token = UserService.getToken();
      //  sessionStorage.setItem("token", token);
      //  sessionStorage.setItem("LoggedIn", true);
      //  const userRole = UserService.getTokenParsed().resource_access.cakesldap.roles[0];

      //   if(userRole === 'learner'){
         
      //   }
         console.log("tok",_kc.token);
       


      } else {
        // UserService.doLogin();
      }
    })
    .catch(console.error);
};

const doLogin = _kc.login;


const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);
    console.log("Token parsed", _kc);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
 
};

export default UserService;