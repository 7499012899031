import { Link, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useFormik, ErrorMessage } from 'formik';
import swal from "sweetalert"
import Services from '../../src/Service/Service'
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import '../assets/Style.css'
import moment from 'moment';
import axios from 'axios';
import AssignQuiz from '../AssignQuiz/AssignQuiz';

const CreateQuiz = () => {
    const [isAdaptive, setIsAdaptive] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errors, setErrors] = useState({});
    const [errorAddaptive, setErrorAddaptive] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [isAssignedQuiz,setIsAssignedQuiz]=useState(false);
    const [show1, setShow1] = useState(false);
    const [listCertificates, setlistCertificates] = useState([]);
    const [selectedCertificate, setSelectedCertificate] = useState('');
    const { qId } = useParams();
    let navigate = useNavigate();
    const [getAddaptive, setAddaptive] = useState({
        isTrue: false,
        "easy": "",
        "medium": "",
        "hard": ""
    })

    const handleClose1 = () => {
        setShow1(false);
    }
    const handleShow1 = () => setShow1(true);
    const [quizData, setQuizData] = useState({
        quizTitle: "",
        shortCode: "",
        description: "",
        noOfAttempts: "",
        totalDuration: "",
        quizSize: "",
        passPercentage: "",
        shuffle: false,
        showScore: false,
        publish: true,
        quizType: ""

    });



    const [totalAdaptiveQuestions, setTotalAdaptiveQuestions] = useState({
        easy: 0,
        medium: 0,
        hard: 0
    });

    // const renderTooltipTitle = (props) => (
    //     <Tooltip id="Title" {...props}>
    //         Enter Quiz Title
    //     </Tooltip>
    // );

    // const renderTooltipShortCode = (props) => (
    //     <Tooltip id="Scode" {...props}>
    //         Enter Quiz Short Code
    //     </Tooltip>
    // );

    // const renderTooltipDescription = (props) => (
    //     <Tooltip id="Desc" {...props}>
    //         Enter Description for Quiz
    //     </Tooltip>
    // );

    // const renderTooltipNoofAttempts = (props) => (
    //     <Tooltip id="NOA" {...props}>
    //         Enter the No Of Attempts
    //     </Tooltip>
    // );

    // const renderTooltipTotalDuration = (props) => (
    //     <Tooltip id="Duration" {...props}>
    //         Enter Total Duration in minutes
    //     </Tooltip>
    // );

    // const renderTooltipQuizSize = (props) => (
    //     <Tooltip id="Size" {...props}>
    //         Enter Quiz Size
    //     </Tooltip>
    // );

    // const renderTooltipPassPercentage = (props) => (
    //     <Tooltip id="Percentage" {...props}>
    //         Enter Pass Percentage
    //     </Tooltip>
    // );

    // const renderTooltipShuffle = (props) => (
    //     <Tooltip id="shuffleQ" {...props}>
    //         Show different questions on each visits
    //     </Tooltip>
    // );

    // const renderTooltipScore = (props) => (
    //     <Tooltip id="scoreQ" {...props}>
    //         Show correct and wrong answers at the end of the quiz
    //     </Tooltip>
    // );

    // const renderTooltipPublish = (props) => (
    //     <Tooltip  {...props}>

    //     </Tooltip>
    // );
    // const renderTooltipStartDate = (props) => (
    //     <Tooltip id="sDate" {...props}>
    //         Start quiz on scheduled date
    //     </Tooltip>
    // );
    // const renderTooltipEndDate = (props) => (
    //     <Tooltip id="eDate" {...props}>
    //         End quiz on scheduled date
    //     </Tooltip>
    // );

    // const renderTooltipAdaptive = (props) => (
    //     <Tooltip id="aDaptive" {...props}>

    //     </Tooltip>
    // );

    const onHandleWhiteListing = () => {
        setShow1(true);
    }




    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        } else {
            setStartDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        } else {
            setEndDate(null);
        }
    };

const onAssignedQuizHandler = () =>{
    alert("hi");
}


    const isAdaptiveHandler = () => {
        setIsAdaptive(!isAdaptive);
        setAddaptive({
            isTrue: !isAdaptive,
            easy: "",
            medium: "",
            hard: ""
        });
    }


    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };


    const onCancelHandler = () => {
        // navigate("/dashboard/total-quizzes");
        navigate(-1);
    }


    const validateField = (name, value) => {
        // perform validation logic for each field
        // set error message to errors state if validation fails
        let errorsCopy = { ...errors };
        switch (name) {
            case 'quizTitle':
                if (!value.trim()) {
                    errorsCopy.quizTitle = 'Quiz Title is required';
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    errorsCopy.quizTitle = 'Quiz Title contain only a-z,A-Z and space';
                } else {
                    delete errorsCopy.quizTitle;
                }
                break;
            case 'shortCode':
                if (!value.trim()) {
                    errorsCopy.shortCode = 'Short Code is required';
                } else {
                    delete errorsCopy.shortCode;
                }
                break;

            case 'description':
                if (!value.trim()) {
                    errorsCopy.description = 'Description is required';
                } else {
                    delete errorsCopy.description;
                }
                break;

            case 'noOfAttempts':
                if (!value.trim()) {
                    errorsCopy.noOfAttempts = 'No of Attempts is required';
                } else if (!/^[0-9]*$/.test(value)) {
                    errorsCopy.noOfAttempts = 'No of Attempts contain only digits';
                } else {
                    delete errorsCopy.noOfAttempts;
                }
                break;

            case 'totalDuration':
                if (!value.trim()) {
                    errorsCopy.totalDuration = 'Total Duration is required in (minutes)';
                } else if (!/^[0-9]*$/.test(value)) {
                    errorsCopy.totalDuration = 'Total Duration contain only digits';
                } else {
                    delete errorsCopy.totalDuration;
                }
                break;



            case 'quizSize':
                if (!value.trim()) {
                    errorsCopy.quizSize = 'No of Questions is required';
                } else if (!/^[0-9]*$/.test(value)) {
                    errorsCopy.quizSize = 'No of Questions contain only digits';
                } else {
                    delete errorsCopy.quizSize;
                }
                break;


            case 'passPercentage':
                if (!value.trim()) {
                    errorsCopy.passPercentage = 'Pass Percentage is required';
                } else if (!/^[0-9]*$/.test(value)) {
                    errorsCopy.passPercentage = 'Pass Percentage contain only digits';
                } else {
                    delete errorsCopy.passPercentage;
                }
                break;

        }
        setErrors(errorsCopy);
    };




    const onValidateHandler = () => {
        const { quizTitle, shortCode, description, noOfAttempts, totalDuration, quizSize, passPercentage } = quizData;
        const errors = {};

        // Perform form validation here
        // Example: Checking if the fields are empty
        if (quizTitle === "") {
            errors.quizTitle = "QuizTitle is required";
        } else if (!/^[A-Za-z\s]+$/.test(quizTitle)) {
            errors.quizTitle = 'Quiz Title contain only a-z,A-Z and space';
        }

        if (shortCode === "") {
            errors.shortCode = "Shortcode is required";
        }

        if (description === "") {
            errors.description = "Description is required";
        }

        if (noOfAttempts === "") {
            errors.noOfAttempts = "No Of Attempts is required";
        } else if (!/^[0-9]*$/.test(noOfAttempts)) {
            errors.noOfAttempts = 'No of Attempts contain only digits';
        }

        if (totalDuration === "") {
            errors.totalDuration = "Total Duration is required";
        } else if (!/^[0-9]*$/.test(totalDuration)) {
            errors.totalDuration = 'Total Duration contain only digits';
        }

        if (quizSize === "") {
            errors.quizSize = "Quiz Size is required";
        } else if (!/^[0-9]*$/.test(quizSize)) {
            errors.quizSize = 'Quiz Size contain only digits';
        }

        if (passPercentage === "") {
            errors.passPercentage = "Pass Percentage is required";
        } else if (!/^[0-9]*$/.test(passPercentage)) {
            errors.passPercentage = 'Pass Percentage contain only digits';
        }

        if (isAdaptive) {
            if (getAddaptive.easy === "") {
                errors.easy = "Easy field is required";
            }
            if (getAddaptive.medium === "") {
                errors.medium = "Medium field is required";
            }
            if (getAddaptive.hard === "") {
                errors.hard = "Hard field is required";
            }
            const totalAdaptive = parseInt(getAddaptive.easy) + parseInt(getAddaptive.medium) + parseInt(getAddaptive.hard);
            if (totalAdaptive !== parseInt(quizData.quizSize)) {
                errors.totalAdaptive = "Total count of adaptive questions should match quiz size.";
            }
        }




        setErrors(errors);

        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    };

    const onCertificateChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCertificate(selectedValue);
    
        // setQuizData({
        //     ...quizData,
        //     certFileName: selectedCertificate
        // });
    }



    const onhandleChange = (event) => {
        const { name, value } = event.target;
        setQuizData({ ...quizData, [name]: value });


        if (event.target.name === "shuffle") {
            setQuizData({ ...quizData, [event.target.name]: !quizData.shuffle })
        }

        if (event.target.name === "showScore") {
            setQuizData({ ...quizData, [event.target.name]: !quizData.showScore })
        }

        if (event.target.name === "publish") {
            setQuizData({ ...quizData, [event.target.name]: !quizData.publish })
        }


        if (["easy", "medium", "hard"].includes(name)) {
            handleAdaptiveValues(name, value);

        }


        if (name === "quizType" && value === "assignQuiz") {
            setIsAssignedQuiz(true);
        }


    };


    const handleAdaptiveValues = (level, value) => {
        const updatedTotalAdaptiveQuestions = { ...totalAdaptiveQuestions };
        updatedTotalAdaptiveQuestions[level] = parseInt(value);

        setTotalAdaptiveQuestions(updatedTotalAdaptiveQuestions);
        setAddaptive((prevState) => ({
            ...prevState,
            [level]: value,
        }));


        const total = Object.values(updatedTotalAdaptiveQuestions).reduce((acc, curr) => acc + curr, 0);

        if (total !== parseInt(quizData.quizSize)) {
            setErrorAddaptive("Total count of adaptive questions should match quiz size.");
        } else {
            setErrorAddaptive("");
        }

        // Automatically calculate and set the remaining value
        const providedLevels = Object.keys(updatedTotalAdaptiveQuestions).filter(lvl => updatedTotalAdaptiveQuestions[lvl] !== 0);

        if (providedLevels.length === 2) {
            const remainingLevel = ["easy", "medium", "hard"].find(lvl => !providedLevels.includes(lvl));
            const remainingValue = parseInt(quizData.quizSize) - total;

            if (remainingValue >= 0) {
                setAddaptive(prevState => ({
                    ...prevState,
                    [remainingLevel]: remainingValue.toString(),
                }));

                setErrorAddaptive(""); // Clear error message when conditions are met
            }
        }
    };







    const onhandleSubmit = (event) => {
        event.preventDefault();
        const formIsValid = onValidateHandler();
        if (formIsValid) {
            const data = { ...quizData, quizId: qId, startDate: startDate ? moment(startDate).format('DD/MM/YYYY, h:mm:ss a') : 'NA', endDate: endDate ? moment(endDate).format('DD/MM/YYYY, h:mm:ss a') : 'NA', addaptiveData: [getAddaptive],certFileName:selectedCertificate }
            if (isEditMode) {
                console.log("dataedit", data)
                Services.UpdateQuiz(data)
                    .then(async response => {
                        if (response.data.status === true) {
                            await swal("Success", response.data.msg, "success");
                            setQuizData({ quizTitle: '', shortCode: '', description: '', noOfAttempts: '', totalDuration: '', quizSize: '', passPercentage: '', shuffle: false, showScore: false, publish: true });
                            setStartDate(null);
                            setEndDate(null);
                            setIsAdaptive(0)
                            setIsEditMode(false);   
                            console.log("Quiz",quizData.quizType);                         
                        //    if(quizData.quizType === "assignQuiz"){
                        //     navigate(`/dashboard/quiz/assign-user/${qId}`);
                        //    }else{
                            navigate(-1);
                        //    }
                        }
                        else {
                            await swal("Message", response.data.msg, "info");
                        }
                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            Services.Logout()
                                .then(response => {
                                    if (response.status === 200) {
                                        sessionStorage.removeItem("token");
                                        sessionStorage.removeItem("LoggedIn");
                                        navigate("/");
                                    }
                                })
                                .catch(err => {
                                    // Handle error if needed
                                });
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });

            } else {
                Services.CreateQuiz(data)

                    .then(async response => {
                        if (response.data.status === true) {
                            await swal("Success", response.data.msg, "success");
                            setQuizData({ quizTitle: '', shortCode: '', description: '', noOfAttempts: '', totalDuration: '', quizSize: '', passPercentage: '', shuffle: false, showScore: false, publish: true, quizType: '' });
                            setStartDate(null);
                            setEndDate(null);
                            setIsAdaptive(0);
                            setSelectedCertificate('');
                            navigate("/dashboard/quiz/total-quizzes");
                        }
                        else {
                            await swal("Message", response.data.msg, "info");
                        }

                    }).catch(err => {
                        if (err.response && err.response.status === 401) {
                            const errorMessage = err.response.data.errors.UnAuthorized;

                            swal("Message!", errorMessage, "warning");
                            Services.Logout()
                                .then(response => {
                                    if (response.status === 200) {
                                        sessionStorage.removeItem("token");
                                        sessionStorage.removeItem("LoggedIn");
                                        navigate("/");
                                    }
                                })
                                .catch(err => {
                                    // Handle error if needed
                                });
                        }
                        else if (err.response && err.response.data && err.response.data.errors) {
                            let errorMessages = Object.values(err.response.data.errors).join('\n');
                            swal("Message!", errorMessages, "warning");
                        } else if (err.response && err.response.data && err.response.data.msg) {
                            swal("Message!", err.response.data.msg, "warning");
                        } else {
                            swal("Message!", "Server error occurred", "warning");
                        }
                    });

            }
        }
    };


    useEffect(() => {

        Services.GetQuizzesById(qId)
            .then(response => {
                // setlistQuizzes(response.data);
                setQuizData({ quizTitle: response.data.quizTitle, shortCode: response.data.shortCode, description: response.data.description, noOfAttempts: response.data.noOfAttempts, totalDuration: response.data.totalDuration, quizSize: response.data.quizSize, passPercentage: response.data.passPercentage, shuffle: response.data.shuffle, showScore: response.data.showScore, publish: response.data.publish, quizType: response.data.quizType })
                setSelectedCertificate(response.data.certFileName);
               
                if (response.data.startDate !== "NA") {
                    const sDate = response.data.startDate;
                    const parsedStartDate = moment(sDate, 'DD/MM/YYYY, h:mm:ss a');
                    const isoStartDate = parsedStartDate.toISOString();
                    setStartDate(new Date(isoStartDate));
                } else {
                    setStartDate(null);
                }

                if (response.data.endDate !== "NA") {
                    const eDate = response.data.endDate;
                    const parsedEndDate = moment(eDate, 'DD/MM/YYYY, h:mm:ss a');
                    const isoEndDate = parsedEndDate.toISOString();
                    console.log("SDate", isoEndDate);
                    setEndDate(new Date(isoEndDate));
                } else {
                    setEndDate(null);
                }
                if (response.data.addaptiveData !== null) {
                    if (response.data.addaptiveData.length > 0 && response.data.addaptiveData[0].isTrue === false) {
                        setIsAdaptive(0);
                    } else {
                        setIsAdaptive(!isAdaptive);
                    }
                }
                setAddaptive({
                    easy: response.data.addaptiveData[0].easy,
                    medium: response.data.addaptiveData[0].medium,
                    hard: response.data.addaptiveData[0].hard
                })
                setIsEditMode(true)
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
            });
    }, [])
    console.log("CertName",selectedCertificate);
    useEffect(() => {

        Services.GetAllCertificate()
            .then(response => {
                setlistCertificates(response.data);

            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
            });
    }, [])







    return (
        <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container pt-3">
                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                DashBoard
                            </Link>

                        </li>
                        <li>
                            <Link to="/dashboard/quiz">
                                Quiz
                            </Link>
                            
                        </li>
                        {!isEditMode ?
                            <li>Create Quiz</li>
                            :
                            <li>Update Quiz</li>
                        }

                    </ol>
                    {!isEditMode ?
                        <h2 >Create Quiz</h2>
                        : <h2 >Update Quiz</h2>
                    }

                </div>
            </section>
            <div className="container-xxl wrapper">
                {/* {!isEditMode ?
                    <h3 className="mt-3 text-secondary">Create Quiz</h3>
                    : <h3 className="mt-3 text-secondary">Edit Quiz</h3>
                }
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">

                        <li className="breadcrumb-item">
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li className="breadcrumb-item">
                            <Link to="/dashboard">
                                DashBoard
                            </Link>
                        </li>
                        {!isEditMode ?
                            <li className="breadcrumb-item active" aria-current="page">Create Quiz</li>
                            :
                            <li className="breadcrumb-item active" aria-current="page">Edit Quiz</li>
                        }
                    </ol>
                </nav> */}

                <form onSubmit={onhandleSubmit} autoComplete="off">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-leftsidebody-create">
                                    <div className='row pt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>Quiz Title
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipTitle}
                                                    >
                                                        <i className="fa fa-fw fa-info-circle"></i>
                                                    </OverlayTrigger> */}
                                                </h3>

                                                <input
                                                    type="text"
                                                    className={errors.quizTitle ? "form-control is-invalid" : "form-control"}
                                                    id="QuizTitle"
                                                    name="quizTitle"
                                                    value={quizData.quizTitle}
                                                    onBlur={handleBlur}
                                                    onChange={onhandleChange}
                                                />
                                                <small>
                                                    Enter quiz title
                                                </small>

                                                <div className="invalid-feedback">
                                                    {errors.quizTitle && <span>{errors.quizTitle}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>Quiz Short Code
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipShortCode}
                                                    >
                                                        <i className="fa fa-fw fa-info-circle"></i>
                                                    </OverlayTrigger> */}
                                                </h3>
                                                <input
                                                    type="text"
                                                    className={errors.shortCode ? "form-control is-invalid" : "form-control"}
                                                    id="QuizShortCode"
                                                    name='shortCode'
                                                    maxLength={5}
                                                    value={quizData.shortCode}
                                                    onBlur={handleBlur}
                                                    onChange={onhandleChange}
                                                />
                                                <small>
                                                    Enter quiz short code
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.shortCode && <span>{errors.shortCode}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 pt-3'>
                                        <div className="form-group">
                                            <h3 className='required'>Description
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipDescription}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <textarea
                                                className={errors.description ? "form-control is-invalid" : "form-control"}
                                                id="Description"
                                                rows={3}
                                                defaultValue={""}
                                                name="description"
                                                value={quizData.description}
                                                onBlur={handleBlur}
                                                onChange={onhandleChange}
                                            />
                                            <small>
                                                Enter description for quiz
                                            </small>
                                            <div className="invalid-feedback">
                                                {errors.description && <span>{errors.description}</span>}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row pt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>No of Attempts
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipNoofAttempts}
                                                    >
                                                        <i className="fa fa-fw fa-info-circle"></i>
                                                    </OverlayTrigger> */}
                                                </h3>
                                                <input
                                                    type="number"
                                                    className={errors.noOfAttempts ? "form-control is-invalid" : "form-control"}
                                                    id="NoOfAttempts"
                                                    name="noOfAttempts"
                                                    value={quizData.noOfAttempts}
                                                    onBlur={handleBlur}
                                                    onChange={onhandleChange}
                                                />
                                                <small>
                                                    Enter the No of attempts
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.noOfAttempts && <span>{errors.noOfAttempts}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>Total Duration
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipTotalDuration}
                                                    >
                                                        <i className="fa fa-fw fa-info-circle"></i>
                                                    </OverlayTrigger> */}
                                                </h3>
                                                <input
                                                    type="number"
                                                    className={errors.totalDuration ? "form-control is-invalid" : "form-control"}
                                                    id="TotalDuration"
                                                    name="totalDuration"
                                                    value={quizData.totalDuration}
                                                    onBlur={handleBlur}
                                                    onChange={onhandleChange}
                                                />
                                                <small>
                                                    Enter total duration in minutes
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.totalDuration && <span>{errors.totalDuration}</span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row pt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>No of Questions
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipQuizSize}
                                                    >
                                                        <i className="fa fa-fw  fa-info-circle"></i>
                                                    </OverlayTrigger> */}
                                                </h3>
                                                <input
                                                    type="number"
                                                    className={errors.quizSize ? "form-control is-invalid" : "form-control"}
                                                    id="QuizSize"
                                                    name='quizSize'
                                                    value={quizData.quizSize}
                                                    onBlur={handleBlur}
                                                    onChange={onhandleChange}
                                                />
                                                <small>
                                                    Enter total no of questions
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.quizSize && <span>{errors.quizSize}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <h3 className='required'>Pass Percentage
                                                    {/* <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipPassPercentage}
                                                    >
                                                        <i className="fa fa-fw fa-info-circle"></i>
                                                    </OverlayTrigger> */}
                                                </h3>
                                                <input
                                                    type="number"
                                                    className={errors.passPercentage ? "form-control is-invalid" : "form-control"}
                                                    id="PassPercentage"
                                                    name="passPercentage"
                                                    value={quizData.passPercentage}
                                                    onBlur={handleBlur}
                                                    onChange={onhandleChange}
                                                />
                                                <small>
                                                    Enter pass percentage
                                                </small>
                                                <div className="invalid-feedback">
                                                    {errors.passPercentage && <span>{errors.passPercentage}</span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <h3>Start Date
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipStartDate}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}

                                            </h3>
                                            <DatePicker selected={startDate} onChange={handleStartDateChange}
                                                isClearable
                                                showTimeSelect
                                                todayButton="Today"
                                                wrapperClassName="date-picker"
                                                dateFormat="dd/MM/yyyy, h:mm aa"

                                            />
                                            <small>
                                                Start quiz on scheduled date
                                            </small>
                                        </div>

                                        <div className='col-md-6'>
                                            <h3>End Date
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipEndDate}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <DatePicker selected={endDate} onChange={handleEndDateChange}
                                                isClearable
                                                showTimeSelect
                                                todayButton="Today"
                                                wrapperClassName="date-picker"
                                                dateFormat="dd/MM/yyyy, h:mm aa"

                                            />
                                            <small>
                                                End quiz on scheduled date
                                            </small>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-rightsidebody-create">
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <h3>Shuffle
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipShuffle}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <small>
                                                Show different questions on each visit
                                            </small>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className="switch">
                                                <input type="checkbox" name="shuffle" id="shuffle" defaultChecked="" value={quizData.shuffle} checked={quizData.shuffle ? true : false} onChange={onhandleChange} />
                                                <span className="slider round " />
                                                <span className="check" />
                                            </label>
                                        </div>

                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-md-6'>
                                            <h3>Show Score
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipScore}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <small>
                                                Show correct and wrong answers at the end of quiz
                                            </small>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className="switch">
                                                <input type="checkbox" name="showScore" id="shuffle" value={quizData.showScore} defaultChecked="" checked={quizData.showScore ? true : false} onChange={onhandleChange} />
                                                <span className="slider round " />
                                                <span className="check" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-md-6'>
                                            <h3>Publish
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipPublish}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>

                                        </div>
                                        <div className='col-md-6'>
                                            <label className="switch">
                                                <input type="checkbox" name="publish" id="publish" value={quizData.publish} defaultChecked="" checked={quizData.publish ? true : false} onChange={onhandleChange} />
                                                <span className="slider round " />
                                                <span className="check" />
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className='row mt-5'>
                                        <div className='col-md-6'>
                                            <h3>Start Date</h3>
                                            <DatePicker selected={startDate} onChange={handleStartDateChange}
                                                isClearable
                                                showTimeSelect
                                                todayButton="Today"
                                                wrapperClassName="date-picker"
                                                dateFormat="dd/MM/yyyy, h:mm aa"

                                            />
                                            <small>
                                                Start quiz on scheduled date
                                            </small>
                                        </div>

                                        <div className='col-md-6'>
                                            <h3>End Date </h3>                                                                                   
                                            <DatePicker selected={endDate} onChange={handleEndDateChange}
                                                isClearable
                                                showTimeSelect
                                                todayButton="Today"
                                                wrapperClassName="date-picker"
                                                dateFormat="dd/MM/yyyy, h:mm aa"

                                            />
                                            <small>
                                                End quiz on scheduled date
                                            </small>
                                        </div>
                                    </div> */}

                                    <div className='row mt-5'>
                                        <div className='col-md-6 custom-select-quiz'>
                                            <h3>Choose Certificate</h3>
                                            <select className="form-select" aria-label="Default select example" value={selectedCertificate} name="certFileName" onChange={onCertificateChange}>
                                                <option>Choose Certificate</option>
                                                {listCertificates.map((certificate, index) => (
                                                    <option key={index}>{certificate}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col-md-6 custom-select-quiz'>
                                            <h3>Choose Quiz Type</h3>
                                            <select className="form-select" aria-label="Default select example" value={quizData.quizType} name="quizType" onChange={onhandleChange}>
                                                <option value="">Choose Quiz Type</option>
                                                <option value="regular">Regular</option>
                                                <option value="assignQuiz">Assign Quiz</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-md-6'>
                                            <h3>Adaptive
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipAdaptive}
                                                >
                                                    <i className="fa fa-fw fa-info-circle"></i>
                                                </OverlayTrigger> */}
                                            </h3>
                                            <div className="can-toggle">
                                                <input id="adaptive" type="checkbox" checked={isAdaptive ? true : false} onClick={isAdaptiveHandler} />
                                                <label htmlFor="adaptive">
                                                    <div
                                                        className="can-toggle__switch"
                                                        data-checked="Yes"
                                                        data-unchecked="No"
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    {isAdaptive ?
                                        <div className='col-md-12'>
                                            <div className='row mt-2'>
                                                <div className='col-md-4'>
                                                    <h3 className='mt-3'>Easy</h3>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input
                                                        type="number"
                                                        className={errors.easy ? "form-control is-invalid" : "form-control"}
                                                        id="inputAdaptive"
                                                        name='easy'
                                                        value={getAddaptive.easy}
                                                        onChange={onhandleChange}
                                                        onBlur={onValidateHandler}
                                                    />
                                                    {errors.easy && <div className="text-danger mt-2">{errors.easy}</div>}
                                                </div>

                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-md-4'>
                                                    <h3 className='mt-3'>Medium</h3>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input
                                                        type="number"
                                                        className={errors.medium ? "form-control is-invalid" : "form-control"}
                                                        id="inputAdaptive"
                                                        name='medium'
                                                        value={getAddaptive.medium}
                                                        onBlur={onValidateHandler}
                                                        onChange={onhandleChange}
                                                    />
                                                    {errors.medium && <div className="text-danger mt-2">{errors.medium}</div>}
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-md-4'>
                                                    <h3 className='mt-3'>Hard</h3>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input
                                                        type="number"
                                                        className={errors.hard ? "form-control is-invalid" : "form-control"}
                                                        id="inputAdaptive"
                                                        name='hard'
                                                        value={getAddaptive.hard}
                                                        onBlur={onValidateHandler}
                                                        onChange={onhandleChange}
                                                    />
                                                    {errors.hard && <div className="text-danger mt-2">{errors.hard}</div>}
                                                </div>
                                            </div>
                                            {errorAddaptive && totalAdaptiveQuestions.easy + totalAdaptiveQuestions.medium + totalAdaptiveQuestions.hard !== parseInt(quizData.quizSize) ? (
                                                <div className="text-danger mt-2">{errorAddaptive}</div>
                                            ) : null}
                                        </div>
                                        : null}




                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="d-flex justify-content-end mt-4">
                      
                            {isEditMode ? (
                                <>
                                    <div className='m-1'>
                                    <button type="button" className="btn btn-lg btn-danger" onClick={onCancelHandler}>
                                        Cancel
                                    </button>
                                    </div>
                                   
                                    <div className='m-1'>
                                    <button type="submit" className="btn btn-lg btn-success">
                                        Update
                                    </button>
                                    </div>
                                   
                                </>
                            ) : (
                                <button type="submit" className="btn btn-lg btn-success">
                                    Submit
                                </button>
                            )}
                        

                    </div>
                    {/* <div className='row'>
                        <div className='col-md-12'>
                            <button type="submit" className="button-submit">
                                Submit
                            </button>
                        </div>
                    </div> */}
                </form>
            </div>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-danger'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='fw-bold fs-5 mt-3 text-dark text-center'>Thank you!Your quiz has been submitted successfully</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>

    )
}

export default CreateQuiz;