import Quizz from '../../src/Images/Quizz.jpg'
import { useState, useEffect } from 'react';
import ClientCaptcha from 'react-client-captcha';
import myInitObject from "./myInitObject";
//import { GoogleLogin } from 'react-google-login';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik, ErrorMessage } from 'formik';
import FacebookLogin from 'react-facebook-login';
import Services from '../../src/Service/Service'
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import swal from "sweetalert"
import '../assets/Style.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-sociallogin-kc/dist/index.css'
import axios from 'axios';

const Login = () => {
    const [imageSrc, setImageSrc] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [token, setcaptchaToken] = useState('');
    const [hash, setcaptchahash] = useState('');
    const [randomString, setrandomString] = useState('');
    let navigate = useNavigate();
    const LoggedIn = sessionStorage.getItem("LoggedIn");
    const [values, setValues] = useState({});
    const { qId } = useParams();

    // console.log("qid",qId);



    const googleFailure = (res) => {
        console.log("Google Login Failed :" + Object.values(res));
    };

    const responseFacebook = (response) => {
        console.log(response);
    };


    const googleSuccess = (response) => {
        console.log('Login Successful:', response);
        // Extract the first part before the dot in the credential
        //console.log("Google response :",response);

        const decodedgoogle = jwtDecode(response.credential);

        console.log("googleToken", decodedgoogle);

        const firstPart = response.credential.split('.')[0];

        // Generate a random three-digit number (between 100 and 999)
        const randomKeyLength = 3;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomKey = '';
        for (let i = 0; i < randomKeyLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomKey += characters.charAt(randomIndex);
        }

        // Append the random number after the first part
        const modifiedCredential = `${firstPart}.${randomKey}${response.credential.slice(firstPart.length + 1)}`;
        console.log("String", response.credential);
        //let data = { credential: response.credential }
        let data = response.credential;
        let json = {}
        console.log("google token", data)
        Services.PostGoogleCredentials(data).then(async response => {
            if (response.status === 200) {
                sessionStorage.setItem("token", response.data.id);
                sessionStorage.setItem("LoggedIn", true);
                const flag = sessionStorage.getItem("flag");
                const token = sessionStorage.getItem("token");
                const username = getUsernameFromToken(token);
                const roles = getRolesFromToken(token);

                function getUsernameFromToken(token) {
                    // Decode the JWT token
                    const decodedToken = jwtDecode(token);

                    // Extract the username from the decoded token
                    const username = decodedToken ? decodedToken.sub : null;

                    return username;
                }

                function getRolesFromToken(token) {
                    // Decode the JWT token
                    const decodedToken = jwtDecode(token);

                    // Extract the roles from the decoded token
                    const roles = decodedToken ? decodedToken.roles : null;

                    return roles;
                }

                if (roles === '[ROLE_ADMIN]') {
                    navigate('/dashboard');
                    window.location.reload();
                } else if (roles === '[ROLE_USER]' && flag === "0") {
                    navigate(`/quiz/user-dashboard`);
                    window.location.reload();
                } else {
                    navigate(`/quiz/${qId}`);
                    window.location.reload();
                }
            }


        }).catch((err) => {
            // if (err.response && err.response.status === 401) {
            //     swal("Error", err.response.data.error, "error");
            // } else
            if (err.response && err.response.data && err.response.data.errors) {
                let errorMessages = Object.values(err.response.data.errors).join('\n');
                swal("Message!", errorMessages, "warning");
                GenerateCaptchaImage();
                //setFieldValue('captcha', '');
            } else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

    };






    const validate = (values) => {
        const errors = {}

        if (!values.email) {
            errors.email = 'Please Enter Email'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }

        if (!values.password) {
            errors.password = 'Please Enter Password'
        }

        if (!values.captcha) {
            errors.captcha = 'Please Enter Captcha';
        }

        return errors
    }


    const GenerateCaptchaImage = () => {
        Services.GenerateCaptcha().then(async response => {
            const base64Data = response.data.captchaImage; // Assuming the response contains a 'image' field with base64 data
            const token = response.data.captchaToken;
            const hash = response.data.captchaHash;
            const randomString = response.data.randomString;
            setcaptchaToken(token);
            setcaptchahash(hash);
            setrandomString(randomString);
            const mimeType = 'image/png'; // Set the correct MIME type for the image
            const byteCharacters = atob(base64Data);
            const byteArrays = [];
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }
            const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType });

            const imageUrl = URL.createObjectURL(blob);
            setImageSrc(imageUrl);

        }).catch(err => {
            swal("Message!", "Server Error", "info")
        });

    }



    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            captcha: ''
        },
        validate,
        onSubmit: async (values, { setFieldValue }) => {
            let data = { email: values.email, password: values.password, captchaImage: values.captcha, captchaToken: token, captchaHash: hash, randomString: randomString };
            Services.Login(data)
                .then(async (response) => {
                    if (response.status === 200) {
                        sessionStorage.setItem("token", response.data.id);
                        sessionStorage.setItem("LoggedIn", true);
                        const flag = sessionStorage.getItem("flag");
                        const token = sessionStorage.getItem("token");
                        const username = getUsernameFromToken(token);
                        const roles = getRolesFromToken(token);

                        function getUsernameFromToken(token) {
                            // Decode the JWT token
                            const decodedToken = jwtDecode(token);

                            // Extract the username from the decoded token
                            const username = decodedToken ? decodedToken.sub : null;

                            return username;
                        }

                        function getRolesFromToken(token) {
                            // Decode the JWT token
                            const decodedToken = jwtDecode(token);

                            // Extract the roles from the decoded token
                            const roles = decodedToken ? decodedToken.roles : null;

                            return roles;
                        }

                        if (roles === '[ROLE_ADMIN]') {
                            navigate('/dashboard');
                            window.location.reload();
                        } else if (roles === '[ROLE_USER]' && flag === "0") {
                            navigate(`/quiz/user-dashboard`);
                            window.location.reload();
                        } else {
                            navigate(`/quiz/${qId}`);
                            window.location.reload();
                        }
                    }
                })
                .catch((err) => {
                    // if (err.response.data.errors && err.response.status === 401) {
                    //     swal("Error", err.response.data.errors, "error");
                    // } 
                    // else 
                    if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        swal("Message!", errorMessages, "warning");
                        GenerateCaptchaImage();
                        setFieldValue('captcha', '');
                    } else if (err.response && err.response.data && err.response.data.errors) {
                        swal("Message!", err.response.data.errors, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                });
        }
    });


    useEffect(() => {
        GenerateCaptchaImage()

    }, [])

    return (
        <div>
            <div className="wrapper-home container">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="card my-4 h-auto">
                                <div className="card-body cardbody-color p-lg-5">
                                    {/* <div className="text-center">
                                        <Link to="/">
                                            <img
                                                src={Quizz}
                                                className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                                                width="200px"
                                                alt="profile"
                                            />
                                        </Link>
                                    </div> */}
                                    <div className='my-4'>
                                        <h5 className='text-center'>Login</h5>
                                    </div>
                                    {LoggedIn === "true" ? (
                                        <div>
                                            <h6 className='text-center fw-bold'>You are already logged in.</h6>

                                        </div>
                                    ) : (
                                        <>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className={formik.errors.email && formik.touched.email ? "form-control is-invalid" : "form-control"}
                                                    id="EmailLogin"
                                                    name='email'
                                                    placeholder="Email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}

                                                />
                                                <label for="floatingInput">Email address</label>
                                                <div className='invalid-feedback'>
                                                    {formik.touched.email && formik.errors.email && (
                                                        <span style={{ color: "red" }}> {formik.errors.email}</span>
                                                    )}
                                                </div>

                                            </div>
                                            <div className=" form-floating  mb-3">
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    className={formik.touched.password && formik.errors.password ? "form-control is-invalid" : "form-control"}
                                                    id="PasswordLogin"
                                                    name="password"
                                                    placeholder="Password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                <label htmlFor="PasswordLogin">Password</label>
                                                {formik.touched.password && formik.errors.password && (
                                                    <span style={{ color: "red" }}> {formik.errors.password}</span>
                                                )}
                                                <div className="eye-icon-container">
                                                  
                                                        {showPassword ? (
                                                           <i class="ri-eye-off-fill ri-lg" onClick={() => setShowPassword(!showPassword)}></i>
                                                        ) : (
                                                            <i className="ri-eye-fill ri-lg" onClick={() => setShowPassword(!showPassword)}></i>
                                                        )}
                                                   
                                                </div>
                                            </div>

                                            <div className='form-floating'>

                                                <input type="text" className={formik.touched.captcha && formik.errors.captcha ? "form-control is-invalid" : "form-control"} name="captcha" maxLength="5"
                                                    placeholder='Captcha'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.captcha}
                                                />
                                                <label for="floatingInput">Captcha</label>
                                                {formik.touched.captcha && formik.errors.captcha && (
                                                    <span style={{ color: "red" }}> {formik.errors.captcha}</span>
                                                )}
                                            </div>
                                            <div className='d-flex justify-content-end my-3'>

                                                {imageSrc && (
                                                    <>
                                                        <img src={imageSrc} alt="Image" width={200} />
                                                        <div className='border border-2'>
                                                            <i className="fa-solid fa-2xl fa-rotate-right mt-4 mx-3" onClick={GenerateCaptchaImage}></i>
                                                        </div>
                                                    </>
                                                )}


                                            </div>

                                            <div className="text-center">

                                                <button type="submit" className="btn btn-color w-100">
                                                    Submit
                                                </button>

                                            </div>

                                            <Link to="/forgot-password">
                                                <div className='d-flex justify-content-end mt-2'>
                                                    Forgot Password?
                                                </div>
                                            </Link>
                                            {/* <div className="border-left-right">
                                        <span>Or</span>
                                    </div> */}


                                            <div id="emailHelp" className="form-text text-center mt-2 mb-5 text-dark">
                                                Not Registered?{" "}
                                                <a href="/signup" className="text-dark fw-bold">
                                                    Create an Account
                                                </a>

                                                <div className='d-flex justify-content-center mt-2 w-100'>
                                                    {/* <button className="btn btn-fb btn-circle btn-circle-lg m-1">
                                                        <i className="fab fa-facebook-f" style={{ color: "white" }} />
                                                    </button> */}
                                                    {/* <GoogleOAuthProvider clientId={myInitObject.google_clientId}> */}

                                                    <GoogleLogin
                                                        clientId={myInitObject.google_clientId}
                                                        render={renderProps => (
                                                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-danger btn-circle btn-circle-lg m-1">
                                                                <i className="fab fa-google" />
                                                            </button>
                                                        )}
                                                        scope="profile email"
                                                        buttonText="Login"
                                                        onSuccess={googleSuccess}
                                                        onFailure={googleFailure}
                                                        cookiePolicy={'single_host_origin'}
                                                        theme="filled_blue"
                                                        text="signin_with"
                                                        size="large"
                                                    />
                                                    {/* </GoogleOAuthProvider> */}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;