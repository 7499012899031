import Navbar from "./Navbar/Navbar";
import axios from "axios";
import Dashboard from "./Dashboard/DashBoard";
import { Routes, Route, BrowserRouter } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer';
import './App.css';
import UserDashboard from "./UserDashboard/UserDashboard";
import DashBoardRouter from "./DashboardRouter/Dashboardrouter";
import Footer from "./Footer/Footer"
import Home from "./Home/Home";
import { useEffect } from 'react'
import HomeRouter from "./HomeRouter/HomeRouter";
import QuizResponseRouter from "./QuizResponse/QuizResponseRouter";
import CreateQuiz from "./CreateQuiz/CreateQuiz";
import Ivp from "./IVP/Ivp";
import ErrorPage from "./ErrorPage/ErrorPage";
import history from "./History/History";
import Services from "./Service/Service";
import UserService from "./Services/UserService";
import RenderOnAuthenticated from "./RenderOnAuthenticated/RenderOnAuthenticated";
import CakesRouter from "./CakesRouter/CakesRouter";
import { useKeycloak } from "@react-keycloak/web";
import UserUtil from "./UserUtil/UserUtil";
// function PrivateRoute({ role, allowedRoles, ...props }) {
//   const tokenParsed = UserService.getTokenParsed();
//   const rolesForCakesLdap = tokenParsed.resource_access.cakesldap.roles;

//   // Check if the user has the required role
//   if (rolesForCakesLdap.includes('learner')) {
//     return <Route {...props} />;
//   } else {
//     // return <navigate to="/error" />;
//     return null;
//   }
// }


// const setupAxiosInterceptors = (keycloak) => {
//   axios.interceptors.request.use(
//     async (config) => {
//       if (keycloak && keycloak.authenticated && keycloak.token) {
//         const tokenExpired = keycloak.tokenParsed.exp * 1000 < Date.now() + 60 * 1000; // Check if token is about to expire in 60 seconds
//         if (tokenExpired) {
//           try {
//             const refreshed = await keycloak.updateToken(60); // Refresh token every 60 seconds
//             if (refreshed) {
//               const updatedToken = keycloak.token;
//               console.log('Updated Token:', updatedToken);

//               // Save updated token in sessionStorage or wherever needed
//               sessionStorage.setItem('token', updatedToken);

//               config.headers.Authorization = `Bearer ${updatedToken}`;
//             }
//           } catch (error) {
//             console.error('Error updating token:', error);
//           }
//         } else {
//           config.headers.Authorization = `Bearer ${keycloak.token}`;
//         }
//       }
//       return config;
//     },
//     (error) => {
//       Promise.reject(error);
//     }
//   );
// };



function App() {
  // let navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  //const userRole = UserService.getTokenParsed().resource_access.cakesldap.roles[0];
  // useEffect(() => {
  //   const handleBackButton = () => {
  //     history.go(1); // Push a new state and stay on the current page
  //   };

  //   window.addEventListener('popstate', handleBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', handleBackButton);
  //   };
  // }, []);



  // useEffect(() => {
  //   if (initialized) {
  //     setupAxiosInterceptors(keycloak);
  //   }
  // }, [initialized, keycloak]);



  // useEffect(() => {
  //   if (initialized) {
  //     const updateToken = async () => {
  //       try {
  //         const refreshed = await keycloak.updateToken(60); // Refresh token every 60 seconds
  //         if (refreshed) {
  //           const updatedToken = keycloak.token;
  //           console.log('Updated Token:', updatedToken);

  //           // Save updated token in sessionStorage
  //           sessionStorage.setItem('token', updatedToken);
  //         }
  //       } catch (error) {
  //         console.error('Error updating token:', error);
  //       }
  //     };

  //     const intervalId = setInterval(updateToken, 1000); // Call updateToken every 60 seconds

  //     return () => clearInterval(intervalId); // Clean up the interval on component unmount
  //   }
  // }, [initialized, keycloak]);



  axios.interceptors.request.use(async (config) => {
    if (keycloak.token !== undefined) {
        try {
            await keycloak.updateToken(60);
            config.headers['Authorization'] = `Bearer ${keycloak.token}`;
            // sessionStorage.setItem("token",keycloak.token);
            UserUtil.setCookieStorage("token",keycloak.token)
            console.log("updated token");
        } catch (error) {
            // navigate.push(`${process.env.PUBLIC_URL + "/"}`);
            // await keycloak.logout;
            console.log("inside catch keycloak");
          //   try {
          //     await keycloak.logout(); // Logout the user
          //     console.log("Logged out successfully");
          // } catch (logoutError) {
          //     console.error("Logout failed", logoutError); // Log the logout error for debugging
          // }
         
        }
    }
    return config;
});

  
  

  const onIdle = () => {
    // alert('fires after 10 minutes');
    //insert any custom logout logic here
    // sessionStorage.removeItem("token");
    // Services.Logout()
    // .then(response => {
    //     if (response.status === 200) {
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("LoggedIn");
    //         navigate("/login");
    //     }
    // })
    // .catch(err => {
    //     // Handle error if needed
    // });
  }

 
  


  return (

    <div>
      {/* <Navbar /> */}

      <Routes>
        <Route path="/*" element={<HomeRouter />} />
        <Route path="/admin-dashboard/*" element={<DashBoardRouter />} />
        <Route path="/user-dashboard/*" element={<QuizResponseRouter />} />
        {/* <Route path="/ivp-login/:url" element={<Ivp />} /> */}
        <Route path="/cakes-login/*"  element={<CakesRouter />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
