import Quizz from '../../src/Images/Quizz.jpg'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik, ErrorMessage, resetForm } from 'formik';
import ClientCaptcha from 'react-client-captcha';
import { useState, useEffect } from 'react';
import swal from "sweetalert"
import Services from '../../src/Service/Service'

const ForgetPassword = () => {
    const [imageSrc, setImageSrc] = useState('');
    const [token, setcaptchaToken] = useState('');
    const [hash, setcaptchahash] = useState('');
    const [randomString, setrandomString] = useState('');
    const LoggedIn = sessionStorage.getItem("LoggedIn");
    const [captchaCode, setCaptchaCode] = useState({
        captchaCode: ''
    })
    const { qId } = useParams();


    const validate = (values) => {
        const errors = {}

        if (!values.email) {
            errors.email = 'Please Enter Email'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }

        if (!values.captcha) {
            errors.captcha = 'Please Enter Captcha';
        }
        return errors
    }





    const formik = useFormik({
        initialValues: {
            email: '',
            captcha: ''
        },
        validate,
        onSubmit: async (values, { setFieldValue }) => {

            const data = { email: values.email, captchaImage: values.captcha, captchaToken: token, captchaHash: hash, randomString: randomString };

            try {
                const forgotPasswordResponse = await Services.ForgotPassword(data);

                if (forgotPasswordResponse.status === 200) {
                    await swal("Success", forgotPasswordResponse.data.msg, "success");
                    //formik.resetForm(); 
                    formik.resetForm();
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    swal("Error", err.response.data.error, "error");
                } else if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                    GenerateCaptchaImage();
                    setFieldValue('captcha', '');
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            }

        }
    });


    const GenerateCaptchaImage = () => {
        Services.GenerateCaptcha().then(async response => {
            const base64Data = response.data.captchaImage; // Assuming the response contains a 'image' field with base64 data
            const token = response.data.captchaToken;
            const hash = response.data.captchaHash;
            const randomString = response.data.randomString;
            setcaptchaToken(token);
            setcaptchahash(hash);
            setrandomString(randomString);
            const mimeType = 'image/png'; // Set the correct MIME type for the image
            const byteCharacters = atob(base64Data);
            const byteArrays = [];
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }
            const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType });

            const imageUrl = URL.createObjectURL(blob);
            setImageSrc(imageUrl);

        }).catch(err => {
            swal("Message!", "Server Error", "info")
        });

    }

    useEffect(() => {
        GenerateCaptchaImage();
    }, [])


    return (
        <div>
            <div className="container wrapper-home">
                <form onSubmit={formik.handleSubmit} autocomplete="off">
                    <div className="row">


                        <div className="col-md-12">
                            <div className="card my-4">
                                <div className="card-body cardbody-color p-lg-5">
                                    {/* <div className="text-center">
                                        <Link to="/">
                                            <img
                                                src={Quizz}
                                                className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                                                width="200px"
                                                alt="profile"
                                            />
                                        </Link>
                                    </div> */}
                                    <div>
                                        <h5 className='text-center mb-3'>Forgot Password</h5>
                                    </div>
                                    {LoggedIn === "true" ? (
                                        <div>
                                            <h6 className='text-center fw-bold'>You are already logged in.</h6>

                                        </div>
                                    ) : (
                                        <>
                                            <div className="alert alert-primary fw-bold" role="alert">
                                                <p>For security reasons, we store your password encrypted, and hence cannot mail it to you. If you would like to reset your password, please provide your email address (the one provided by you during the registration process). A link for resetting your password will be sent to the mentioned email address</p>
                                                <p>There are a few reasons that you might not be able to find the activation email. Please check the following things before you contact us:</p>
                                                <ul>
                                                    <li>Please always check your junk/spam folder.</li>
                                                    <li>Ensure your email id is correct.</li>
                                                    <li>Ensure your inbox is not full.</li>
                                                </ul>

                                            </div>
                                            <div className='col-md-6 offset-md-3'>
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="email"
                                                        className={formik.touched.email && formik.errors.email ? "form-control is-invalid" : "form-control"}
                                                        id="EmailLogin"
                                                        name='email'
                                                        placeholder="Email"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}

                                                    />
                                                    <label for="floatingInput">Email</label>
                                                    {formik.touched.email && formik.errors.email && (
                                                        <span style={{ color: "red" }}> {formik.errors.email}</span>
                                                    )}


                                                </div>


                                                <div className='form-floating'>

                                                    <input type="text" className={formik.touched.captcha && formik.errors.captcha ? "form-control is-invalid" : "form-control"} name="captcha" maxLength="5"
                                                        placeholder='Captcha'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.captcha}
                                                    />
                                                    <label for="floatingInput">Captcha</label>
                                                    {formik.touched.captcha && formik.errors.captcha && (
                                                        <span style={{ color: "red" }}> {formik.errors.captcha}</span>
                                                    )}
                                                </div>
                                                <div className='d-flex justify-content-end my-3'>
                                                    {imageSrc && (
                                                        <>
                                                            <img src={imageSrc} alt="Image" width={200} />
                                                            <div className='border border-2'>
                                                                <i className="fa-solid fa-2xl fa-rotate-right mt-4 mx-3" onClick={GenerateCaptchaImage}></i>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="text-center">

                                                    <button type="submit" className="btn btn-color w-100">
                                                        Submit
                                                    </button>

                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgetPassword;