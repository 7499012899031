import { Link, useNavigate } from 'react-router-dom';
import quizIcon from '../assets/img/quiz.png'
import ctfIcon from '../assets/img/ctfd.png'
const UserDashboard = () => {
    let navigate = useNavigate();
    const switchToQuizSection = () => {                    
        navigate("/quiz/quiz-dashboard")
     
      };

      const switchToCTFSection = () => {    
        navigate("/quiz/ctf-dashboard")
      };

    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li aria-current="page">Dashboard</li>

                    </ol>
                    <h2>Dashboard</h2>
                </div>
            </section>
            <div className="container wrapper">
            <>               
                <div className="section_our_solution">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="our_solution_category">
                                <div className="solution_cards_box">
                                    <div className="solution_card"  onClick={switchToQuizSection}>
                                        <div className="hover_color_bubble" />
                                        <div className="so_top_icon">
                                          <img src={quizIcon} width={40} height={50} />
                                        </div>
                                        <div className="solu_title">
                                            <h2 className="text-center text-white mt-5">Quiz</h2>
                                        </div>
                                        {/* <div className="solu_description">
                                            <p>
                                                It is a long established fact that a reader will be distracted
                                                by the readable content of a page when looking at its layout.
                                            </p>
                                            <button type="button" className="read_more_btn">
                                                Read More
                                            </button>
                                        </div> */}
                                    </div>
                                    
                                </div>                             
                                
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="our_solution_category">
                                <div className="solution_cards_box">                                    
                                    <div className="solution_card"  onClick={switchToCTFSection}>
                                        <div className="hover_color_bubble" />
                                        <div className="so_top_icon">
                                          <img src={ctfIcon} width={40} height={50} />
                                        </div>
                                        <div className="solu_title">
                                            <h2 className="text-center text-white mt-5">CTF</h2>
                                        </div>
                                        {/* <div className="solu_description">
                                            <p>
                                                It is a long established fact that a reader will be distracted
                                                by the readable content of a page when looking at its layout.
                                            </p>
                                            <button type="button" className="read_more_btn">
                                                Read More
                                            </button>
                                        </div> */}
                                    </div>
                                    
                                </div>                             
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
            </div>
        </div>
    )
}
export default UserDashboard