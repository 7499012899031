import '../assets/Style.css'
import '../assets/css/style.css'
import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import jwtDecode from 'jwt-decode';
import Badge from 'react-bootstrap/Badge';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import certificate from '../assets/img/certificate.png'
const UserAttemptedQuizzes = () => {
    let navigate = useNavigate();
    const [show1, setShow1] = useState(false);
    const [listUserResponse, setlistUserResponse] = useState([]);
    const [pdfData, setPdfData] = useState(null);
    const [error, setError] = useState(null);
    const [getCertificateName, setCertificateName] = useState("");
    const [getQuizName, setQuizName] = useState("");
    const handleClose1 = () => {
        setShow1(false);

    }
    const handleShow1 = () => setShow1(true);
    const renderNoCertificate = (props) => (
        <Tooltip id="noCertificate" {...props}>
            No Certificate issued
        </Tooltip>
    );

    const customStyles = {

        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: '500',
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: 'darkgray',
                color: 'black'
            },
        },
    };

    const columns = [
        {
            name: 'Sr No',
            selector: (row, index) => index + 1,
            center: true,

        },
        {
            name: 'Quiz Name',
            selector: (row) => row.selectedQuiz,
            cell: row => <span className="text-capitalize">{row.selectedQuiz}</span>,
            center: true,
        },

        {
            name: 'Total Questions',
            selector: (row) => row.totalQuestions,
            center: true,
        },
        {
            name: 'Attempted',
            selector: (row) => row.attempted,
            center: true,
        },
        {
            name: 'Unattempted',
            selector: (row) => row.unattempted,
            center: true,
        },
        {
            name: 'Correct',
            selector: (row) => row.correct,
            center: true,
        },
        {
            name: 'Wrong',
            selector: (row) => row.wrong,
            center: true,
        },
        // {
        //     name: 'Total Marks',
        //     selector: (row) => row.totalMarks,
        // },
        {
            name: 'Total Score',
            selector: (row) => row.totalScore,
            center: true,
        },
        {
            name: 'Status',
            selector: (row) => (
                <Badge pill bg={row.status === 'passed' ? 'success' : 'danger'}>
                    {row.status}
                </Badge>
            ),
            center: true,
        },

        {
            name: 'Certificate',
            selector: (row) => {
                if (row.status === 'passed') {
                    return <img src={certificate} width={50} height={50} className='p-2' onClick={() => onCertificatePreview(row.certFileName)} />;
                } else {

                    return (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderNoCertificate}
                        >
                            <i className="ri-error-warning-fill ri-2x"></i>
                        </OverlayTrigger>
                    );

                }
            },
            center: true,
        },

    ]



    const onCertificatePreview = (certFileName) => {
        console.log("Name", certFileName);
        setShow1(true);

        if (certFileName !== "File not found" && certFileName !== "notissued") {
            Services.GetCertificate(certFileName)
                .then(response => {
                    const data = response.data.Certificate;
                    setPdfData(data);
                })
                .catch(error => {
                    if (error.response && error.response.status === 500) {
                        swal("Server Error", error.response.data.msg, "error");
                    } else {
                        const errorMsg = error.response.data.msg;
                        const errors = error.response.data.errors;
                        let errorMessage = errorMsg + "\n\n";

                        for (const key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                errorMessage += key + ": " + errors[key] + "\n";
                            }
                        }

                        swal("Error occurred", errorMessage, "warning");
                    }
                });
        } else {
            setError("Error while generating Certificate")
            // let formData = new FormData();
            // formData.append("quizName", JSON.stringify(getQuizName));
            // formData.append("userName",JSON.stringify( username));
            // formData.append("certFileName", JSON.stringify(certFileName));

            // Services.GenerateCertificate(formData)
            //     .then(response => {
            //      console.log("generated");
            //     })
            //     .catch(error => {
            //         if (error.response && error.response.status === 500) {
            //             swal("Server Error", error.response.data.msg, "error");
            //         } else {
            //             const errorMsg = error.response.data.msg;
            //             const errors = error.response.data.errors;
            //             let errorMessage = errorMsg + "\n\n";

            //             for (const key in errors) {
            //                 if (errors.hasOwnProperty(key)) {
            //                     errorMessage += key + ": " + errors[key] + "\n";
            //                 }
            //             }

            //             swal("Error occurred", errorMessage, "warning");
            //         }
            //     });
        }

    }

    useEffect(() => {



        Services.GetUserResponse()
            .then(response => {
                setlistUserResponse(response.data);
                const responseData = response.data;
                const certFileName = responseData[0]?.certFileName;
                const QuizName = responseData[0]?.selectedQuiz;
                setCertificateName(certFileName);
                setQuizName(QuizName);
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
            })



    }, []);


    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li aria-current="page">
                            <Link to="/quiz/user-dashboard">
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link to="/quiz/quiz-dashboard">
                                Quiz
                            </Link>
                        </li>
                        <li>
                            Attempted Quizzes
                        </li>

                    </ol>
                    <h2>Attempted Quizzes</h2>
                </div>
            </section>
            <div className="container-xxl my-4">
            {listUserResponse.length > 0 ? (
                <DataTable
                    columns={columns}
                    data={listUserResponse}
                    highlightOnHover
                    pointerOnHover
                    responsive
                    pagination
                    paginationPerPage={10}
                    customStyles={customStyles}
                />
            ) : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                {columns.map((col, index) => (
                                    <th key={index}>{col.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={columns.length}>
                                    <p className='text-center pt-3'>There is no data to display.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
        
        <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-danger'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='fw-bold fs-5 mt-3 text-dark text-center'>
                    <div className="text-center">
                        {pdfData ? (
                            !error ? (
                                <embed src={pdfData} type="application/pdf" width="350px" height="300px" />
                            ) : (
                                <p className="fs-5 text-danger">{error}</p>
                            )
                        ) : (
                            <p className="fs-5 text-danger">Certificate has not been issued.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default UserAttemptedQuizzes