import BeatLoader from "react-spinners/BeatLoader";
import HashLoader from "react-spinners/HashLoader";
const Spinner = () => {
    // if (!isLoading) return null;
    return (
        <div className="sweet-loading d-flex justify-content-center loader">
        <HashLoader color="#012970" />
    </div>
    )
}

export default Spinner;