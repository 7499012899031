
import '../assets/Style.css'
import Form from 'react-bootstrap/Form';
import axios from "axios"
import { useState, useEffect, useRef } from 'react'
import swal from "sweetalert"
import { Modal } from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';
import Button from 'react-bootstrap/Button';
import jwtDecode from 'jwt-decode';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import Services from '../../src/Service/Service';
import QuizResult from '../QuizResult/QuizResult';
import Alert from 'react-bootstrap/Alert';
//import { preventBack, handleUnload } from '../PreventBack/PreventBack.js';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../Loader/Loader';


const QuizResponse = () => {

    const { qId } = useParams();
    const [listQuizzes, setlistQuizzes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [listQuizQuestion, setlistQuizQuestion] = useState([]);
    const [showScore, setShowScore] = useState(false);
    const [userResponseId, setuserResponseId] = useState('')
    const [counter, setCounter] = useState(0);
    const [error, setError] = useState(null);
    const [serverError, setserverError] = useState(false);
    const [timerFinished, setTimerFinished] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [correctInputOption, setCorrectInputOption] = useState({});
    const navigate = useNavigate();

    const STATUS = {
        STARTED: 'Started',
        STOPPED: 'Stopped',
    }
    const twoDigits = (num) => String(num).padStart(2, '0')
    const [minutesToDisplay1, setminutesToDisplay] = useState();
    const [hoursToDisplay1, sethoursToDisplay] = useState()
    const [gettimer, setTimer] = useState();
    const [getTime, setTime] = useState('');
    const [secondsRemaining, setSecondsRemaining] = useState();
    const [getQData, setQData] = useState([]);
    const [status, setStatus] = useState(STATUS.STOPPED);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const secondsToDisplay = secondsRemaining % 60;
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    const minutesToDisplay = minutesRemaining % 60;
    const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

    const location = useLocation();
    let token = sessionStorage.getItem("token");
    const handleStart = () => {
        setStatus(STATUS.STARTED);
    };

    const handleStop = () => {
        setStatus(STATUS.STOPPED);
    };



    const handleClose1 = () => {
        setShow1(false);
        navigate("/quiz/user-dashboard")
    }
    const handleShow1 = () => setShow1(true);


    const handleClose2 = () => {
        setShow2(false);
    }
    const handleShow2 = () => setShow2(true);

    const handleReset = () => {
        setStatus(STATUS.STOPPED);
        setSecondsRemaining(minutesToDisplay1);
    };

    useInterval(
        () => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining - 1);
            } else {
                setStatus(STATUS.STOPPED);
                setTimerFinished(true);
                onsubmitResponse();
            }
        },
        status === STATUS.STARTED ? 1000 : null
        // passing null stops the interval
    );


    const onModalSubmit = () => {
        onsubmitResponse();
        handleClose2();
    }



    const increaseButton = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    };

    const decreaseButton = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    };


    const onclickSubmitButton = () => {
        setShow2(true);
    }
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }




    const LoggedIn = sessionStorage.getItem("LoggedIn");
    let email = "";
    if (LoggedIn === "true") {
        const token = sessionStorage.getItem("token");
        email = getEmailFromToken(token);

        function getEmailFromToken(token) {

            const decodedToken = jwtDecode(token);

            const email = decodedToken ? decodedToken.sub : null;

            return email;
        }
    }

    const fetchQuestionData = () => {

        let storedKey = sessionStorage.getItem("refreshKey");
        let newString = storedKey.slice(0, -4);
        if (storedKey === qId) {

            Services.GetRandomQuestionsById(qId)
                .then(response => {
                    if (response.status === 200) {
                        setlistQuizQuestion(response.data);
                        const randomKey = uuidv4();
                        const randomString = randomKey.substr(0, 4);
                        const combinedString = qId + randomString;
                        sessionStorage.setItem("refreshKey", combinedString);

                        setIsLoading(false);
                        handleStart();
                    } else {
                        throw new swal("Message!", response.data.msg, "warning");
                    }
                }).catch(async err => {

                    if (err.response.status === 400 && err.response.data && err.response.data.errors) {
                        const errorMessage = err.response.data.msg;
                        const errorDetails = err.response.data.errors;
                        let errorText = "";
                        for (const key in errorDetails) {
                            if (errorDetails.hasOwnProperty(key)) {
                                const errorDetail = errorDetails[key];
                                errorText += "\n" + key + ": " + errorDetail;
                            }
                        }

                        console.log("text", errorText);
                        setError(errorText);

                    } else {

                        setserverError(true);
                    }
                });


            setIsLoading(false);
        } else {
            onRefreshFunction();
        }
    }


    const onRefreshFunction = () => {
        let text = "If the OK button is pressed, your quiz response will not be submitted";
        if (window.confirm(text)) {
            navigate("/");
        } else {
            sessionStorage.setItem("refreshKey", qId);
            fetchQuestionData()
        }

    }
    // const onClickOptionHandler = (questionId, markedOption) => {
    //     let newData = [...getQData];
    //     if (!markedOption) {
    //         markedOption = null;
    //     }
    //     newData.push({ questionId, markedOption });
    //     setQData(newData);
    // };


    // const onClickOptionHandler = (questionId, markedOption) => {
    //     let newData = [...getQData];
    //     newData.push({ questionId, markedOption })
    //     setQData(newData);
    // };

    // const ids = getQData.map(o => o.questionId)
    // const filtered = getQData.filter(({ questionId }, index) => !ids.includes(questionId, index + 1))



    // const data = {
    //     // "userResponseId": "",
    //     "quizId": qId,
    //     "email": email,
    //     "quizData": filtered

    // }


    const [selectedOptions, setSelectedOptions] = useState({});
    const onClickOptionHandler = (questionId, option) => {
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [questionId]: option,
        }));
    };

    let data;
    // console.log("data before submission", data);

    const onsubmitResponse = () => {
        setIsLoading(true);

        const responses = listQuizQuestion.slice(0, listQuizzes.quizSize).map((item) => {
            if (item.type === "single") {
                const selectedOption = selectedOptions[item.questionId] || "";
                return {
                    questionId: item.questionId,
                    markedOption: selectedOption,
                };
            } else if (item.type === "fill") {
                const userAnswer = correctInputOption[item.questionId] || "";
                return {
                    questionId: item.questionId,
                    markedOption: userAnswer,
                };
            } else if (item.type === "tf") {
                const selectedValue = selectedOptions[item.questionId] || "";
                return {
                    questionId: item.questionId,
                    markedOption: selectedValue,
                };
            } else {
                return {
                    questionId: item.questionId,
                    markedOption: "",
                };
            }
        });

      
        data = {
            // "userResponseId": "",
            "quizId": qId,
            "email": email,
            "quizData": responses

        }

        console.log("QResponse",data);
        Services.QuizAttend(data)
            .then(async response => {
                if (response.data.status === true) {
                    handleStop();
                    setIsLoading(false);
                    console.log("data submitted", data)
                    await swal("Success", response.data.msg, "success");
                    sessionStorage.removeItem("refreshKey");
                    var userid = (response.data.id);
                    if (showScore === true) {
                        navigate(`/quiz/result/${userid}`);
                        sessionStorage.removeItem("flag");
                    } else {
                        setShow1(true);
                        //navigate("/")
                        sessionStorage.setItem("flag", 0);
                    }
                } else {
                    await swal("Message!", response.data.msg, "warning");
                }
            }).catch(async err => {
                setIsLoading(false);
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
                else if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
         
            })
    }

    useEffect(() => {
        var shuffle = false;
        Services.GetQuizzesById(qId)
            .then(response => {
                setlistQuizzes(response.data);
                shuffle = response.data.shuffle;
                setShowScore(response.data.showScore)
                fetchQuestionData();
                setTime(response.data.totalDuration)
                let timDur = (response.data.totalDuration) * 60;
                const minutesToDisplay = timDur * 60;
                setminutesToDisplay(minutesToDisplay)
                setSecondsRemaining(timDur)
            }).catch(err => {
                //alert("error");
            })
    }, [])




    useEffect(() => {
        const disableCopyAndContextMenu = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', disableCopyAndContextMenu);
        document.addEventListener('copy', disableCopyAndContextMenu);

        return () => {
            document.removeEventListener('contextmenu', disableCopyAndContextMenu);
            document.removeEventListener('copy', disableCopyAndContextMenu);
        };
    }, []);



    return (
        <div>

            {listQuizQuestion.length !== 0 ? (
                <div className='container wrapper-home'>
                    {/* {isLoading && <Loader />} */}
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <h3 className='text-danger fw-bold mt-4 text-uppercase'>{listQuizzes.quizTitle} Quiz</h3>
                            <div className='col-md-12'>
                                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    <span className="text-danger">Disclaimer:</span>
                                    <span> Please do not</span>
                                    <span className="text-danger"> close, press back button or refresh </span>
                                    this page. Doing so, you will not be able to submit the quiz for this attempt.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div className="card">
                                    <div className='col-md-12'>
                                        <h5 className='timer p-3 bg-warning fw-bold text-dark rounded-2 m-2 text-center border border-primary-subtle'>
                                            {/* Render the onsubmitresponse method when timerFinished is true */}
                                            {/* {timerFinished ? onsubmitResponse() : null}  */}
                                            {/* Display the timer */}
                                            {`${twoDigits(hoursToDisplay)}:${twoDigits(minutesToDisplay)}:${twoDigits(secondsToDisplay)}`}
                                        </h5>
                                    </div>


                                    <div id="carouselExampleIndicators" className="carousel carousel-fade" data-bs-wrap="false" data-bs-interval="false">
                                        <div className="card-body">
                                            <div className="carousel-inner">
                                                {
                                                    listQuizzes.quizSize !== 0 &&
                                                    listQuizQuestion.slice(0).map((item, index) => {
                                                        return <div className={`carousel-item ${index == 0 ? 'active' : ''}`}>
                                                            {/* <h6 className='fw-bold text-success'>{index + 1}. Question</h6> */}
                                                            {/* <h5 className='mt-4 fw-bold'>
                                                        <pre><code>{index + 1}. {item.questionTitletext}</code></pre></h5> */}
                                                            {/* <h5 className='mt-4 fs-5'> */}
                                                            {/* <pre> */}
                                                            {/* <p dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item.questionTitletext}` }}></p> */}
                                                            {/* <p style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item.questionTitletext}` }}></p> */}
                                                            {/* <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item.questionTitletext}` }}></span> */}
                                                            <p style={{ display: 'inline-flex' }} dangerouslySetInnerHTML={{ __html: `${index + 1}. &nbsp; ${item.questionTitletext}` }}></p>

                                                            {/* </pre> */}
                                                            {/* </h5> */}
                                                            {item.imageName && item.imageName.substring(item.imageName.lastIndexOf('/') + 1) !== "NA" && (
                                                                <div className="col-md-6 mb-2">
                                                                    <img
                                                                        src={item.imageName}
                                                                        alt="Image.png"
                                                                        className="img-responsive"
                                                                        width={200}
                                                                        height={200}
                                                                    />
                                                                </div>
                                                            )}

                                                            {item.type === "single" ? (
                                                                <div>
                                                                    <div className="form-check unselectable">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={index}
                                                                            id="flexRadioDefault1"
                                                                            value="a"
                                                                            defaultChecked=""
                                                                            onClick={() => onClickOptionHandler(item.questionId, "a")}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                            {item.option1}
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check unselectable">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={index}
                                                                            id="flexRadioDefault2"
                                                                            value="b"
                                                                            defaultChecked=""
                                                                            onClick={() => onClickOptionHandler(item.questionId, "b")}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                            {item.option2}
                                                                        </label>
                                                                    </div>
                                                                    {item.option3 && (
                                                                        <div className="form-check unselectable">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={index}
                                                                                value="c"
                                                                                id="flexRadioDefault1"
                                                                                defaultChecked=""
                                                                                onClick={() => {
                                                                                    onClickOptionHandler(item.questionId, "c");
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {item.option3}
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                    {item.option4 && (
                                                                        <div className="form-check unselectable">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={index}
                                                                                value="d"
                                                                                id="flexRadioDefault1"
                                                                                defaultChecked=""
                                                                                onClick={() => {
                                                                                    onClickOptionHandler(item.questionId, "d");
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {item.option4}
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                    {item.option5 && (
                                                                        <div className="form-check unselectable">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={index}
                                                                                value="e"
                                                                                id="flexRadioDefault1"
                                                                                defaultChecked=""
                                                                                onClick={() => {
                                                                                    onClickOptionHandler(item.questionId, "e");
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {item.option5}
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                    {item.option6 && (
                                                                        <div className="form-check unselectable">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={index}
                                                                                value="f"
                                                                                id="flexRadioDefault1"
                                                                                defaultChecked=""
                                                                                onClick={() => {
                                                                                    onClickOptionHandler(item.questionId, "f");
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {item.option6}
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : item.type === "fill" ? (
                                                                <div className="col-md-3">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="answer" className="form-label">
                                                                            Enter your Answer
                                                                        </label>
                                                                        <input
                                                                            autoComplete="off"
                                                                            type="answer"
                                                                            className="form-control"
                                                                            id="answer"
                                                                            name="answer"
                                                                            // value={correctInputOption}
                                                                            onChange={(e) => setCorrectInputOption(prevState => ({
                                                                                ...prevState,
                                                                                [item.questionId]: e.target.value
                                                                            }))}

                                                                            maxLength={item.option1}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : item.type === "tf" ? (
                                                                
                                                                <div>
                                                                    <div className='col-md-1'>
                                                                        <div className='row'>
                                                                           
                                                                            <div className='col-md-4'>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="true"
                                                                                        id="flexRadioDefault1"                                                                                                                                                      
                                                                                        value="true"
                                                                                        checked={selectedOptions[item.questionId] === "true"}
                                                                                        onClick={() => onClickOptionHandler(item.questionId, "true")} 
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-8'>
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">True</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-1 pt-2'>
                                                                        <div className='row'>
                                                                            
                                                                            <div className='col-md-4'>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="false"
                                                                                        id="flexRadioDefault1"
                                                                                        value="false"
                                                                                        checked={selectedOptions[item.questionId] === "false"}
                                                                                        onClick={() => onClickOptionHandler(item.questionId, "false")} 
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-8'>
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">False</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                        
                                                            ) : null}

                                                            {index + 1 === listQuizzes.quizSize && <div className='text-end'>
                                                                <button type="button" onClick={onclickSubmitButton} className="btn btn-success">Submit</button>
                                                            </div>
                                                            }
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card mt-2 mb-5'>
                                    <div className='card-body-Next'>

                                        <div className='d-flex justify-content-between'>
                                            <button className="btn btn-outline-light" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" disabled={currentQuestionIndex === 0} onClick={decreaseButton}>
                                                <i className="fa fa-lg fa-fw fa-angle-double-left"></i>
                                                <span>Previous</span>
                                            </button>
                                            <button className="btn btn-outline-light" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" disabled={currentQuestionIndex === listQuizzes.quizSize - 1} onClick={increaseButton}>
                                                <i className="fa fa-lg fa-fw fa-angle-double-right"></i>
                                                <span>Next</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div>
                    {serverError ? (
                        <div className="container wrapper-home">
                            <div className='col-md-12'>
                                <Alert variant="danger">
                                    <Alert.Heading>Server Error</Alert.Heading>
                                    <p>An error occurred while fetching quiz data from the server.</p>
                                    <Alert.Link href="/"><i className="fa fa-fw fa-home" aria-hidden="true"></i>Home</Alert.Link>
                                </Alert>
                            </div>
                        </div>
                    ) : (
                        <div className="container wrapper-home">
                            <div className='col-md-12'>
                                <Alert variant="danger">
                                    <Alert.Heading>Quiz Cannot be Started!</Alert.Heading>
                                    <p>{error}</p>
                                    <Alert.Link href="/"><i className="fa fa-fw fa-home" aria-hidden="true"></i>Home</Alert.Link>
                                </Alert>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-danger'>{listQuizzes.quizTitle} Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fw-bold fs-5 mt-3 text-dark text-center'>Thank you!Your quiz has been submitted successfully</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-danger'>{listQuizzes.quizTitle} Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fw-bold fs-5 mt-3 text-dark text-center'>Are you sure you want to submit the quiz?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onModalSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>

    )
}

export default QuizResponse;