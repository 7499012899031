import axios from "axios";
import API_BASE_URL from "../config/config";
import { v4 as uuidv4 } from 'uuid';
import UserUtil from "../UserUtil/UserUtil";
import CAKE_BASE_URL from "../config/configurl";


const USERSERVICE_BASE_URL = API_BASE_URL;

// let token = sessionStorage.getItem("token");
let token = UserUtil.getCookieStorage("token");
const randomKey = uuidv4();

console.log("tok",token)

class Service {

//    -----------------------------------------IVP API's--------------------------
    Ivpauthorize(body) {
        return axios.post("http://dhananjay.hyderabad.cdac.in:8000" + "/tokengen", body);
    }

    Userinfo(ivptoken) {
        return axios.post("http://dhananjay.hyderabad.cdac.in:8000" + "/userinfo", null,
            {
                headers: {
                    Authorization: `Bearer ${ivptoken}`
                }
            }
        );
    }

    IvpLogin(data) {
        return axios.post(USERSERVICE_BASE_URL + "/social/IVP/login", data

        );
    }


    //    -----------------------------------------QUIZ API's--------------------------

    GenerateCaptcha() {
        return axios.get(USERSERVICE_BASE_URL + "/generateCaptcha",
            {
                headers: {
                    randomToken: randomKey,
                }
            }
        );
    }

    ValidateCaptcha(data) {
        return axios.post(USERSERVICE_BASE_URL + "/validateCaptcha", data,
            {
                headers: {
                    randomToken: randomKey,
                }
            }
        );
    }

    SignUp(user) {
        return axios.post(USERSERVICE_BASE_URL + "/register", user,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }

    Login(data) {
        return axios.post(USERSERVICE_BASE_URL + "/login", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );

    }


    PostGoogleCredentials(data) {
        return axios.post(USERSERVICE_BASE_URL + "/social/google/login", null,
            {
                headers: {
                    Authorization: `${data}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    ForgotPassword(data) {
        return axios.post(USERSERVICE_BASE_URL + "/forgotpass", data);

    }

    ResetPassword(data, randomNumber) {
        return axios.post(USERSERVICE_BASE_URL + `/reset/${randomNumber}`, data);

    }

    Logout() {
        return axios.get(USERSERVICE_BASE_URL + "/signout",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );

    }

    CreateQuiz(data) {
        return axios.post(USERSERVICE_BASE_URL + "/quiz/add", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }



    UpdateQuiz(data) {
        return axios.put(USERSERVICE_BASE_URL + "/quiz/update", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    UpdateEmails(data) {
        return axios.put(USERSERVICE_BASE_URL + "/quiz/updateemails", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    GetQuizzes() {
        return axios.get(USERSERVICE_BASE_URL + "/quiz/get",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetQuizzesById(quizId) {
        return axios.get(USERSERVICE_BASE_URL + `/quiz/get/${quizId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetListOfEmails(){
        return axios.get(USERSERVICE_BASE_URL + "/listofemails",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


   

    DeleteQuiz(quizid) {
        return axios.delete(USERSERVICE_BASE_URL + `/quiz/delete/${quizid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetQuizzesByStartDate() {
        return axios.get(USERSERVICE_BASE_URL + "/quiz/get-quiz-by-startdate-and-enddate",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    SingleChoice(data) {
        return axios.post(USERSERVICE_BASE_URL + "/question/add", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    UpdateSingleChoice(data) {
        return axios.put(USERSERVICE_BASE_URL + "/question/update", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    GetQuestions() {
        return axios.get(USERSERVICE_BASE_URL + "/question/get",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    UploadImage(data) {
        return axios.post(USERSERVICE_BASE_URL + "/upload/image", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetQuestionsById(quizId) {
        return axios.get(USERSERVICE_BASE_URL + `/question/${quizId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetQuestionsByquestionId(questionId) {
        return axios.get(USERSERVICE_BASE_URL + `/question/get/${questionId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetRandomQuestionsById(quizId) {
        return axios.get(USERSERVICE_BASE_URL + `/question/random/${quizId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }



    DeleteQuestions(questionid) {
        return axios.delete(USERSERVICE_BASE_URL + `/question/delete/${questionid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


   
    
    DeleteImageinQuestion(id,imageName) {
        return axios.delete(USERSERVICE_BASE_URL + `/question/delete/question-image/${id}/${imageName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    BulkUpload(excelUpload) {
        return axios.post(USERSERVICE_BASE_URL + "/upload/excel", excelUpload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    BulkUploadEmails(excelUpload) {
        return axios.post(USERSERVICE_BASE_URL + "/upload/quiz/excel-emails", excelUpload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    FillintheBlanks(data) {
        return axios.post(USERSERVICE_BASE_URL + "/fill-in-the-blanks/add", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    UpdateFillintheBlanks(data) {
        return axios.put(USERSERVICE_BASE_URL + "/fill-in-the-blanks/update", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetFillintheblanksByquestionId(questionId) {
        return axios.get(USERSERVICE_BASE_URL + `/fill-in-the-blanks/get/${questionId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    TrueorFalse(data) {
        return axios.post(USERSERVICE_BASE_URL + "/true-false/add", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }

    UpdateTrueorFalse(data) {
        return axios.put(USERSERVICE_BASE_URL + "/true-false/update", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }




    QuizAttend(data) {
        return axios.post(USERSERVICE_BASE_URL + "/quiz/attend", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetResult(userId) {
        return axios.get(USERSERVICE_BASE_URL + `/userresponse/get/id/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }



    GetUserResponse() {
        return axios.get(USERSERVICE_BASE_URL + `/userresponse/get/email`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetQuizByWhitelistedEmails() {
        return axios.get(USERSERVICE_BASE_URL + `/quiz/get-quiz-by-whitelisted-emails`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    UploadCertificate(data) {
        return axios.post(USERSERVICE_BASE_URL + "/upload/certificate", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
        );
    }


    GetAllCertificate() {
        return axios.get(USERSERVICE_BASE_URL + "/certificate/getall",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetCertificate(certificateName) {
        return axios.get(USERSERVICE_BASE_URL + `/certificate/get/${certificateName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/pdf",
                    //"responseType": 'arraybuffer'

                }
            }
        );
    }


    //    -----------------------------------------CTFd API's--------------------------



    CreateChallenge(data) {
        return axios.post(USERSERVICE_BASE_URL + "/challenge/add", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "multipart/form-data"

                }
            }
        );
    }

    GetChallengeById(challengeId) {
        return axios.get(USERSERVICE_BASE_URL + `/challenge/get/${challengeId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetChallenges() {
        return axios.get(USERSERVICE_BASE_URL + `/challenge/get-all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetChallengesByVisibility() {
        return axios.get(USERSERVICE_BASE_URL + `/challenge/get-by-visibility`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    UpdateChallenge(data) {
        return axios.put(USERSERVICE_BASE_URL + "/challenge/update", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    DeleteFilesinChallenge(challengeId,imageName) {
        return axios.delete(USERSERVICE_BASE_URL + `/challenge/delete/challenge-image/${challengeId}/${imageName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }

    CreateChallengeList(data) {
        return axios.post(USERSERVICE_BASE_URL + "/challengelist/add", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }

    GetChallengeList() {
        return axios.get(USERSERVICE_BASE_URL + `/challengelist/get`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    // "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetChallengeListById(challengeListId) {
        return axios.get(USERSERVICE_BASE_URL + `/challengelist/get/${challengeListId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    UpdateChallengeList(data) {
        return axios.put(USERSERVICE_BASE_URL + "/challengelist/update", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    UpdateEmailsforChallengeList(data) {
        return axios.put(USERSERVICE_BASE_URL + "/challengelist/update/emails", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }

    BulkUploadEmailsforChallengeList(excelUpload) {
        return axios.post(USERSERVICE_BASE_URL + "/upload/challengelist/excel-emails", excelUpload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }


   

    GetEmailsListinChallengeList() {
        return axios.get(USERSERVICE_BASE_URL + `/challengelist/get/whitelistedemails`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    ChallengeResponseAttend(data) {
        return axios.post(USERSERVICE_BASE_URL + "/challenge-response/attend", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"

                }
            }
        );
    }


    GetChallengeResponsebyEmail() {
        return axios.get(USERSERVICE_BASE_URL + `/challenge-response/get/email`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );        
    }


    GetChallengeResponseInSolves(challengeId) {
        return axios.get(USERSERVICE_BASE_URL + `/challenge-response/get/email/${challengeId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    GetLeaderBoard(challengeListId) {
        return axios.get(USERSERVICE_BASE_URL + `/leaderboard/get/${challengeListId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetLeaderBoardAdmin() {
        return axios.get(USERSERVICE_BASE_URL + `/leaderboard/get`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }

    DownloadFileinChallenge(fileName) {
        return axios.get(USERSERVICE_BASE_URL + `/download-file/${fileName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json",
                   
                }
            }
        );
    }

    GetHintData(challengeId,hintIndex) {
        return axios.get(USERSERVICE_BASE_URL + `/challenge/get/hint/${challengeId}/${hintIndex}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetScoreboardofUserofCTF() {
        return axios.get(USERSERVICE_BASE_URL + `/leaderboard/user/get/graph/ctf`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


    GetScoreboardofUserofCTFPerformance(challengeListId) {
        return axios.get(USERSERVICE_BASE_URL + `/leaderboard/user/get/graph/${challengeListId} `,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-control-Allow-origin": "*",
                    'Accept': 'application/json, text/plain, */*',
                    "Content-type": "application/json"
                }
            }
        );
    }


//    -----------------------------------------CAKES API's--------------------------

// GetRoleOfCakesUser(userId,courseId) {
//     return axios.get(`http://10.244.3.218:8085/api/tenantUserBindCheck/${userId}/${courseId}`,
//         {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Access-control-Allow-origin": "*",
//                 'Accept': 'application/json, text/plain, */*',
//                 "Content-type": "application/json"
//             }
//         }
//     );
// }


GetRoleOfCakesUser(userId,courseId) {
    return axios.get(CAKE_BASE_URL+`/api/tenantUserBindCheck/${userId}/${courseId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-control-Allow-origin": "*",
                'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json",
                //"Origin": "http://megh1.hyderabad.cdac.in" 
            }
        }
    );
}




// GetCoursesFromCakes(userId) {
//     return axios.get(`http://10.244.3.218:8082/courseOrganizer/getCoursesByuserIdforCTF/${userId}`,
//         {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Access-control-Allow-origin": "*",
//                 'Accept': 'application/json, text/plain, */*',
//                 "Content-type": "application/json"
//             }
//         }
//     );
// }



GetCoursesFromCakes(userId) {
    return axios.get(CAKE_BASE_URL+`/courseOrganizer/getCoursesByuserIdforCTF/${userId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-control-Allow-origin": "*",
                'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json",
                //"Origin": "http://megh1.hyderabad.cdac.in" 
            }
        }
    );
}





// GetListOfEmailsFromCakes(courseId){
//     return axios.get(`http://10.244.3.218:8085/api/getCourseEnrolledLearners/${courseId}/2`,
//         {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Access-control-Allow-origin": "*",
//                 'Accept': 'application/json, text/plain, */*',
//                 "Content-type": "application/json"
//             }
//         }
//     );
// }



GetListOfEmailsFromCakes(courseId){
    return axios.get(CAKE_BASE_URL +`/api/getCourseEnrolledLearners/${courseId}/2`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-control-Allow-origin": "*",
                'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json",
                //"Origin": "http://megh1.hyderabad.cdac.in" 
            }
        }
    );
}

// ------------------------------------------------------------------------------------------------------------------------

PostSyncEmails(data) {
    return axios.post(USERSERVICE_BASE_URL + "/cakes-user", data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-control-Allow-origin": "*",
                'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json"

            }
        }
    );
}


GetAdminGraphData(challengeListId) {
    return axios.get(USERSERVICE_BASE_URL + `/leaderboard/admin/get/graph/${challengeListId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-control-Allow-origin": "*",
                'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json"
            }
        }
    );
}


GetAdminChallengePerformanceGraphData() {
    return axios.get(USERSERVICE_BASE_URL + `/leaderboard/admin/get/graph/challenges`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-control-Allow-origin": "*",
                'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json"
            }
        }
    );
}
 
}

export default new Service();