import { Link , useNavigate} from "react-router-dom";
import { useState, useEffect } from 'react';
import Services from '../../src/Service/Service';
import jwtDecode from 'jwt-decode';
import DataTable from 'react-data-table-component';
import swal from "sweetalert"
import URLs from "../config/Urls";
import UserUtil from "../UserUtil/UserUtil";

const SyncUsers = () => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const [listUserEmails, setListUserEmails] = useState([]);
    let navigate = useNavigate();
    // const LoggedIn = sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getCookieStorage("LoggedIn");
    let userId = "";

    if (LoggedIn === "true") {
        // const token = sessionStorage.getItem("token");
        const token = UserUtil.getCookieStorage("token");
        userId = getUserIdfromToken(token);


        function getUserIdfromToken(token) {

            const decodedToken = jwtDecode(token);

            const userId = decodedToken ? decodedToken.sub : null;

            return userId;
        }



    }

    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',
               
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius:'5px'
            },
        },
    };

    const columns = [
        {
            name: 'Sr No',
            selector: (row, index) => index + 1,
            maxWidth: '100px',
           center:true

        },
        {
            name: 'Name',
            selector: (row) => row.firstName,
            maxWidth: '700px',
            center: true




        },
        {
            name: 'Email',
            selector: (row) => row.email,
            maxWidth: '700px',
            center: true




        },

    ];


    const handleSyncUsers = () => {
        // Transform listUserEmails into the desired format
        const formattedData = listUserEmails.map(user => ({
            email: user.email,
            username: user.firstName // Assuming firstName is the username field
        }));
    
        console.log("email data",formattedData);


        Services.PostSyncEmails(formattedData)

        .then(async response => {
            if (response.data.status === true) {
                if (response.data.existingUsersCount !== null && response.data.newUsersCount !== null) {
                  const message = `Existing Users Count: ${response.data.existingUsersCount}\nNew Users Count: ${response.data.newUsersCount}`;
                  await swal("Success", message, "success");
                } else {
                  await swal("Success", response.data.msg, "success");
                }
              } else {
                await swal("Message", response.data.msg, "info");
              }

        }).catch(err => {
            if (err.response && err.response.status === 401) {
                window.location.href = URLs.CAKES;
                // const errorMessage = err.response.data.errors.UnAuthorized;

                // swal("Message!", errorMessage, "warning");
                // Services.Logout()
                //     .then(response => {
                //         if (response.status === 200) {
                //             sessionStorage.removeItem("token");
                //             sessionStorage.removeItem("LoggedIn");
                //             navigate("/");
                //         }
                //     })
                //     .catch(err => {
                //         // Handle error if needed
                //     });

                //UserUtil.logout();
            }
            else if (err.response && err.response.data && err.response.data.errors) {
                let errorMessages = Object.values(err.response.data.errors).join('\n');
                swal("Message!", errorMessages, "warning");
            } else if (err.response && err.response.data && err.response.data.msg) {
                swal("Message!", err.response.data.msg, "warning");
            } else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

      
    };
    




    const handleCourseChange = (courseId) => {
        // const courseId = e.target.value;
        // const courseName = e.target.options[e.target.selectedIndex].text;

        // // Create an object for the selected course
        // const selected = {
        //     [courseId]: courseName
        // };

        // // Update the selected course state
        // setSelectedCourse(selected);

        // if (courseId !== '') {
        Services.GetListOfEmailsFromCakes(courseId)
            .then(response => {
                setListUserEmails(response.data)

            }).catch(err => {

                if (err.response && err.response.data.status === 500) {

                    swal("Message!", err.response.data.message, "info");
                } else {
                    // Fallback if the response structure is different
                    swal("Error", "Failed to fetch data. Please try again later.", "error");
                }
            });
        // }
    };

    useEffect(() => {
        Services.GetCoursesFromCakes(userId).then(response => {
            console.log("data", response.data);
            setCourses(response.data);
        }).catch(err => {


        });

    }, [userId])


    return (
        <div>
            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>
                        <li>
                        <a  href={URLs.CAKES}>
                                CAKES
                            </a>
                        </li>

                        <li>
                        <Link to="/admin-dashboard/ctf">
                               Admin  Dashboard
                            </Link>
                        </li>
                    </ol>
                    <h2>Users</h2>
                </div>
            </section>
            <div className="container my-4">
                <div className='col-md-12'>

                    <div className="col-md-6">
                        <div className="custom-select-quiz">
                            <h3>Choose Course</h3>
                            <select
                                className="form-select"
                                data-title="Course"
                                name="Course"
                                onChange={(e) => {
                                    const selectedId = e.target.value;
                                    // setSelectedQuiz(selectedId); // Update state with selected quiz ID
                                    if (selectedId) {
                                        handleCourseChange(selectedId) // Call API function when an option is selected
                                    }
                                }}
                            >
                                <option value="">Choose Course</option>
                                {courses.map(course => (
                                    <option key={course.courseId} value={course.courseId}>
                                        {course.courseName}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>

                    <div className='mt-4'>
                        {listUserEmails.length > 0 ? (
                            <div>

                                <DataTable
                                    columns={columns}
                                    data={listUserEmails}
                                    highlightOnHover
                                    pointerOnHover
                                    responsive
                                    pagination
                                    paginationPerPage={10}
                                    customStyles={customStyles}
                                />

                                <div className="text-center">
                                    <button className="btn-theme"  onClick={handleSyncUsers}><i class="bi bi-envelope-at-fill"></i> Sync Emails</button>
                                </div>
                            </div>

                        ) : (
                            <div className='user-dashboard'>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="text-center"> 
                                            <tr>
                                                {columns.map((col, index) => (
                                                    <th key={index}>{col.name}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={columns.length}>
                                                    <p className='text-center pt-3'>There is no data to display.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SyncUsers