import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// import './QuizQuestion.css'

const QuizQuestion = () => {
    const renderTooltipEdit = (props) => (
        <Tooltip id="EditOption" {...props}>
            Update the Question
        </Tooltip>
    );

    const renderTooltipDelete = (props) => (
        <Tooltip id="DeleteOption" {...props}>
            Delete the Question
        </Tooltip>
    );

    const renderTooltipActiveDeactive = (props) => (
        <Tooltip id="ActiveDeactive" {...props}>
            Activate or Deactivate the Question
        </Tooltip>
    );
    return (
        <div>
            <div className="container-fluid-total">
                <div className="breadcrumb mt-3 ">
                    <Link to="/" style={{ textDecoration: 'none', color: "black" }}>
                        <a href="#" className="item">
                            <span className="arrow" />
                            <span className="hide-mobile">Home</span>
                            <span className="show-mobile">Home</span>
                        </a>
                    </Link>
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: "black" }}>
                        <a href="#" className="item">
                            <span className="arrow" />
                            <span className="hide-mobile">Dashboard</span>
                            <span className="show-mobile">DashBoard</span>
                        </a>
                    </Link>
                    <Link to="/dashboard/create-quiz" style={{ textDecoration: 'none', color: "black" }}>
                        <a href="#" className="item active">
                            <span className="arrow" />
                            <span className="hide-mobile">Quiz Questions</span>
                            <span className="show-mobile">Quiz Questions</span>
                        </a>
                    </Link>
                </div>
                <div className="card">
                    <div className="card-body-total">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Sr No</th>
                                        <th scope="col">Question</th>
                                        <th scope="col">Question Type</th>
                                        <th scope="col">Created</th>
                                        <th scope="col">Updated</th>
                                        <th scope="col">Options</th>
                                        <th scope="col">Activate/Deactivate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="table-info">
                                        <th scope="row">1</th>
                                        <td id="questionDisplay">What is Inheritance in java? Explain its types?</td>
                                        <td>Single Choice</td>
                                        <td>11-10-2022</td>
                                        <td>11-10-2022</td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipEdit}
                                            >
                                                <i className="fas fa-pen" style={{ paddingLeft: "20px" }}> | </i>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipDelete}
                                            >
                                                <i className='fas fa-trash' style={{ paddingLeft: "10px" }}></i>
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipActiveDeactive}
                                            >
                                                <label className="custom-control ios-switch">
                                                    <input type="checkbox" className="ios-switch-control-input" />
                                                    <span className="ios-switch-control-indicator" />
                                                </label>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                    <tr className="table-info">
                                        <th scope="row">2</th>
                                        <td>What is Abstraction in java?</td>
                                        <td>Single Choice</td>
                                        <td>11-10-2022</td>
                                        <td>11-10-2022</td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipEdit}
                                            >
                                                <i className="fas fa-pen" style={{ paddingLeft: "20px" }}> | </i>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipDelete}
                                            >
                                                <i className='fas fa-trash' style={{ paddingLeft: "10px" }}></i>
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipActiveDeactive}
                                            >
                                                <label className="custom-control ios-switch">
                                                    <input type="checkbox" className="ios-switch-control-input" />
                                                    <span className="ios-switch-control-indicator" />
                                                </label>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizQuestion;