import { Link, useNavigate } from "react-router-dom";
import Services from '../../src/Service/Service';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DataTable from 'react-data-table-component';
import Tooltip from 'react-bootstrap/Tooltip';
import { useState, useEffect } from 'react';
import swal from "sweetalert"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';


const TotalQuizzes = () => {
    const [listQuizzes, setlistQuizzes] = useState([]);
    const [listQuizQuestions, setlistQuizQuestions] = useState([]);
    const [show, setShow] = useState(false);
    const [modalId, setModalId] = useState(null);
    const [fullscreen, setFullscreen] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    let navigate = useNavigate();

    const renderTooltipAddUser = (props) => (
        <Tooltip id="Preview" {...props}>
            Add Users
        </Tooltip>
    );

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setModalId(id);
        Services.GetQuestionsById(id)
            .then(response => {
                setlistQuizQuestions(response.data)
                console.log("Res", response.data)
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
                else {
                    swal("Message!", "Server error occurred", "warning");
                }
                // alert("Server Error 500")
            })

    }

    const handleFilter = (e) => {
        const keyword = e.target.value.toLowerCase();
        const filtered = listQuizzes.filter((item) =>
            item.quizTitle.toLowerCase().includes(keyword) ||
            item.shortCode.toLowerCase().includes(keyword) ||
            item.description.toLowerCase().includes(keyword) ||
            item.noOfAttempts.toString().includes(keyword) ||
            item.totalDuration.toString().includes(keyword) ||
            item.quizSize.toString().includes(keyword) ||
            item.passPercentage.toString().includes(keyword) ||
            item.startDate.includes(keyword) ||
            item.endDate.includes(keyword) ||
            moment(item.createdDate).format('DD/MM/YYYY, h:mm:ss a').includes(keyword) ||
            moment(item.updatedDate).format('DD/MM/YYYY, h:mm:ss a').includes(keyword)
        );
        setFilteredData(filtered);
    };

    const renderTooltipEdit = (props) => (
        <Tooltip id="EditOption" {...props}>
            Edit
        </Tooltip>
    );

    // const renderTooltipDelete = (props) => (
    //     <Tooltip id="DeleteOption" {...props}>
    //         Delete the Quiz
    //     </Tooltip>
    // );

    const renderTooltipPreview = (props) => (
        <Tooltip id="Preview" {...props}>
            View Quiz question
        </Tooltip>
    );
    const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            }
        },
        headCells: {
            style: {
                fontSize: '17px',
                fontWeight: '500',
                // paddingLeft: '0 8px',
                // marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',

            },
        },
        headRow: {
            style: {
                backgroundColor: 'darkgray', // Set the background color of the header row
            },
        },
    };

    const columns = [
        { name: 'Sr No', selector: (row, index) => index + 1, sortable: true },
        { name: 'Quiz Title', selector: (row) => row.quizTitle, sortable: true },
        { name: 'Shortcode', selector: (row) => row.shortCode, sortable: true },
        { name: 'Description', selector: (row) => row.description, sortable: true },
        { name: 'Attempts', selector: (row) => row.noOfAttempts, sortable: true },
        { name: 'Time Duration(min)', selector: (row) => row.totalDuration, sortable: true },
        {
            name: 'Publish',
            selector: (row) => row.publish ? 'Published' : 'Unpublished'
        },
        { name: 'No of Questions', selector: (row) => row.quizSize, sortable: true },
        { name: 'Pass Percentage', selector: (row) => row.passPercentage, sortable: true },
        { name: 'Start Date', selector: (row) => row.startDate, sortable: true },
        { name: 'End Date', selector: (row) => row.endDate, sortable: true },
        { name: 'Created', selector: (row) => row.createdDate, sortable: true },
        { name: 'Updated', selector: (row) => row.updatedDate, sortable: true },
        {
            name: 'Options',

            cell: (row) => (
                <>

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipEdit}
                    >
                        <Link to={`/dashboard/quiz/update-quiz/${row.quizId}`}>
                            <button className="btn btn-warning me-1">
                                <i className="fas fa-fw fa-pen" ></i>
                            </button>
                        </Link>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipPreview}
                    >
                        <button className="btn btn-info me-1">
                            <i className="fas fa-fw fa-solid fa-eye" onClick={() => handleShow(row.quizId)}></i>
                        </button>
                    </OverlayTrigger>

                    {row.quizType === 'assignQuiz' && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipAddUser}
                        >
                            <Link to={`/dashboard/quiz/assign-user/${row.quizId}`}>
                                <button className="btn btn-primary me-1">
                                    <i className="ri-user-add-fill"></i>
                                </button>
                            </Link>
                        </OverlayTrigger>
                    )}

                </>

            ),
            maxWidth: '900px',
            wrap: true,
            compact: true,
            grow: false,
        },
    ];


    // const deleteById = (id) => {
    //     swal({
    //         title: "Are you sure?",
    //         text: "Once deleted, you will not be able to recover this Quiz!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 Services.DeleteQuiz(id)
    //                     .then(async response => {
    //                         if (response.data.status == true) {
    //                             await swal("Success", response.data.msg, "success");
    //                             const updatedData = listQuizzes.filter(item => item.quizId !== id);
    //                             setlistQuizzes(updatedData)
    //                         } else {
    //                             await swal("Message!", response.data.msg, "info")
    //                         }
    //                     }).catch(async err => {
    //                         await swal("Message!", "Server Error", "warning")
    //                     })
    //             } else {
    //                 swal("Your Quiz is safe!");
    //             }
    //         })
    // }




    useEffect(() => {
        Services.GetQuizzes().then(response => {
            setlistQuizzes(response.data);
            console.log(response.data);
        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                Services.Logout()
                    .then(response => {
                        if (response.status === 200) {
                            sessionStorage.removeItem("token");
                            sessionStorage.removeItem("LoggedIn");
                            navigate("/");
                        }
                    })
                    .catch(err => {
                        // Handle error if needed
                    });
            }
            else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

    }, [])

    return (
        <div className="Quiz-admin">
            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                DashBoard
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/quiz">
                                Quiz
                            </Link>
                        </li>
                        <li aria-current="page">Quiz List</li>

                    </ol>
                    <h2>Quiz List</h2>

                </div>
            </section>
            <div className="container-fluid mt-4">


                {listQuizzes && (
                    <div className="col-md-12 d-flex justify-content-end">
                        <form className="d-flex m-2" role="search">
                            <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={handleFilter}
                            />
                        </form>
                    </div>
                )}
                <DataTable
                    columns={columns}
                    data={filteredData.length > 0 ? filteredData : listQuizzes}
                    striped
                    highlightOnHover
                    pagination
                    paginationPerPage={10}
                    noHeader
                    responsive
                    customStyles={customStyles}
                />
                {/* <div className="table-responsive">
                            <table className="table table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Sr No</th>
                                        <th scope="col">Quiz Title</th>
                                        <th scope="col">Shortcode</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Attempts</th>
                                        <th scope="col">Time Duration(min)</th>
                                        <th scope="col">No of Questions</th>
                                        <th scope="col">Pass Percentage</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Created</th>
                                        <th scope="col">Updated</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listQuizzes.length !== 0 && listQuizzes.map((item, index) => {
                                            return <tr className="table-info">
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.quizTitle}</td>
                                                <td>{item.shortCode}</td>
                                                <td>{item.description}</td>
                                                <td>{item.noOfAttempts}</td>
                                                <td>{item.totalDuration}</td>
                                                <td>{item.quizSize}</td>
                                                <td>{item.passPercentage}</td>
                                                <td>{item.startDate}</td>
                                                <td>{item.endDate}</td>
                                                <td>{moment(item.createdDate).format('DD/MM/YYYY')}</td>
                                                <td>{moment(item.updatedDate).format('DD/MM/YYYY')}</td>
                                                <td>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipEdit}
                                                    >
                                                        <Link to={`/dashboard/create-quiz/${item.quizId}`}>
                                                            <i className="fas fa-pen" key={item.quizId} style={{ paddingLeft: "20px", textDecoration: 'none', color: "black" }}> | </i>
                                                        </Link>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipDelete}

                                                    >

                                                        <i className='fas fa-trash' onClick={() => deleteById(item.quizId)} style={{ paddingLeft: "10px" }}></i>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                        </div> */}

                <Modal show={show} onHide={handleClose} fullscreen={fullscreen}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4>Quiz Questions</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {listQuizQuestions.length > 0 ? (
                            listQuizQuestions.map((item, index) => (
                                <div>
                                    <h6 className='fw-bold text-success'>{index + 1}. Question</h6>
                                    <h5 className='d-flex'>
                                        <div className="p-1">
                                            {/* <label> <pre><code> {item.questionTitletext}</code></pre></label> */}
                                            <div dangerouslySetInnerHTML={{ __html: item.questionTitletext }} />
                                        </div>

                                        <div className="pb-2 pl-2">
                                            {item.type === 'single' ? (
                                                <Link to={`/dashboard/quiz/update-single-choice/${item.questionId}`}>
                                                    <button className="btn btn-warning">
                                                        <i className="fas fa-fw fa-pen"></i>
                                                    </button>
                                                </Link>
                                            ) : (
                                                item.type === 'fill' ? (
                                                    <Link to={`/dashboard/quiz/update-fill/${item.questionId}`}>
                                                        <button className="btn btn-warning">
                                                            <i className="fas fa-fw fa-pen"></i>
                                                        </button>
                                                    </Link>
                                                ) : (
                                                    item.type === 'tf' ? (
                                                        <Link to={`/dashboard/quiz/update-true-false/${item.questionId}`}> {/* Adjust the path accordingly */}
                                                            <button className="btn btn-warning">
                                                                <i className="fas fa-fw fa-pen"></i>
                                                            </button>
                                                        </Link>
                                                    ) : (
                                                        // Handle other cases or display nothing if needed
                                                        <></>
                                                    )
                                                )
                                            )}
                                        </div>

                                    </h5>

                                    {item.imageName && item.imageName.substring(item.imageName.lastIndexOf('/') + 1) !== "NA" && (
                                        <div className="col-md-6 mb-2">
                                            <img
                                                src={item.imageName}
                                                alt="Image.png"
                                                className="img-responsive"
                                                width={200}
                                                height={200}
                                            />
                                        </div>
                                    )}
                                    {item.type === "single" ? (
                                        <div>
                                            <div className="form-check unselectable">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={index}
                                                    id="flexRadioDefault1"
                                                    value="a"
                                                    defaultChecked=""

                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    {item.option1}
                                                </label>
                                            </div>
                                            <div className="form-check unselectable">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={index}
                                                    id="flexRadioDefault2"
                                                    value="b"
                                                    defaultChecked=""

                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    {item.option2}
                                                </label>
                                            </div>
                                            {item.option3 && (
                                                <div className="form-check unselectable">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={index}
                                                        value="c"
                                                        id="flexRadioDefault1"
                                                        defaultChecked=""

                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        {item.option3}
                                                    </label>
                                                </div>
                                            )}
                                            {item.option4 && (
                                                <div className="form-check unselectable">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={index}
                                                        value="d"
                                                        id="flexRadioDefault1"
                                                        defaultChecked=""

                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        {item.option4}
                                                    </label>
                                                </div>
                                            )}
                                            {item.option5 && (
                                                <div className="form-check unselectable">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={index}
                                                        value="e"
                                                        id="flexRadioDefault1"
                                                        defaultChecked=""

                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        {item.option5}
                                                    </label>
                                                </div>
                                            )}
                                            {item.option6 && (
                                                <div className="form-check unselectable">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={index}
                                                        value="f"
                                                        id="flexRadioDefault1"
                                                        defaultChecked=""

                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        {item.option6}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    ) : item.type === "fill" ? (
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="answer" className="form-label">
                                                    Enter your Answer
                                                </label>
                                                <input
                                                    type="answer"
                                                    className="form-control"
                                                    id="answer"
                                                    name="answer"
                                                // value={correctInputOption}                                                                                                                                  
                                                />
                                            </div>
                                        </div>
                                    ) : item.type === "tf" ? (
                                        <div>
                                            <div className='col-md-1'>
                                                <div className='row'>

                                                    <div className='col-md-4'>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="true"
                                                                id="flexRadioDefault1"
                                                                value="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">True</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-1 pt-2'>
                                                <div className='row'>

                                                    <div className='col-md-4'>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="false"
                                                                id="flexRadioDefault1"
                                                                value="false"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">False</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    <p className="fw-bold">Correct Answer : {item.correctOption}</p>
                                    <p className="fw-bold">Type : {item.type}</p>
                                </div>
                            ))
                        ) : (
                            <h5 className="fw-bold text-success">No questions available in this quiz.</h5>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        </div>
    )
}

export default TotalQuizzes