import { Breadcrumb, Container } from "react-bootstrap";
import NavBar from "../Navbar/Navbar"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Spinner from '../Spinner/Spinner';
import { useState, useEffect } from 'react';
import img from '../../src/Images/img.png';
import Services from '../../src/Service/Service';
import moment from 'moment';
import quizImage from '../assets/img/values-1.png'
import swal from "sweetalert"
import axios from 'axios';
import quizIcon from '../assets/img/quiz.png'
import ctfIcon from '../assets/img/ctfd.png'
import { useNavigate } from "react-router-dom";
import DashBoardRouter from "../DashboardRouter/Dashboardrouter";
import '../assets/Style.css'
import BeatLoader from "react-spinners/BeatLoader";
import NewForm from "../NewForm/NewForm";
import { Logout } from "../Logout/Logout";

//import Globalfunction from '../config/Globalfunction';

const Dashboard = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [listQuizzes, setlistQuizzes] = useState([])
    const [currentSection, setCurrentSection] = useState(null);
    const [loading, setLoading] = useState(true);
    const switchToQuizSection = () => {
        setCurrentSection('quiz');
        navigate("/dashboard/quiz")

    };

    const switchToCTFSection = () => {
        setCurrentSection('ctf');
        navigate("/dashboard/ctf")
    };
    // const deleteById = (id) => {
    //     swal({
    //         title: "Are you sure?",
    //         text: "Once deleted, you will not be able to recover this Quiz!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 Services.DeleteQuiz(id)
    //                     .then(async response => {
    //                         if (response.data.status == true) {
    //                             await swal("Success", response.data.msg, "success");
    //                             const updatedData = listQuizzes.filter(item => item.quizId !== id);
    //                             setlistQuizzes(updatedData)
    //                         } else {
    //                             await swal("Message!", response.data.msg, "info")
    //                         }
    //                     }).catch(async err => {
    //                         await swal("Message!", err.response.data.msg, "warning")
    //                     })
    //             } else {
    //                 swal("Your Quiz is safe!");
    //             }
    //         })
    // }

    // const fetchQuizData = () => {

    //     Services.GetQuizzes()
    //     .then(response => {
    //         setlistQuizzes(response.data);
    //         console.log(response.data);
    //     }).catch((err) => {
    //         //  swal("Message!", err.response.data, "warning");
    //         // alert("Server Error 500")
    //     })
    // }
    useEffect(() => {

        Services.GetQuizzes()
            .then(response => {
                setTimeout(() => {
                setlistQuizzes(response.data);
                console.log(response.data);
                setIsLoading(false);
            }, 2000);
            }).catch(err => {
                // if (err.response && err.response.status === 401) {
                //     const errorMessage = err.response.data.errors.UnAuthorized;

                //     swal("Error", errorMessage, "error");
                //     Services.Logout()
                //     .then(response => {
                //         if (response.status === 200) {
                //             sessionStorage.removeItem("token");
                //             sessionStorage.removeItem("LoggedIn");
                //             navigate("/");
                //         }
                //     })
                //     .catch(err => {
                //         // Handle error if needed
                //     });
                // }
                // else 
                setIsLoading(false);
                if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    //swal("Message!", errorMessages, "warning");
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            });
    }, [])

    return (
        <div>
            {isLoading ? ( // Conditionally render the spinner while loading
                <div className="spinner-container">
                    <Spinner />
                </div>
            ) : (
                <>
                    <section className="breadcrumbs">
                        <div className="container pt-3">

                            <ol>
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>

                                <li>Dashboard</li>
                            </ol>
                            <h2>Dashboard</h2>

                        </div>
                    </section>
                    <div className="container wrapper">
                        <>
                            <div className="section_our_solution">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="our_solution_category">
                                            <div className="solution_cards_box">
                                                <div className="solution_card" onClick={switchToQuizSection}>
                                                    <div className="hover_color_bubble" />
                                                    <div className="so_top_icon">
                                                        <img src={quizIcon} width={40} height={50} />
                                                    </div>
                                                    <div className="solu_title">
                                                        <h2 className="text-center text-white mt-5">Quiz</h2>
                                                    </div>
                                                    {/* <div className="solu_description">
                                            <p>
                                                It is a long established fact that a reader will be distracted
                                                by the readable content of a page when looking at its layout.
                                            </p>
                                            <button type="button" className="read_more_btn">
                                                Read More
                                            </button>
                                        </div> */}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="our_solution_category">
                                            <div className="solution_cards_box">
                                                <div className="solution_card" onClick={switchToCTFSection}>
                                                    <div className="hover_color_bubble" />
                                                    <div className="so_top_icon">
                                                        <img src={ctfIcon} width={40} height={50} />
                                                    </div>
                                                    <div className="solu_title">
                                                        <h2 className="text-center text-white mt-5">CTF</h2>
                                                    </div>
                                                    {/* <div className="solu_description">
                                            <p>
                                                It is a long established fact that a reader will be distracted
                                                by the readable content of a page when looking at its layout.
                                            </p>
                                            <button type="button" className="read_more_btn">
                                                Read More
                                            </button>
                                        </div> */}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    </div>
                    <Header currentSection={currentSection} />
                    
                </>
            )}
        </div>
    )
}
export default Dashboard;