import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import Services from '../../src/Service/Service'

class UserUtil { 
 
setCookieStorage(key,value){
  //sessionStorage.setItem(key,value);
  Cookies.set(key, value);
}

setCookies(key, value, options) {
    Cookies.set(key, value, options);
  }

getCookieStorage(key){
    //return  sessionStorage.getItem(key);
    return Cookies.get(key);
}

removeCookieStorage(key){
    //return  sessionStorage.removeItem(key);
    Cookies.remove(key);
}


 getUsernameFromToken(val) {
    
    const decodedToken = jwtDecode(val);

    const username = decodedToken ? decodedToken.username : null;

    return username;
  }


   getRolesFromToken(val) {
    // Decode the JWT token
    const decodedToken = jwtDecode(val);

    // Extract the roles from the decoded token
    const roles = decodedToken ? decodedToken.roles : null;

    return roles;
}

logout(){
  // Services.Logout()
  // .then(response => {
  //     if (response.status === 200) {
  //         // sessionStorage.removeItem("token");
  //         // sessionStorage.removeItem("LoggedIn");
  //         //UserUtil.removeCookieStorage("token");
  //         //UserUtil.removeCookieStorage("LoggedIn");
  //         Cookies.remove("token");
  //         Cookies.remove("LoggedIn");
  //         window.location.href = "/";
  //     }
  // })
  // .catch(err => {
  //     // Handle error if needed
  // });



      Services.Logout()
            .then(response => {
                if (response.status === 200) {
                    // sessionStorage.removeItem("token");
                    // sessionStorage.removeItem("LoggedIn");
                    Cookies.remove("token");
                    Cookies.remove("LoggedIn");
                    Cookies.remove("referrer");
                    Cookies.remove("mode");
                    Cookies.remove("flag");
                    Cookies.remove("ivpflag");
                    window.location.href = "/";
                    // navigate("/");
                    //alert("hi");
                    let flag = Cookies.get("ivpflag");
                    let user_id = Cookies.get("xyzabc");
                    let body = { user_id };
                    if (flag) {
                        Services.IVPLogout(body)
                            .then(response => {
                                if (response.status === 200) {
                                    //navigate("/");
                                    Cookies.remove("ivpflag");
                                    Cookies.remove("xyzabc");
                                    window.location.href = "/";
                                    //alert("hi");


                                }
                            }).catch(err => {

                            })
                    }
                }
            }).catch(err => {

            })
}


}
 export default new UserUtil()