import logo from "../assets/img/logo.png"
import cdacLogo from "../assets/img/cdac.png";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
import Services from "../Service/Service";
import { useKeycloak } from "@react-keycloak/web";
import UserService from "../Services/UserService";
// import iseaLogo from "../assets/img/isea.png"
import URLs from "../config/Urls";
import iseaLogo from "../assets/img/flag.png"
import ctfLogo from "../assets/img/ctf.png"
import mietyLogo from "../assets/img/miety.png";
import Avatar from '../assets/img/user-profile.png'
import UserUtil from "../UserUtil/UserUtil";
import CAKE_BASE_URL from "../config/configurl";

const Header = ({ currentSection }) => {
    let navigate = useNavigate();
    // const [roles, setRoles] = useState([]);
    const { keycloak, initialized } = useKeycloak();
    const [selectedItem, setSelectedItem] = useState(null);
    // const LoggedIn = sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getCookieStorage("LoggedIn");
    let username = "";
    let roles = "";

    if (LoggedIn == "true") {
        // const token = sessionStorage.getItem("token");
        const token = UserUtil.getCookieStorage("token");
        username = getUsernameFromToken(token);
        roles = getRolesFromToken(token);

        function getUsernameFromToken(token) {

            const decodedToken = jwtDecode(token);

            const username = decodedToken ? decodedToken.name : null;

            return username;
        }


        function getRolesFromToken(token) {

            const decodedToken = jwtDecode(token);

            const roles = decodedToken ?  decodedToken.resource_access.cakesldap.roles : null;

            return roles;
        }
    }


    // const logoutHandler = (event) => {
    //     keycloak?.logout().then(() => navigate(CAKE_BASE_URL)).catch()
    // };
       

    const logoutHandler = async () => {
        try {
          if (keycloak) {
            console.log('Attempting to logout from Keycloak...');
            await keycloak.logout();
            console.log('Logout successful');
    
            // Ensure cookies are removed
            UserUtil.removeCookieStorage('token');
            UserUtil.removeCookieStorage('LoggedIn');
            console.log('Cookies removed');
    
            // Immediate redirection
            console.log(`Navigating to ${URLs.CAKES}...`);
            window.location.href = URLs.CAKES;
            console.log('Navigation function called');
          } else {
            console.error('Keycloak instance is not available');
          }
        } catch (error) {
          console.error('Logout failed', error);
        }
      };
      

    const handleKeyCloakLogin = () => {
        // keycloak.login();

    }

    // const logoutHandler = async () => {
    //     try {
    //       if (keycloak) {
    //         await keycloak.logout();
    //         // UserUtil.removeCookieStorage(z)
    //         navigate(URLs.CAKES); 
    //       } else {
    //         console.error('Keycloak instance is not available');
    //       }
    //     } catch (error) {
    //       console.error('Logout failed', error);
    //     }
    //   };



    const dashBoardHandler = () => {
        navigate("/admin-dashboard/ctf");
        window.location.reload();
    }

    const dashBoardUserHandler = () => {
        navigate("/user-dashboard/ctf");
        window.location.reload();
      
    }

    useEffect(() => {
        function handleClickOutside(event) {
            // Check if the clicked element matches '.mobile-nav-toggle' selector
            if (event.target.matches('.mobile-nav-toggle')) {
                // Toggle classes on '#navbar' element and 'bi-list'/'bi-x' classes on the clicked element
                const navbar = document.querySelector('#navbar');
                navbar.classList.toggle('navbar-mobile');
                event.target.classList.toggle('bi-list');
                event.target.classList.toggle('bi-x');
            }
        }

        function handleDropdownClick(event) {
            // Check if '#navbar' has 'navbar-mobile' class and the clicked element matches '.navbar .dropdown > a' selector
            const navbar = document.querySelector('#navbar');
            if (navbar.classList.contains('navbar-mobile') && event.target.matches('.navbar .dropdown > a')) {
                event.preventDefault();
                // Toggle 'dropdown-active' class on the next sibling of the clicked element
                event.target.nextElementSibling.classList.toggle('dropdown-active');
            }
        }

        // Add event listeners on component mount
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('click', handleDropdownClick, true);

        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('click', handleDropdownClick, true);
        };
    }, []);

    // useEffect(() => {
    //         if (keycloak.authenticated) {

    //     const token = keycloak.token;

    //    console.log("tok",token);
    //     sessionStorage.setItem("token", token);
    //     sessionStorage.setItem("LoggedIn", "true");
    // }
    // }, []);





    return (
        <div>
            <header id="header" className="header fixed-top header-scrolled">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                <div>
                        <a href="https://www.meity.gov.in/" className="logo d-flex align-items-center" target="_blank" title="MeitY (External website that opens in new window)">
                            <img
                                src={mietyLogo}
                                alt="logo"
                                id="logo"
                                height={60}
                                width={140}
                            />
                        </a>
                    </div>
                    <span className="border-header"></span>
                    <div>
                        <Link to="/" className="logo d-flex align-items-center" title="Capture The Flag">
                            <img
                                src={ctfLogo}
                                alt="logo"
                                id="logo"
                                height={60}
                                width={145}
                            />
                        </Link>
                    </div>
                    <div className="ms-auto">
                    <nav id="navbar" className="navbar">


                        <ul>

                            {roles.includes('instructor') ? (
                                <>
                                    {currentSection === 'quiz' && (
                                        <>
                                            <li className="dropdown">
                                                <a href="#">
                                                    <span>Quiz</span> <i className="bi bi-chevron-down" />
                                                </a>
                                                <ul>
                                                    <li>
                                                        <Link to="/dashboard/quiz/create-quiz">
                                                            <a href="#">Create Quiz</a>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/dashboard/quiz/total-quizzes">
                                                            <a href="#">View Quizzes</a>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="dropdown">
                                                <a href="#">
                                                    <span>Question</span> <i className="bi bi-chevron-down" />
                                                </a>
                                                <ul>
                                                    <li>
                                                        <Link to="/dashboard/quiz/create-questions">
                                                            <a href="#">Create Question</a>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/dashboard/quiz/total-questions">
                                                            <a href="#">View Question</a>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/dashboard/quiz/certificate">
                                                    <a className="nav-link p-0" href="#">Certificate</a>
                                                </Link>
                                            </li>
                                        </>

                                    )}

                                    {currentSection === 'ctf' && (
                                        <>
                                            <li className="dropdown">
                                                <a href="#">
                                                    <span>Challenge</span> <i className="bi bi-chevron-down" />
                                                </a>
                                                <ul>
                                                    <li>
                                                        <Link to="/admin-dashboard/ctf/create-challenge">
                                                            <a href="#">Create Challenge</a>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/admin-dashboard/ctf/view-challenges">
                                                            <a href="#">Challenge List</a>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="dropdown">
                                                <a href="#">
                                                    <span>CTF</span> <i className="bi bi-chevron-down" />
                                                </a>
                                                <ul>
                                                    <li>
                                                        <Link to="/admin-dashboard/ctf/create-ctf-list">

                                                            <a href="#">Create CTF</a>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/admin-dashboard/ctf/view-ctf-list" >
                                                            <a href="#">CTF List</a>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/admin-dashboard/ctf/leaderboard" className="nav-link">
                                                    Leaderboard
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-dashboard/ctf/users" className="nav-link">
                                                   Users
                                                </Link>
                                            </li>
                                        </>

                                    )}

                                    {/* Add the rest of your menu items here */}
                                </>
                            ) : roles.includes('learner') ? (
                                <li>
                                     <li>
                                            <Link to="/user-dashboard/user-scoreboard" className="nav-link">
                                                Scoreboard
                                            </Link>
                                        </li>
                                </li>
                                // <div></div>
                            ) : null}


                            {!LoggedIn ?
                                <>
                                    {/* <li>
                                        <Link to="/signup">
                                            <a className="getstarted m-0" href="#about">
                                                Sign Up
                                            </a>
                                        </Link>
                                    </li> */}
                                    {/* {keycloak.authenticated && (
                                    <li>
                                    ({keycloak.tokenParsed.preferred_username})
                                    </li>
                                     )} */}
                                    <li>
                                        {/* <Link to="/login"> */}
                                        {/* {!keycloak.authenticated && ( */}
                                            {/* <a className="getstarted m-1" href="#" onClick={() => handleKeyCloakLogin()}>
                                                Login
                                            </a> */}
                                        {/* )} */}
                                        {/* </Link> */}
                                    </li>
                                </>
                                :
                                <li className="dropdown">
                                   <a href="#" >
                                        <img
                                                src={Avatar}
                                                alt="logo"
                                                id="logo"
                                                height={40}
                                                width={40}
                                            /><span className="text-capitalize m-1">{username}</span> <i className="bi bi-chevron-down" />
                                        </a>

                                    <ul>
                                        {roles.includes('instructor') ?
                                            <li>
                                                <a href="#" onClick={dashBoardHandler}>Dashboard</a>
                                            </li>
                                            :
                                            <li>
                                                <a href="#" onClick={dashBoardUserHandler}>Dashboard</a>
                                            </li>
                                        }
                                        {!!keycloak.authenticated && (
                                            <li>
                                                <a href={URLs.CAKES} onClick={logoutHandler}>Logout</a>
                                            </li>
                                        )}

                                    </ul>

                                </li>

                            }
                        </ul>
                        <div>
                            <i className="bi bi-list mobile-nav-toggle" />
                        </div>
                        {/* </div> */}
                    </nav>
                </div>
                </div>
            </header >
            {/* End Header */}
        </div >
    )
}

export default Header;