import '../assets/Style.css'
import '../assets/css/style.css'
import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import jwtDecode from 'jwt-decode';
import Badge from 'react-bootstrap/Badge';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import certificate from '../assets/img/certificate.png'
import SideMenu from '../SideMenu/SideMenu';
import AttemptedChallengesByUser from '../AttemptedChallengesByUser/AttemptedChallengesByUser';
import UserCtfStatistics from '../UserCtfStatistics/UserCtfStatistics';
import URLs from '../config/Urls';
import UserUtil from '../UserUtil/UserUtil';

const UserCTFDashboard = () => {
    let navigate = useNavigate();
    const [selectedChallengeList, setSelectedChallengeList] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState('Dashboard');
    const [activeTab, setActiveTab] = useState('Challenge');
    const [flagValue, setFlagValue] = useState('');
    const [getChallengeId, setChallengeId] = useState(null);
    const [challengeLists, setChallengeLists] = useState([]);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [selectedHint, setSelectedHint] = useState(null);
    const [clickedChallenge, setClickedChallenge] = useState(null);
    const [attempts, setAttempts] = useState(0);
    const [breadcrumbText, setBreadcrumbText] = useState('');
    const [headingText, setHeadingText] = useState('Dashboard');
    const [displayChallengeLists, setDisplayChallengeLists] = useState(true);

    const handleClose1 = () => {
        setShow1(false);

    }
    const handleShow1 = () => setShow1(true);
    const handleShow2 = (clickedChallenge) => {
        setShow2(true);
        setChallengeId(clickedChallenge.challengeId);
        setClickedChallenge(clickedChallenge);

    }


    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };






    // const handleChallengeListClick = (challengeList) => {
    //     setSelectedChallengeList(challengeList);
    //      navigate("")
    // };


    const handleBackToChallengeLists = () => {
        setSelectedChallengeList(null);
        setDisplayChallengeLists(true);
        setSelectedMenu("AssignedCTF");
        setBreadcrumbText("");
    };

    // const LoggedIn = sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getCookieStorage("LoggedIn");
    let email = "";
    let username = "";
    if (LoggedIn === "true") {
        // const token = sessionStorage.getItem("token");
        const token = UserUtil.getCookieStorage("token");
        email = getEmailFromToken(token);
        username = getUsernameFromToken(token);

        function getEmailFromToken(token) {

            const decodedToken = jwtDecode(token);

            const email = decodedToken ? decodedToken.sub : null;

            return email;
        }


        function getUsernameFromToken(token) {

            const decodedToken = jwtDecode(token);

            const username = decodedToken ? decodedToken.username : null;

            return username;
        }

    }


    useEffect(() => {
        $(document).ready(function () {
            $(".btn-toggle-menu").click(function () {
                console.log("Button clicked"); // Debugging line
                $("#wrapper").toggleClass("toggled");
            });
        });
    }, []);


    useEffect(() => {


        if (selectedMenu === 'AssignedCTF') {

            Services.GetEmailsListinChallengeList()
                .then(response => {
                    setChallengeLists(response.data);
                    console.log("Dataa", response.data);
                }).catch(err => {

                    if (err.response && err.response.status === 401) {
                        window.location.href = URLs.CAKES;
                        //const errorMessage = err.response.data.errors.UnAuthorized;

                        //swal("Message!", errorMessage, "warning");
                        // Services.Logout()
                        //     .then(response => {
                        //         if (response.status === 200) {
                        //             sessionStorage.removeItem("token");
                        //             sessionStorage.removeItem("LoggedIn");
                        //             navigate("/");
                        //         }
                        //     })
                        //     .catch(err => {
                        //         // Handle error if needed
                        //     });

                        UserUtil.logout();
                    }
                })

        }


    }, [selectedMenu]);

    const challengesByCategory = {};


    if (selectedChallengeList) {

        // Create a set to keep track of displayed challenge names in each category
        const displayedChallengeNames = {};

        selectedChallengeList.challenges.forEach(challenge => {
            const { category, challengeName } = challenge;
            if (!challengesByCategory[category]) {
                challengesByCategory[category] = [];
            }

            // Check if the challenge name has not been displayed in this category
            if (!displayedChallengeNames[category]?.has(challengeName)) {
                challengesByCategory[category].push(challenge);

                // Add the challenge name to the set to mark it as displayed
                if (!displayedChallengeNames[category]) {
                    displayedChallengeNames[category] = new Set();
                }
                displayedChallengeNames[category].add(challengeName);
            }
        });

        // Now you can work with challengesByCategory based on the selectedChallengeList
    }





    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container  mt-3">

                    <ol>
                        <li>
                        <a  href={URLs.CAKES}>
                                CAKES
                            </a>
                        </li>

                        {/* <li aria-current="page">
                            <Link to="/quiz/user-dashboard">
                                Dashboard
                            </Link>
                        </li> */}

                        <li>
                            {/* <Link to="/quiz/ctf-dashboard"> */}
                              User DashBoard
                            {/* </Link> */}
                        </li>



                    </ol>
                    <h2>User Dashboard</h2>

                </div>
            </section>
            <div className='container-fluid'>


                <div id="wrapper" className="wrapper-content">

                <SideMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />

                    <div id="page-content-wrapper">
                        <nav className="navbar navbar-default">
                            <div className="container-fluid">
                                <div className="navbar-header">
                                    <button
                                        className="btn-menu btn-toggle-menu"
                                        type="button"
                                    >
                                        <i className="fa fa-bars" />
                                    </button>
                                </div>
                            </div>
                        </nav>

                        {selectedMenu === 'Dashboard' && <UserCtfStatistics selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>}
                        {selectedMenu === 'AttemptedChallenges' && <AttemptedChallengesByUser selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>}

                    </div>
                </div>

            </div>

        </div>
    )
}

export default UserCTFDashboard