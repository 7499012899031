import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../assets/Style.css'
import axios from "axios";
import Services from '../../src/Service/Service';
import { useState, useEffect } from 'react';
import swal from "sweetalert"
import DataTable from 'react-data-table-component';
import moment from 'moment';
// import * as moment from 'Moment';

const TotalQuestions = () => {
    const [listQuestions, setlistQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [listQuizzes, setlistQuizzes] = useState([]);
    const [selectedQuiz, setSelectedQuiz] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    const renderTooltipEdit = (props) => (
        <Tooltip id="EditOption" {...props}>
            Update the Question
        </Tooltip>
    );

    const renderTooltipDelete = (props) => (
        <Tooltip id="DeleteOption" {...props}>
            Delete the Question
        </Tooltip>
    );



    const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            }
        },
        headCells: {
            style: {
                fontSize: '17px',
                fontWeight: '500',
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false'
            },
        },
        headRow: {
            style: {
                backgroundColor: 'darkgray', // Set the background color of the header row
            },
        },
    };

    const columns = [
        {
            name: 'Sr No',
            selector: (row, index) => index + 1,
            maxWidth: '100px',
            wrap: true,
            compact: true,
            grow: false,
            sortable: true,


        },
        {
            name: 'Question',
            selector: (row) => row.questionTitletext,
            cell: (row) => (
                <div dangerouslySetInnerHTML={{ __html: row.questionTitletext }} />
            ),
            maxWidth: '900px',
            wrap: true,
            compact: true,
            grow: false,
            sortable: true,
            center: true

        },


        {
            name: 'Question Type',
            selector: (row) => row.type,
            center: true,
            cell: (row) => {
                if (row.type === 'fill') {
                    return 'Fill in the blanks';
                } else if (row.type === 'tf') {
                    return "True or False"
                } else {
                    return row.type;
                }
            }
        }

        ,
        {
            name: 'Option 1',
            selector: (row) => row.option1,
            center: true,
            maxWidth: '200px',
            wrap: true,
            compact: true,
            grow: false,


        },

        {
            name: 'Option 2',
            selector: (row) => row.option2,
            center: true,
            maxWidth: '200px',
            wrap: true,
            compact: true,
            grow: false,

        },

        {
            name: 'Created',
            selector: (row) => row.createdDate,
            format: (row) => row.createdDate,
            center: true,
            maxWidth: '200px',
            wrap: true,
            compact: true,
            grow: false,
        },
        {
            name: 'Updated',
            selector: (row) => row.updatedDate,
            format: (row) => row.updatedDate,
            center: true,
            maxWidth: '200px',
            wrap: true,
            compact: true,
            grow: false,
        },
        {
            name: 'Options',
            cell: (row) => (
                <>
                    <div>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipEdit}
                        >
                            {row.type === 'single' ? (
                                <Link to={`/dashboard/quiz/update-single-choice/${row.questionId}`}>
                                    <button className="btn btn-warning me-1">
                                        <i className="fas fa-fw fa-pen text-dark" key={row.questionId} />
                                    </button>
                                </Link>
                            ) : row.type === 'fill' ? (
                                <Link to={`/dashboard/quiz/update-fill/${row.questionId}`}>
                                    <button className="btn btn-warning me-1">
                                        <i className="fas fa-fw fa-pen text-dark" key={row.questionId} />
                                    </button>
                                </Link>
                            ) : row.type === 'tf' ? (
                                <Link to={`/dashboard/quiz/update-true-false/${row.questionId}`}>
                                    <button className="btn btn-warning me-1">
                                        <i className="fas fa-fw fa-pen text-dark" key={row.questionId} />
                                    </button>
                                </Link>
                            ) :
                                null}
                        </OverlayTrigger>
                    </div>
                    {/* <div>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipDelete}
                        >
                            <button className="btn btn-danger me-1">
                                <i className="fas fa-fw fa-trash" onClick={() => deleteById(row.questionId)} />
                            </button>
                        </OverlayTrigger>
                    </div> */}

                </>
            ),
            center: true,
            maxWidth: '100px',
            wrap: true,
            compact: true,
            grow: false,

        },
    ];

    const handleFilter = (e) => {
        const keyword = e.target.value ? e.target.value.toLowerCase() : '';
        let filteredData = [];

        if (listQuizzes.length === 0) {
            filteredData = listQuestions.filter(
                (question) =>
                    question.questionTitletext.toLowerCase().includes(keyword) ||
                    question.type.toLowerCase().includes(keyword)
            );
        } else {
            filteredData = listQuestions.filter((question) => {
                const quizIdsAndNames = question.quizIdsAndNames;

                if (quizIdsAndNames && typeof quizIdsAndNames === 'object') {
                    const values = Object.values(quizIdsAndNames);
                    return (
                        question.questionTitletext.toLowerCase().includes(keyword) ||
                        question.type.toLowerCase().includes(keyword) ||
                        values.some((value) => value.toLowerCase().includes(keyword))
                    );
                }

                return false; // Handle the case when quizIdsAndNames is not an object
            });
        }

        setFilteredQuestions(filteredData);
    };




    const fetchQuestionData = () => {
        Services.GetQuestions()
            .then((response) => {

                setlistQuestions(response.data);
                // console.log(updatedData);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    Services.Logout()
                        .then(response => {
                            if (response.status === 200) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("LoggedIn");
                                navigate("/");
                            }
                        })
                        .catch(err => {
                            // Handle error if needed
                        });
                }
                else {
                    swal("Message!", "Server error occurred", "warning");
                }
            });
    };

    const fetchQuizData = () => {

        Services.GetQuizzes().then(response => {
            setlistQuizzes(response.data);
            console.log(response.data);
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Error", errorMessage, "error");
                Services.Logout()
                    .then(response => {
                        if (response.status === 200) {
                            sessionStorage.removeItem("token");
                            sessionStorage.removeItem("LoggedIn");
                            navigate("/");
                        }
                    })
                    .catch(err => {
                        // Handle error if needed
                    });
            } else {
                swal("Message!", "Server error occurred", "warning");
            }
        })
    }



    let token = sessionStorage.getItem("token");
    const deleteById = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Question!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Services.DeleteQuestions(id)
                        .then(async response => {
                            if (response.data.status == true) {
                                await swal("Success", response.data.msg, "success");
                                const updatedData = listQuestions.filter(item => item.questionId !== id);
                                setlistQuestions(updatedData)
                            } else {
                                await swal("Message!", response.data.msg, "info")
                            }
                        }).catch(async err => {
                            await swal("Message!", "Server Error", "warning")
                        })
                } else {
                    swal("Your file is safe!");
                }
            })
    }

    useEffect(() => {

        fetchQuestionData();
        fetchQuizData();

    }, []);

    useEffect(() => {
        if (selectedQuiz === '') {
            setFilteredQuestions([]);
        } else {
            const filteredData = listQuestions.filter((question) => {
                const quizIdsAndNames = question.quizIdsAndNames;
    
                if (quizIdsAndNames && typeof quizIdsAndNames === 'object') {
                    const quizNamesArray = Object.values(quizIdsAndNames);
                    console.log(quizNamesArray);
    
                    // Check if selectedQuiz exists in quizNamesArray
                    return quizNamesArray.includes(selectedQuiz);
                }
    
                return false; // Handle the case when quizIdsAndNames is not an object
            });
    
            setFilteredQuestions(filteredData);
        }
    }, [selectedQuiz, listQuestions]);
    
    


console.log("selectedQuiz:", selectedQuiz);
console.log("filteredQuestions:", filteredQuestions);

    const shouldShowDataTable = selectedQuiz !== '' && filteredQuestions.length > 0;

    return (
        <div className="Quiz-admin">

            <section class="breadcrumbs">
                <div class="container pt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                DashBoard
                            </Link>
                        </li>

                        <li aria-current="page">Questions List</li>

                    </ol>
                    <h2>Questions List</h2>

                </div>
            </section>
            <div className="container-fluid mt-5">

                <div className="d-flex">
                    <div className="custom-select-quiz">
                        <h3>Quiz Filter</h3>
                        <select
                            className="form-select"
                            data-title="selectQuiz"
                            name="selectQuiz"
                            onChange={(e) => setSelectedQuiz(e.target.value)}
                        >
                            <option value="">Choose Quiz</option>
                            {listQuizzes.map((quiz) => (
                                <option key={quiz.quizId} value={quiz.quizTitle}>
                                    {quiz.quizTitle}
                                </option>
                            ))}
                        </select>
                        {/* {selectedQuiz !== '' && filteredQuestions.length === 0 && (
                                    <div className="alert alert-info alert-dismissible fade show m-2" role="alert">

                                        <p>No questions available for the selected quiz.</p>

                                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                )} */}
                    </div>


                    <div className="ms-auto p-2">
                        {/* <div className="col-md-12 d-flex justify-content-end"> */}
                        <form className="d-flex mt-4" role="search">
                            <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={handleFilter}
                            />

                        </form>
                        {/* </div> */}
                    </div>
                </div>
                {selectedQuiz !== '' && filteredQuestions.length === 0 ? (
                    <div className="alert alert-info alert-dismissible fade show" role="alert">
                        <h5>No questions available for the selected quiz.</h5>
                    </div>
                ) : (
                    <div>
                        <DataTable
                            columns={columns}
                            data={filteredQuestions.length ? filteredQuestions : listQuestions}
                            striped
                            highlightOnHover
                            pointerOnHover
                            responsive
                            pagination
                            paginationPerPage={10}
                            customStyles={customStyles}
                        />
                    </div>
                )}

            </div>
        </div>

    )
}

export default TotalQuestions;