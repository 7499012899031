import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import swal from "sweetalert"
import Services from '../../src/Service/Service';
import ReactPaginate from 'react-paginate';
import Spinner from "../Spinner/Spinner";
import UserUtil from "../UserUtil/UserUtil";
import URLs from "../config/Urls";

const CtfDashboard = () => {
    let navigate = useNavigate();
    const [challengeListData, setChallengeListData] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const challengesPerPage = 6; // Adjust this number based on how many challenges you want to display per page
    const pageCount = Math.ceil(
        challengeListData ? challengeListData.length / challengesPerPage : 0
    );

 

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const displayChallenges = challengeListData
        ? challengeListData
            .slice(pageNumber * challengesPerPage, (pageNumber + 1) * challengesPerPage)
            .map((challenge, index) => (
                <div className="ag-courses_item" key={index}>
                    <Link to={`/admin-dashboard/ctf/update-ctf-list/${challenge.challengeListId}`}>
                        <a href="#" className="ag-courses-item_link" key={index}>
                            <div className="ag-courses-item_bg" />
                            <div className="ag-courses-item_title text-capitalize">
                                {challenge.challengeListName}
                            </div>
                            <div className="ag-courses-item_course my-3 text-center fw-bold">
                                {challenge.courseIdAndName[Object.keys(challenge.courseIdAndName)[0]]} Course
                            </div>
                            <div className="quiz-info d-flex fw-bold text-black text-center my-2 p-2">
                                <div className="ag-courses-item_date-box w-100 mb-0 fs-6 border-end">
                                    <span className="ag-courses-item_start_date ">{challenge.startDate}</span>
                                    <div>
                                        <small className="text-secondary hover-effect">Start Date</small>
                                    </div>
                                </div>
                                <div className="ag-courses-item_date-box w-100 mb-0 fs-6">
                                    <span className="ag-courses-item_end_date">{challenge.endDate}</span>
                                    <div>
                                        <small className="text-secondary hover-effect">End Date</small>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </Link>
                </div>
            ))
        :""
      



    useEffect(() => {
   
        Services.GetChallengeList().then(response => {
            console.log("in 200",response.status)
            setTimeout(() => {
                
            setChallengeListData(response.data);
            console.log("data", response.data);
                setIsLoading(false);
            }, 2000);
        }).catch(err => {
            setIsLoading(false);
           
            // console.log("in catch",err.status);
            console.log("in catch2",err.response)
            if (err.response.status === 401) {
                console.log("inside 401")
                window.location.href = URLs.CAKES;
                // const errorMessage = err.response.data.errors.UnAuthorized;
   
                // swal("Message!", errorMessage, "warning");
                // Services.Logout()
                //     .then(response => {
                //         if (response.status === 200) {
                //             UserUtil.removeSessionStorage("token");
                //             UserUtil.removeSessionStorage("LoggedIn");
                //             navigate("/");
                //         }
                //     })
                //     .catch(err => {
                //         // Handle error if needed
                //     });
                UserUtil.logout();
            }
            else {
               
                swal("Message!", "Server error occurred", "warning");
                // window.location.reload();
            }
        });

    }, [])
    return (
        <div>
 {isLoading ? ( // Conditionally render the spinner while loading
                <div className="spinner-container">
                    <Spinner />
                </div>
            ) : (
                <>
            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>
                        <li>
                        <a  href={URLs.CAKES}>
                                CAKES
                            </a>
                        </li>

                        <li>
                            {/* <Link to="/admin-dashboard"> */}
                            Admin  Dashboard
                            {/* </Link> */}
                        </li>
                        {/* <li>
                            CTF
                        </li> */}
                    </ol>
                    <h2>Admin Dashboard</h2>

                </div>
            </section>
            <div className="wrapper">
                <div className="ag-format-container">
                    <div className="ag-courses_box pricing">{displayChallenges}</div>
                </div>

                {challengeListData && challengeListData.length > 0 ? ( // Check if data exists
                    <ReactPaginate
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={'pagination justify-content-center'}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        previousLinkClassName={'btn  btn-sm btn-outline-primary m-2'}
                        nextLinkClassName={'btn btn-sm btn-outline-primary m-2'}
                        disabledClassName={'page-item disabled'}
                        activeClassName={'btn btn-sm btn-primary m-2 active'}
                        breakLabel={'...'}
                        pageClassName={'btn btn-sm btn-outline-primary m-2'}
                        onPageActive={(index) => {
                            // Provide custom class for active/inactive page numbers
                            return index === pageNumber ? 'btn btn-sm btn-primary active' : 'btn btn-sm btn-outline-primary m-2';
                        }}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                    />
                ) :(

                    <div className="col-md-12 container alert  alert-info mt-4 text-center">
                        <h6 className='fw-bold'>No CTF List available.</h6>
                    </div>
               ) }
            </div>

            </>
            )}

        </div>
    )
}

export default CtfDashboard;