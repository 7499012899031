// import { BiTwitter } from 'bootstrap-icons-react';

const Footer = () => {
    return (
        <div>
            {/* ======= Footer ======= */}
            <footer id="footer" className="footer">
                <div className="footer-top">  
                <svg class="footer-new-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
    <path class="footer-new-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
  </svg>

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 col-md-12 footer-info py-5">
                                <p className="logo d-flex align-items-center">
                                    <h4>Capture The Flag</h4>
                                </p>
                                {/* <p>
                                        Cras fermentum odio eu feugiat lide par naso tierra. Justo eget
                                        nada terra videa magna derita valies darta donna mare fermentum
                                        iaculis eu non diam phasellus.
                                    </p> */}
                                <div className="social-links mt-5">
                                    <a href="#" className="twitter">
                                    <i className="bi bi-twitter"></i>
                                    </a>
                                    <a href="#" className="facebook">
                                        <i className="bi bi-facebook" />
                                    </a>
                                    <a href="#" className="instagram">
                                        <i className="bi bi-instagram" />
                                    </a>
                                    <a href="#" className="linkedin">
                                        <i className="bi bi-linkedin" />
                                    </a>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className="bi bi-chevron-right" /> <a href="/">Home</a>
                                    </li> */}
                                    {/* <li>
                                        <i className="bi bi-chevron-right" /> <a href="#">About us</a>
                                    </li> */}
                                    {/* <li>
                                        <i className="bi bi-chevron-right" /> <a href="#">Services</a>
                                    </li> */}
                                    {/* <li>
                                        <i className="bi bi-chevron-right" />{" "}
                                        <a href="#">Terms of service</a>
                                    </li> */}
                                    {/* <li>
                                        <i className="bi bi-chevron-right" />{" "}
                                        <a href="#">Privacy policy</a>
                                    </li> */}
                                {/* </ul>
                            </div> */}
                            {/* <div className="col-lg-2 col-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li>
                                        <i className="bi bi-chevron-right" /> <a href="#">Web Design</a>
                                    </li>
                                    <li>
                                        <i className="bi bi-chevron-right" />{" "}
                                        <a href="#">Web Development</a>
                                    </li>
                                    <li>
                                        <i className="bi bi-chevron-right" />{" "}
                                        <a href="#">Product Management</a>
                                    </li>
                                    <li>
                                        <i className="bi bi-chevron-right" /> <a href="#">Marketing</a>
                                    </li>
                                    <li>
                                        <i className="bi bi-chevron-right" />{" "}
                                        <a href="#">Graphic Design</a>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="col-lg-6 col-md-12 footer-contact text-center text-md-start py-5">
                                <h4>Contact Us</h4>
                                <p className="text-white"><strong>Centre for Development of Advanced Computing</strong>
                                   <br/>
                                    Plot No. 6 & 7,Sy No. 1/1,<br />
                                    Srisailam Highway Pahadi Shareef,
                                    Via Keshavagiri Post,                                   
                                    <br />
                                    Hyderabad, Telangana <br />
                                   
                                    <strong> Pincode:</strong> 501510
                                    <br />  
                                    <strong>Phone: </strong> +91-9100034446/7/8                                 
                                </p>                           
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        © Copyright{" "}
                        <strong>
                            <span>C-DAC</span>
                        </strong>
                        . All Rights Reserved
                    </div>

                </div>
            </footer>
            {/* End Footer */}
        </div>
    )
}
export default Footer;