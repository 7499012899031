import { Link, useNavigate} from 'react-router-dom';
import React, { useState, useEffect, useRef} from 'react';
import Services from '../Service/Service';
import swal from "sweetalert"

const Certificate = () => {
    let navigate = useNavigate();
    const [errorJasperMessage, setErrorJasperMessage] = useState(false);
    const [errorJrxmlMessage, setErrorJrxmlMessage] = useState(false);
    const [errorImageMessage, setErrorImageMessage] = useState(false);
    // function checkJasperContentType(file) {
    //     const allowedExtensions = ["jasper"];
    //     const fileName = file.name;
    //     const fileExtension = fileName.split('.').pop().toLowerCase();

    //     if (allowedExtensions.includes(fileExtension)) {
    //       console.log("File is a valid Jasper file.");
    //       // Proceed with further processing
    //     } else {
    //       console.log("File is not a valid Jasper file.");
    //       // Handle error or provide feedback to the user
    //     }
    //   }


    // function checkJasperContentType(file) {
    //     const reader = new FileReader();

    //     reader.onloadend = function() {
    //         const byteArray = new Uint8Array(reader.result);

    //         // Jasper files typically start with the bytes 50 41 43 4B (ASCII for PACK)
    //         const jasperSignature = [80, 65, 67, 75]; // ASCII bytes for "PACK"

    //         for (let i = 0; i < jasperSignature.length; i++) {
    //             if (byteArray[i] !== jasperSignature[i]) {
    //                 setErrorJasperMessage("Invalid content type. Not a Jasper file.");
    //                 return;
    //             }
    //         }

    //         setErrorJasperMessage(false);
    //     };

    //     // Read the first few bytes of the file to check its content type
    //     reader.readAsArrayBuffer(file.slice(0, 4));
    // }



    // async function checkJasperContentType(file) {
    //     const jasperSignature = [80, 65, 67, 75]; // ASCII bytes for "PACK"

    //     const blobSlice = file.slice(0, 4);
    //     const blob = new Blob([blobSlice]);
    //     const url = URL.createObjectURL(blob);

    //     try {
    //         const response = await fetch(url);
    //         const arrayBuffer = await response.arrayBuffer();
    //         const byteArray = new Uint8Array(arrayBuffer);

    //         for (let i = 0; i < jasperSignature.length; i++) {
    //             if (byteArray[i] !== jasperSignature[i]) {
    //                 setErrorJasperMessage("Invalid content type. Not a Jasper file.");
    //                 return;
    //             }
    //         }

    //         setErrorJasperMessage(false);
    //     } catch (error) {
    //         setErrorJasperMessage("Error fetching and verifying file content type.");
    //     } finally {
    //         URL.revokeObjectURL(url);
    //     }
    // }




    // function checkJasperContentType(file) {
    //     const reader = new FileReader();

    //     reader.onload = function(event) {
    //       const fileContent = new Uint8Array(event.target.result);
    //       const magicNumber = [0x50, 0x4B, 0x03, 0x04]; // Magic number for a Jasper file

    //       for (let i = 0; i < magicNumber.length; i++) {
    //         if (fileContent[i] !== magicNumber[i]) {
    //           console.log("File is not a valid Jasper file.");
    //           return;
    //         }
    //       }

    //       console.log("File is a valid Jasper file.");
    //       // Proceed with further processing
    //     };

    //     reader.readAsArrayBuffer(file);
    //   }






    function checkJRXMLContentType(file) {
        const reader = new FileReader();

        reader.onloadend = function () {
            const byteArray = new Uint8Array(reader.result);

            // JRXML files typically start with "<?xml"
            const jrxmlSignature = [60, 63, 120, 109, 108]; // UTF-8 bytes for "<?xml"

            for (let i = 0; i < jrxmlSignature.length; i++) {
                if (byteArray[i] !== jrxmlSignature[i]) {
                    setErrorJrxmlMessage("Invalid content type. Not a JRXML file.");
                    return;
                }
            }

            setErrorJrxmlMessage(false);
        };

        // Read the first few bytes of the file to check its content type
        reader.readAsArrayBuffer(file.slice(0, 5));
    }

    function checkImageContentType(file) {
        const reader = new FileReader();

        const jpegSignature = [255, 216]; // JPEG magic number
        const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10]; // PNG magic number
        const gifSignature = [71, 73, 70, 56, 55, 97]; // GIF magic number

        reader.onloadend = function () {
            const byteArray = new Uint8Array(reader.result);

            const imageSignatures = [jpegSignature, pngSignature, gifSignature];

            let validImage = false;

            for (const signature of imageSignatures) {
                validImage = true;
                for (let i = 0; i < signature.length; i++) {
                    if (byteArray[i] !== signature[i]) {
                        validImage = false;
                        break;
                    }
                }
                if (validImage) {
                    setErrorImageMessage(false);
                    return;
                }
            }

            setErrorImageMessage("Invalid content type. Not a valid image file.");
        };

        // Read the first few bytes of the file to check its content type
        reader.readAsArrayBuffer(file.slice(0, Math.max(jpegSignature.length, pngSignature.length, gifSignature.length)));
    }





    const handleBlur = (event) => {
        switch (event.target.name) {

            case "jasperFile":
                const fileInput = event.target;
                if (!fileInput.value) {
                    setErrorJasperMessage("Please choose a file");
                } else {
                    const file = fileInput.files[0];
                    const fileName = file.name;
                    const fileExtension = fileName.split(".").pop().toLowerCase();
                    const allowedExtension = 'jasper';
                    const maxSizeKB = 500;

                    if (fileName.includes(".") && fileExtension !== allowedExtension) {
                        setErrorJasperMessage("Invalid file type. Please choose a Jasper file.");
                        return;
                    }

                    if (file.size > maxSizeKB * 1024 ) {
                        setErrorJasperMessage("File size should not exceed 500 Kb");
                        return;
                    }

                    // File is valid, clear error message
                    setErrorJasperMessage(false);
                }
                break;


            case "jrxmlFile":
                if (!event.target.value) {
                    setErrorJrxmlMessage("Please choose a file");
                } else {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileExtension = fileName.split(".").pop().toLowerCase();
                    const allowedExtension = 'jrxml';
                    const maxSizeKB = 500;

                    if (fileName.includes(".")) {
                        const extensions = fileName.split(".");
                        if (extensions.length > 2) {
                            setErrorJrxmlMessage("File should not have a double extension");
                            return;
                        }
                        if (fileExtension !== allowedExtension) {
                            setErrorJrxmlMessage("Invalid file type. Please choose JRXML file.");
                            return;
                        }

                        if (file.size > maxSizeKB * 1024 ) {
                            setErrorJrxmlMessage("File size should not exceed 500 Kb");
                        } else {
                            // Check content type
                            checkJRXMLContentType(file);
                        }
                    } else {
                        setErrorJrxmlMessage(false);
                    }
                }
                break;


            case "imageFile":
                if (!event.target.value) {
                    setErrorImageMessage("Please choose a file");
                } else {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileExtension = fileName.split(".").pop().toLowerCase();
                    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Add more allowed extensions if needed                   
                    const maxSizeKB = 500;
                    if (fileName.includes(".")) {
                        const extensions = fileName.split(".");
                        if (extensions.length > 2) {
                            setErrorImageMessage("File should not have a double extension");
                            return;
                        }
                        if (!allowedExtensions.includes(fileExtension)) {
                            setErrorImageMessage("Invalid file type. Please choose a valid image file.");
                            return;
                        }

                        if (file.size > maxSizeKB * 1024 ) {
                            setErrorImageMessage("File size should not exceed 500 Kb");
                        } else {
                            checkImageContentType(file);
                        }
                    } else {
                        setErrorImageMessage(false);
                    }
                }
                break;
        }

    }


    const validateJasperFile = (file) => {
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const allowedExtension = 'jasper';
        const allowedMimeType = 'application/octet-stream'; // Adjust the mime type if necessary
        const maxSizeKB = 500; 

        if (fileName.includes(".")) {
            const extensions = fileName.split(".");
            if (extensions.length > 2) {
                setErrorJasperMessage("File should not have a double extension");
                return false;
            }
            if (fileExtension !== allowedExtension) {
                setErrorJasperMessage("Invalid file type. Please choose a Jasper file.");
                return false;
            }

            // if (!allowedMimeType.includes(file.type)) { // Check if the MIME type is in the array
            //     setErrorJrxmlMessage("Invalid file type. Please choose a JRXML file.");
            //     return;
            // }

            if (file.size > maxSizeKB * 1024) {
                setErrorJasperMessage("File size should not exceed 500 Kb");
                return false;
            }
        } else {
            setErrorJasperMessage(false);
        }

        return true;
    };

    const validateJrxmlFile = (file) => {
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const allowedExtension = 'jrxml';
        const allowedMimeType = 'application/jrxml'; // Adjust the mime type if necessary
        const maxSizeKB = 500;

        if (fileName.includes(".")) {
            const extensions = fileName.split(".");
            if (extensions.length > 2) {
                return "File should not have a double extension";
            }
            if (fileExtension !== allowedExtension) {
                return "Invalid file type. Please choose a JRXML file.";
            }

            // if (file.type !== allowedMimeType) {
            //     return "Invalid file type. Please choose a JRXML file.";
            // }

            if (file.size > maxSizeKB * 1024) {
                return "File size should not exceed 500 Kb";
            }
        }

        return true; // No errors
    };



    function validateImageFile(file) {
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Add more allowed extensions if needed
        const maxSizeKB = 500;

        if (fileName.includes('.')) {
            const extensions = fileName.split('.');
            if (extensions.length > 2) {
                return "Image file should not have a double extension";
            }
            if (!allowedExtensions.includes(fileExtension)) {
                return "Invalid image file type. Please choose a valid image file.";
            }

            if (file.size > maxSizeKB * 1024 ) {
                return "Image file size should not exceed 500 Kb";
            }
        }

        return true; // Validation passed
    }



    const handleSubmit = (event) => {
        event.preventDefault();

        const jasperFileInput = event.target.querySelector("[name='jasperFile']");
        const jrxmlFileInput = event.target.querySelector("[name='jrxmlFile']");
        const imageFileInput = event.target.querySelector("[name='imageFile']");

        let isJasperValid = true;
        let isJrxmlValid = true;
        let isImageValid = true;

        if (!jasperFileInput || !jasperFileInput.files[0]) {
            setErrorJasperMessage("Please choose a Jasper file");
            isJasperValid = false;
        } else {
            const jasperValidationResult = validateJasperFile(jasperFileInput.files[0]);
            if (jasperValidationResult !== true) {
                setErrorJasperMessage(jasperValidationResult);
                isJasperValid = false;
            }
        }

        if (!jrxmlFileInput || !jrxmlFileInput.files[0]) {
            setErrorJrxmlMessage("Please choose a JRXML file");
            isJrxmlValid = false;

        } else {
            const jrxmlValidationResult = validateJrxmlFile(jrxmlFileInput.files[0]);

            if (jrxmlValidationResult !== true) {
                setErrorJrxmlMessage(jrxmlValidationResult);
                isJrxmlValid = false;
            }
        }

        if (!imageFileInput || !imageFileInput.files[0]) {
            setErrorImageMessage("Please choose an image file");
            isImageValid = false;
        } else {
            const imageValidationResult = validateImageFile(imageFileInput.files[0]);
            if (imageValidationResult !== true) {
                setErrorImageMessage(imageValidationResult);
                isImageValid = false;
            }
        }


        if (isJasperValid && isJrxmlValid && isImageValid) {

            const formData = new FormData();

            const filesArray = [
                jasperFileInput.files[0],
                jrxmlFileInput.files[0],
                imageFileInput.files[0]
            ];

            // formData.append("files",filesArray );
            // console.log("FormData:", formData); // Check FormData content
            // console.log("Files array:", filesArray); // Check filesArray content
            for (let i = 0; i < filesArray.length; i++) {
                formData.append("files", filesArray[i]);
            }

            Services.UploadCertificate(formData)

                .then(async response => {

                    if (response.status === 200) {
                        await swal("Success", response.data.msg, "success");
                        // Reset file input fields
                        jasperFileInput.value = "";
                        jrxmlFileInput.value = "";
                        imageFileInput.value = "";

                    } else {
                        await swal("Message", response.data.msg, "info");
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        Services.Logout()
                            .then(response => {
                                if (response.status === 200) {
                                    sessionStorage.removeItem("token");
                                    sessionStorage.removeItem("LoggedIn");
                                    navigate("/");
                                }
                            })
                            .catch(err => {
                                // Handle error if needed
                            });
                    }
                    else if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        swal("Message!", errorMessages, "warning");
                    } else if (err.response && err.response.data && err.response.data.msg) {
                        swal("Message!", err.response.data.msg, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                });


        }


    };


    return (
         <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                DashBoard
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/quiz">
                                Quiz
                            </Link>
                        </li>

                        <li aria-current="page">Certificate</li>

                    </ol>
                    <h2>Certificate</h2>
                </div>
            </section>
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="alert alert-primary mt-3" role="alert">
                        <h5>Instructions</h5>
                        <ul>
                            <li>Only .jasper,.jrxml,.png,.jpg,.jpeg file formats are accepted</li>
                            <li>Allowed file size is upto 500 Kb</li>
                            {/* <li>Minimum two options are compulsory.Questions with no two options will be skipped</li>
                            <li>If score value is empty then the default score value will be taken as 1</li>
                            <li>If level value is empty then the default level value will be taken as easy</li>
                            <li>If type value is empty then the default type value will be taken as single</li>
                            <li>Images cannot be added through bulk upload, if images need to be included in a question, they must be added manually.</li> */}

                        </ul>
                    </div>
                </div>
                <div className="card">
                    <form onSubmit={handleSubmit}>
                        <div className="card-leftside-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <h3 className="required">Jasper File</h3>
                                        <input
                                            className={errorJasperMessage ? "form-control is-invalid" : "form-control"}
                                            type="file"
                                            name="jasperFile"
                                            onBlur={handleBlur}
                                        />
                                        <div className="form-text">Allowed file type is .jasper with size limit upto 500 Kb</div>
                                        <div className="invalid-feedback">
                                            {errorJasperMessage && <span className='text-danger'>{errorJasperMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <h3 className="required">Jrxml File</h3>
                                        <input
                                            className={errorJrxmlMessage ? "form-control is-invalid" : "form-control"}
                                            type="file"
                                            name="jrxmlFile"
                                            onBlur={handleBlur}
                                        />
                                         <div className="form-text">Allowed file type is .jrxml with size limit upto 500 Kb</div>
                                        <div className="invalid-feedback">
                                            {errorJrxmlMessage && <span className='text-danger'>{errorJrxmlMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <h3 className="required">Background Image</h3>
                                        <input
                                            className={errorImageMessage ? "form-control is-invalid" : "form-control"}
                                            type="file"
                                            name="imageFile"
                                            onBlur={handleBlur}
                                        />
                                         <div className="form-text">Allowed file type is .png,.jpg,.jpeg with size limit upto 500 Kb</div>
                                        <div className="invalid-feedback">
                                            {errorImageMessage && <span className='text-danger'>{errorImageMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-success" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Certificate;