import Keycloak from "keycloak-js";


const keycloakConfig = new Keycloak({
    //url:"http://megh1.hyderabad.cdac.in",
    url:"https://cakes.cdac.in",
    realm:"cakes",
    clientId:"cakesldap"
});



// {
//     "realm": "cakes",
//     "auth-server-url": "https://cakes.cdac.in",
//     "ssl-required": "external",
//     "resource": "cakesldap",
//     "public-client": true,
//     "confidential-port": 0
//   }
  
 

export default keycloakConfig;
