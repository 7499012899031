import { Link, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Services from '../../src/Service/Service';
import { useState, useEffect } from 'react';
import swal from "sweetalert"
import UserUtil from '../UserUtil/UserUtil';
import URLs from '../config/Urls';

const AttemptedChallengesByUser = () => {
    const [getChallengeResponse, setChallengeResponse] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    let navigate = useNavigate();
    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',
               
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius:'5px'
            },
        },
    };
    const columnsChallenge = [
        {
            name: 'Sr No',
            selector: (row, index) => index + 1,
            center: true,
            maxWidth: '70px',
            wrap: true,

        },
        {
            name: 'Challenge Name',
            selector: (row) => row.selectedChallenge,
            cell: row => <span className="text-capitalize">{row.selectedChallenge}</span>,
            center: true,
        },
        {
            name: 'CTF Name',
            selector: (row) => row.challengeListName,
            cell: row => <span className="text-capitalize">{row.challengeListName}</span>,
            center: true,
        },

        {
            name: 'Flag',
            selector: (row) => row.flag,
            center: true,
        },
        {
            name: 'Score',
            selector: (row) => row.value,
            center: true,
        },
        {
            name: 'Secured Score',
            selector: (row) => row.scoredValue,
            center: true,
          
        },
        {
            name: 'Timestamp',
            selector: (row) => row.createdDate,
            maxWidth: '1200px',
            wrap: true,
            compact: true,
            grow: false,
        }

    ]


    useEffect(() => {
        Services.GetChallengeResponsebyEmail()
            .then(response => {
                // console.log("sadas", response.data);
                setChallengeResponse(response.data);
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    window.location.href = URLs.CAKES;
                    //const errorMessage = err.response.data.errors.UnAuthorized;

                    //swal("Message!", errorMessage, "warning");
                    // Services.Logout()
                    //     .then(response => {
                    //         if (response.status === 200) {
                    //             // sessionStorage.removeItem("token");
                    //             // sessionStorage.removeItem("LoggedIn");
                    //             UserUtil.removeSessionStorage("token");
                    //             UserUtil.removeSessionStorage("LoggedIn");
                    //             navigate("/");
                    //         }
                    //     })
                    //     .catch(err => {
                    //         // Handle error if needed
                    //     });
                    UserUtil.logout();
                }
            })
    }, []);

    const handleFilter = (e) => {
        const keyword = e.target.value.toLowerCase();
        const filtered = getChallengeResponse.filter((item) =>
            item.selectedChallenge.toLowerCase().includes(keyword)     
        );
        setFilteredData(filtered);
    };

    return (
        <div className="Quiz-admin">
            {/* <section className="breadcrumbs">
                <div className="container">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li aria-current="page">
                            <Link to="/quiz/user-dashboard">
                                Dashboard
                            </Link>
                        </li>

                        <li>
                            <Link to="/quiz/ctf-dashboard">
                                CTF
                            </Link>
                        </li>
                        <li>
                            Attempted Challenges
                        </li>


                    </ol>
                    <h2>Attempted Challenges</h2>
                </div>
            </section> */}
         
            <div className='container mt-3'>
            <header className="section-header p-0">
                    <p className='fs-4'>Attempted Challenges</p>
                </header>
            <div className="col-md-12 d-flex justify-content-end">
                <form className="d-flex m-2" role="search">
                    <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={handleFilter}
                    />
                </form>
            </div>
                <DataTable
                    columns={columnsChallenge}
                    data={filteredData.length > 0 ? filteredData : getChallengeResponse}
                    highlightOnHover
                    pointerOnHover
                    responsive
                    pagination
                    paginationPerPage={10}
                    customStyles={customStyles}
                />
            </div>
        </div>
    )
}
export default AttemptedChallengesByUser;