import axios from "axios";
import keycloakConfig from "../KeyCloak/Keycloak";

const axiosInstance = axios.create();

const refreshTokenInterceptor = async (config) => {
  const keycloak = keycloakConfig(); // Get your Keycloak instance here

  if (keycloak && keycloak.token) {
    await keycloak.updateToken(60); // Refresh token before 60 seconds expiry
    config.headers.Authorization = `Bearer ${keycloak.token}`;
  }
  return config;
};

axiosInstance.interceptors.request.use(refreshTokenInterceptor, (error) => Promise.reject(error));

export default axiosInstance;
