import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import Services from '../Service/Service';
import Spinner from "../Spinner/Spinner";
import jwtDecode from 'jwt-decode';
import UserService from '../Services/UserService';
import { useKeycloak } from "@react-keycloak/web";
import UserUtil from '../UserUtil/UserUtil';

const Cakes = () => {
    const { courseId } = useParams();
    // const [userId, setUserId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    console.log("courseId", courseId);
    const { keycloak, initialized } = useKeycloak();
    let navigate = useNavigate();

    // const LoggedIn = sessionStorage.getItem("LoggedIn");
   const LoggedIn = UserUtil.getCookieStorage("LoggedIn")

   

   
   useEffect(() => {
    if (initialized) {
        if (keycloak.authenticated) {
            const token = keycloak.token;
            console.log("token", token);

            UserUtil.setCookieStorage("token", token);
            UserUtil.setCookieStorage("LoggedIn", "true");

            fetchUserRole();
        } else {
            const token = UserUtil.getCookieStorage("token");
            if (token) {
                keycloak.token = token;
                keycloak.authenticated = true;
                fetchUserRole();
            } else {
                navigate('/login'); // Redirect to login if not authenticated
            }
        }
    }
}, [initialized, keycloak.authenticated]);


    const fetchUserRole = () => {
        if (keycloak.authenticated) {
            const token = keycloak.token;
            const userId = keycloak.tokenParsed.sub; // Retrieve 'sub' claim from tokenParsed
            
            // Assuming courseId is defined somewhere
            
            Services.GetRoleOfCakesUser(userId, courseId)
                .then(response => {
                    setTimeout(() => {
                        console.log("response", response.data);
    
                        if (response.data === 2) {
                            navigate("/admin-dashboard/ctf");
                            window.location.reload();
                        } else if (response.data === 1) {
                            navigate("/user-dashboard/ctf");
                            window.location.reload();
                        }
                        setIsLoading(false);
                    }, 2000);
                })
                .catch(err => {
                    // window.location.reload();
                    setIsLoading(false); // Set loading state to false in case of error
                });
        }
    };
    


    return (
        <div>
            {isLoading && // Conditionally render the spinner while loading
                <div className="spinner-container">
                    <Spinner />
                </div>
            }
        </div>
    )
}

export default Cakes