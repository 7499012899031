import { Routes, Route, Navigate } from "react-router-dom"
import Cakes from "../Cakes/Cakes"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

const CakesRouter = () => {
    return (
        <div>
            {/* <Header /> */}
            <Routes >
                <Route path="/:courseId" element={<Cakes />} />
            </Routes>
            {/* <Footer /> */}
        </div>
    )
}

export default CakesRouter