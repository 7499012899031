import radio from "../../src/Images/radio.png";
import checkbox from "../../src/Images/checkbox.png"
import trueorfalse from "../../src/Images/trueorfalse.png";
import fillintheblanks from "../../src/Images/fillintheblanks.png";
import matchthefollowing from "../../src/Images/matchthefollowing.png"
import bulkUpload from "../../src/Images/bulkUpload.png"
import { useState } from "react";
import { Link } from "react-router-dom";
import DownloadLink from "react-download-link";
import '../assets/Style.css'



const CreateQuestions = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    return (
        <div>
            <section className="breadcrumbs">
                <div className="container pt-3">

                    <ol>

                        <li >
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard">
                                Dashboard
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/quiz">
                                Quiz
                            </Link>
                        </li>
                        <li aria-current="page">Create Question</li>

                    </ol>
                    <h2>Create Questions</h2>

                </div>
            </section>
            <div className="container my-5">

                <div className="col-md-12">



                    {/* <div className="d-flex flex-row justify-content-end">
                        <button className="btn btn-info mb-3" onCklic={handleShow}>
                            <i className="fas fa-upload" /> | Import from Excel
                        </button>
                    </div> */}
                    {/* <div className="card">
                        <div className="card-main-body"> */}
                    <h4 className="text-center fw-bold mt-4">Pick up a Question Type</h4>
                    <div className="row mt-5">
                        <div className="col-md-3">
                            <Link to="/dashboard/quiz/single-choice">
                                <div className="cards-createquestion">
                                    <div className="card-body">
                                        <img src={radio} className="radioImg" />
                                        <h6 className="text-center text-dark fw-bold">Single Choice</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="cards-createquestion">
                                <div className="card-body">
                                    <img src={checkbox} className="radioImg" />
                                    <h6 className="text-center text-dark  fw-bold">Multiple Choice</h6>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                        <Link to="/dashboard/quiz/true-false">
                            <div className="cards-createquestion" >
                                <div className="card-body">
                                    <img src={trueorfalse} className="radioImg" />
                                    <h6 className="text-center text-dark  fw-bold">True Or False</h6>
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div className="col-md-3">
                            <Link to="/dashboard/quiz/fill">
                                <div className="cards-createquestion">
                                    <div className="card-body">
                                        <img src={fillintheblanks} className="radioImg" />
                                        <h6 className="text-center text-dark  fw-bold">Fill In the Blanks</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="cards-createquestion" >
                                <div className="card-body">
                                    <img src={matchthefollowing} className="radioImg" />
                                    <h6 className="text-center text-dark  fw-bold">Match The Following</h6>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <Link to="/dashboard/quiz/bulk-upload">
                                <div className="cards-createquestion">
                                    <div className="card-body">
                                        <img src={bulkUpload} className="radioImg" />
                                        <h6 className="text-center text-dark  fw-bold">Bulk Upload</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        //    </div>
        // </div> 

    )
}
export default CreateQuestions;