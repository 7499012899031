import Quizz from '../../src/Images/Quizz.jpg'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik, ErrorMessage } from 'formik';
import ClientCaptcha from 'react-client-captcha';
import swal from "sweetalert"
import { useState, useEffect } from 'react';
import Services from '../../src/Service/Service'

const ResetPassword = () => {
    const [imageSrc, setImageSrc] = useState('');
    const [token, setcaptchaToken] = useState('');
    const [hash, setcaptchahash] = useState('');
    const [randomString, setrandomString] = useState('');
    const [randValue, setRandValue] = useState('');
    const LoggedIn = sessionStorage.getItem("LoggedIn");


    let navigate = useNavigate();

    // const location = useLocation()
    // const queryParameters = new URLSearchParams(location.search)
    // console.log("sdascasc",queryParameters.get("rand"));

    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const rand = searchParams.get('rand');




    const validate = (values) => {
        const errors = {}

        if (!values.password) {
            errors.password = 'Please Enter Password'
        } else if (!/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,20}$/.test(values.password)) {
            errors.password = 'Password must contain atleast 8 charachters,1 Uppercase,1 lowercase,1 digit,1 special symbol and no white spaces'
        }

        if (!values.confirmpassword) {
            errors.confirmpassword = 'Please ReEnter Password'
        } else if (values.password != values.confirmpassword) {
            errors.confirmpassword = 'Password does not matches'
        }


        if (!values.captcha) {
            errors.captcha = 'Please Enter Captcha'
        }
        return errors
    }


    // console.log("Random",rand);

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmpassword: '',
            captcha: ''
        },
        validate,
        onSubmit: async (values, { setFieldValue }) => {
            const captchadata = {
                captchaImage: values.captcha,
                captchaToken: token,
                captchaHash: hash
            };

            // try {
            //     const response = await Services.ValidateCaptcha(captchadata);

            // if (response.status === 200) {
            //     const captchaValid = response.data.status;

            //     if (captchaValid) {
            let data = { password: formik.values.password, captchaImage: values.captcha, captchaToken: token, captchaHash: hash, randomString: randomString };
            console.log("Email", data);

            try {
                const resetResponse = await Services.ResetPassword(data, randValue);

                if (resetResponse.status === 200) {
                    await swal("Success", resetResponse.data.msg, "success");
                    formik.resetForm();
                    navigate("/login");
                }
            } catch (resetError) {
                if (resetError.response && resetError.response.data && resetError.response.data.errors) {
                    const errorMessages = Object.values(resetError.response.data.errors).join(", ");
                    swal("Message!", errorMessages, "warning");
                    GenerateCaptchaImage();
                    setFieldValue('captcha', '');
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            }
            //     } else {
            //         swal("Message!", "Please enter the correct captcha value", "info");
            //     }
            // }
            // } catch (error) {
            //     const errorDetails = error.response && error.response.data && error.response.data.errors;
            //     let displayErrorMessage = '';

            //     if (errorDetails && Object.keys(errorDetails).length > 0) {
            //         Object.keys(errorDetails).forEach((key) => {
            //             displayErrorMessage += `${key}: ${errorDetails[key]}\n`;
            //         });
            //     }

            //     swal("Message!", displayErrorMessage, "info");
            // }
        }
    });




    useEffect(() => {
        sessionStorage.setItem("flag", 0);
        const urlParams = new URLSearchParams(window.location.search);
        const randValue = urlParams.get('rand');

        if (randValue) {
            setRandValue(randValue);
        }
    }, []);

    const GenerateCaptchaImage = () => {
        Services.GenerateCaptcha().then(async response => {
            const base64Data = response.data.captchaImage; // Assuming the response contains a 'image' field with base64 data
            const token = response.data.captchaToken;
            const hash = response.data.captchaHash;
            const randomString = response.data.randomString;
            setcaptchaToken(token);
            setcaptchahash(hash);
            setrandomString(randomString);
            const mimeType = 'image/png'; // Set the correct MIME type for the image
            const byteCharacters = atob(base64Data);
            const byteArrays = [];
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }
            const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType });

            const imageUrl = URL.createObjectURL(blob);
            setImageSrc(imageUrl);

        }).catch(err => {
            swal("Message!", "Server Error", "info")
        });

    }



    useEffect(() => {
        GenerateCaptchaImage();
    }, [])



    return (
        <div>
            <div className="container wrapper-home">
                <form onSubmit={formik.handleSubmit} autocomplete="off">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="card my-4">
                                <div className="card-body cardbody-color p-lg-5">
                                    {/* <div className="text-center">
                                        <Link to="/">
                                            <img
                                                src={Quizz}
                                                className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                                                width="200px"
                                                alt="profile"
                                            />
                                        </Link>
                                    </div> */}

                                    <div>
                                        <h5 className='text-center mb-3'>Reset Password</h5>
                                    </div>
                                    {LoggedIn === "true" ? (
                                        <div>
                                            <h6 className='text-center fw-bold'>You are already logged in.</h6>

                                        </div>
                                    ) : (
                                        <>
                                            <div className="alert alert-primary fw-bold" role="alert">
                                                <ul>
                                                    <li>Password should be minimum of 8 characters.</li>
                                                    <li>Password is case sensitive.</li>
                                                    <li>Password can contain alphabets(lowercase, uppercase), numbers.</li>
                                                    <li>Password can contain special symbols !, @, #, $, %, ^, &, *</li>
                                                </ul>

                                            </div>
                                            <div className='col-md-6 offset-md-3'>
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="password"
                                                        name='password'
                                                        className={formik.touched.password && formik.errors.password ? "form-control is-invalid" : "form-control"}
                                                        id="PasswordSignup"
                                                        placeholder="Password"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password}
                                                    />
                                                    <label for="floatingInput">Password</label>
                                                    {formik.touched.password && formik.errors.password && (
                                                        <span style={{ color: "red" }}> {formik.errors.password}</span>
                                                    )}
                                                </div>

                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="password"
                                                        className={formik.touched.confirmpassword && formik.errors.confirmpassword ? "form-control is-invalid" : "form-control"}
                                                        id="ConfirmPasswordSignup"
                                                        name='confirmpassword'
                                                        placeholder="Confirm Password"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.confirmpassword}
                                                    />
                                                    <label for="floatingInput">Confirm Password</label>
                                                    {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                                                        <span style={{ color: "red" }}> {formik.errors.confirmpassword}</span>
                                                    )}
                                                </div>
                                                <div>

                                                    <div className='form-floating'>

                                                        <input type="text" className={formik.touched.captcha && formik.errors.captcha ? "form-control is-invalid" : "form-control"} name="captcha" maxLength="5"
                                                            placeholder='Captcha'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.captcha}
                                                        />
                                                        <label for="floatingInput">Captcha</label>
                                                        {formik.touched.captcha && formik.errors.captcha && (
                                                            <span style={{ color: "red" }}> {formik.errors.captcha}</span>
                                                        )}
                                                    </div>
                                                    <div className='d-flex justify-content-end my-3'>

                                                        {imageSrc && (
                                                            <>
                                                                <img src={imageSrc} alt="Image" width={200} />
                                                                <div className='border border-2'>
                                                                    <i className="fa-solid fa-2xl fa-rotate-right mt-4 mx-3" onClick={GenerateCaptchaImage}></i>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>

                                                    <div className="text-center">

                                                        <button type="submit" className="btn btn-color w-100">
                                                            Submit
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword