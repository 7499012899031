import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../src/assets/vendor/bootstrap-icons/bootstrap-icons.min.css';
import '../src/assets/vendor/remixicon/remixicon.css';
import '../src/assets/vendor/fontawesome/css/fontawesome.css';
import '../src/assets/vendor/fontawesome/css/brands.css';
import '../src/assets/vendor/fontawesome/css/solid.css';
import { GlobalLoadingProvider } from "./GlobalLoadingContext/GlobalLoadingContext";
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'aos/dist/aos.css';
// import 'aos/dist/aos.js';
import './index.css';
import '../src/assets/css/style.css'
import App from './App';
import Keycloak from "keycloak-js";
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import myInitObject from './Login/myInitObject';
import UserService from './Services/UserService';
import HttpService from './Services/HttpService';
import { createRoot } from "react-dom/client";
import { applyMiddleware, createStore } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from './KeyCloak/Keycloak';
// import Keycloak from ';
import thunk from "redux-thunk";
import axios from 'axios';
import AxiosInterceptor from './AxiosInstance/UseAxioswithKeycloak';
// import rootReducer from "./modules";
//const _kc = new Keycloak('/keycloak.json');
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <GoogleOAuthProvider clientId={myInitObject.google_clientId}>
     <ReactKeycloakProvider authClient={keycloak}>
   
    <BrowserRouter>
    
      <App />
     
    </BrowserRouter>

    </ReactKeycloakProvider>
  </GoogleOAuthProvider>

);


// reportWebVitals();


// const renderApp = () => ReactDOM.createRoot(<App/>, document.getElementById("app"));
// UserService.initKeycloak(renderApp);
// HttpService.configure();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render( 
  
//  UserService.initKeycloak(root)
 
 
// );



// const renderApp = () => ReactDOM.createRoot(document.getElementById("app")).render(<App />);

// UserService.initKeycloak(renderApp);
// <App/>

// const root = document.getElementById('root');

// // Check if the 'root' element exists
// if (root) {
//   _kc.init({ onLoad: 'login-required' }).then((authenticated) => {
//     // Render your React app or handle authentication logic
//     ReactDOM.createRoot(
//       <React.StrictMode>
//         <BrowserRouter >
//           <App />
//         </BrowserRouter>
//       </React.StrictMode>,
     
//     );
//   });
// } else {
//   console.error("The 'root' element does not exist in the DOM.");
// }



// UserService.initKeycloak(() => {
//   // This callback function will be executed after successful Keycloak initialization.
//   // You can render your React app or perform other actions here.
//   ReactDOM.render(
//     <React.StrictMode>
//       <BrowserRouter>
//       <App />
//       </BrowserRouter>
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// });







// const renderApp = () => createRoot(document.getElementById("app")).render(<App/>);

// UserService.initKeycloak(renderApp);