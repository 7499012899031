import { Routes, Route,Navigate} from "react-router-dom"
import Footer from "../Footer/Footer";
import QuizResult from "../QuizResult/QuizResult";
import UserResponse from "../UserResponse/UserResponse";
import QuizResponse from "./QuizResponse";
import jwtDecode from 'jwt-decode';
import UserDashboard from "../UserDashboard/UserDashboard";
import NavBar from "../Navbar/Navbar";
import Header from "../Header/Header";
import UserQuizDashboard from "../UserQuizDashboard/UserQuizDashboard";
import UserCTFDashboard from "../UserCTFDashboard/UserCTFDashboard";
import UserAssignedChallenges from "../UserAssignedChallenges/UserAssignedChallenges";
import AttemptedChallengesByUser from "../AttemptedChallengesByUser/AttemptedChallengesByUser";
import UserAttemptedQuizzes from "../UserAttemptedQuizzes/UserAttemptedQuizzes";
import UserUtil from "../UserUtil/UserUtil";
import UserScoreBoard from "../UserScoreBoard/UserScoreBoard";

const QuizResponseRouter = () => {
  
    // const LoggedIn= sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getCookieStorage("LoggedIn");
    let roles="";
    let isAuthenticated="";
    let isUser="";
    if (LoggedIn === "true"){
    // isAuthenticated=sessionStorage.getItem("token");
    isAuthenticated = UserUtil.getCookieStorage("token");
    roles = getRolesFromToken(isAuthenticated);
                            
    function getRolesFromToken(token) {
        // Decode the JWT token
        const decodedToken = jwtDecode(token);
    
        // Extract the roles from the 'cakesldap' resource under 'resource_access'
        const roles = decodedToken && decodedToken.resource_access && decodedToken.resource_access.cakesldap
            ? decodedToken.resource_access.cakesldap.roles
            : null;
    
        return roles;
    }
    
  console.log("getRole",roles);

  isUser = roles.includes('learner');


    }

    console.log("getUser",isUser);
    
    return LoggedIn  && isUser ? (
        <div>
            <Header />
            <Routes >
                <Route path="/" element={<QuizResponse />} />
                <Route path="/user-dashboard" element={<UserDashboard />} />
                <Route path="/:qId" element={<QuizResponse />} />
                <Route path="/result/:uId" element={<QuizResult />} />
                <Route path="/quiz-dashboard" element={<UserQuizDashboard/>} /> 
                <Route path="/quiz-attempted-challenges" element={<UserAttemptedQuizzes/>} />                
                <Route path="/ctf" element={<UserCTFDashboard/>} />
                <Route path="/ctf-challenges/:ctfId" element={<UserAssignedChallenges/>} />
                <Route path="/ctf-attempted-challenges" element={<AttemptedChallengesByUser/>} />
                <Route path="/user-scoreboard" element={<UserScoreBoard />} />
            </Routes>
            {/* <Footer /> */}
        </div>
    ) : (
        <Navigate to="/error" />
    );
}


export default QuizResponseRouter;