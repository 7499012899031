import React, { useState,useEffect } from 'react';
import Services from '../../src/Service/Service';
const NewForm = () => {
  // Initialize an empty object to store flag data
  const [flagData, setFlagData] = useState({});


// Counter for index starting from 1
let flagIndex = 1;

// Define the handleChange function
function handleChange(event) {

  const { name, value } = event.target;
  // If the name is 'flags', update the flag data object
  if (name === 'flags') {
    const caseSensitiveValue = document.querySelector('[name="isCaseSensitive"]').value;
    const isCaseSensitive = caseSensitiveValue === 'true';

    // Update the flagData state using the functional update
    setFlagData((prevFlagData) => ({
      ...prevFlagData,
      [flagIndex]: {
        flag: value,
        isCaseSensitive: isCaseSensitive,
      },
    }));
  }
        
  flagIndex++;
}

useEffect(() => {

  Services.TestApi().then(response => {
     
      console.log(response.data);
  }).catch(() => {
     
  })
}, [])


const onsubmit = () =>{
  console.log(flagData);
}

  return (
    <div className="wrapper-home">
      <div className='row'>
        <div className='col-md-8'>
          <div className='mb-2'>
            <h3>Flag</h3>
            <input
              type="text"
              className="form-control"
              name='flags' // No need for id here
              aria-describedby="emailHelp"
              onChange={handleChange}
            />
            <div className="form-text">
              Static flag for your challenge
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='mb-2'>
            <h3>Case Sensitive</h3>
            <select className="form-select" name="isCaseSensitive" aria-label="Default select example" onChange={handleChange}>
              <option value="0" selected="">Choose Case Sensitive</option>
              <option value="true">Case Sensitive</option>
              <option value="false">Case Insensitive</option>
            </select>
          </div>
        </div>
        <button onClick={onsubmit}>btn</button>
      </div>

    </div>
  )
}

export default NewForm