import Services from '../../src/Service/Service';
import { useState, useEffect, CSSProperties } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import jwtDecode from 'jwt-decode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import swal from "sweetalert"

const Ivp = () => {
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#ffffff");
    const [isLoading, setIsLoading] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        const code = window.location.href.split('=')[3]
        const body = { code }
        Services.Ivpauthorize(body).then(res => {
            // console.log("res : ", res.data)
            if (res.data.status === 1) {

                let token = res.data.data.data.access_token;  
            //et token="eyJhbGciOiJSUzUxMiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJxU0FTZzRGd0QzdmZQX1pjUTlOSGczUEJudWd6TlQ1cFhmRS0zT1BJYVM4In0.eyJleHAiOjE2OTcxMTI0MDUsImlhdCI6MTY5NzEwODgwNiwiYXV0aF90aW1lIjoxNjk3MTA4NjIwLCJqdGkiOiIxMDkwM2I4ZC01YThjLTQ3NmYtODU1Mi0zMjc3MTEyMmU0MDgiLCJpc3MiOiJodHRwOi8vZGhhbmFuamF5Lmh5ZGVyYWJhZC5jZGFjLmluOjgwODAvYXV0aC9yZWFsbXMvY2RhYzIwMjIiLCJhdWQiOlsicmVhbG0tbWFuYWdlbWVudCIsImFjY291bnQiXSwic3ViIjoiNDM4Njc0MzgtYmVjMy00OWRjLWJmNDgtOGQ0ZmFhNDBlMTkzIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidmxhYiIsInNlc3Npb25fc3RhdGUiOiJjMDU4ZjYxZC02YzVhLTQ0ZjgtOTAwMS03NDk3MDhmZjA4NzUiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly8xMC4yNDQuMS42MTo4MDgwIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLWNkYWMyMDIyIiwiYWRtaW4iLCJ1c2VyIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicmVhbG0tbWFuYWdlbWVudCI6eyJyb2xlcyI6WyJ2aWV3LWlkZW50aXR5LXByb3ZpZGVycyIsInZpZXctcmVhbG0iLCJtYW5hZ2UtaWRlbnRpdHktcHJvdmlkZXJzIiwiaW1wZXJzb25hdGlvbiIsInJlYWxtLWFkbWluIiwiY3JlYXRlLWNsaWVudCIsIm1hbmFnZS11c2VycyIsInF1ZXJ5LXJlYWxtcyIsInZpZXctYXV0aG9yaXphdGlvbiIsInF1ZXJ5LWNsaWVudHMiLCJxdWVyeS11c2VycyIsIm1hbmFnZS1ldmVudHMiLCJtYW5hZ2UtcmVhbG0iLCJ2aWV3LWV2ZW50cyIsInZpZXctdXNlcnMiLCJ2aWV3LWNsaWVudHMiLCJtYW5hZ2UtYXV0aG9yaXphdGlvbiIsIm1hbmFnZS1jbGllbnRzIiwicXVlcnktZ3JvdXBzIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiJjMDU4ZjYxZC02YzVhLTQ0ZjgtOTAwMS03NDk3MDhmZjA4NzUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInJvbGUiOiJhZG1pbiIsIm5hbWUiOiJhZnRhYiBrYXppIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiY2RhY0BjZGFjLmluIiwiZ2l2ZW5fbmFtZSI6ImFmdGFiIiwiZmFtaWx5X25hbWUiOiJrYXppIiwiZW1haWwiOiJjZGFjQGNkYWMuaW4ifQ.S0H8bm2UQM2UNXsjz4kdqCZLS3mTEJa6_QJzzYyL2YRdTmL5VwWqpZvHYLH3w_ya2xp6c2ad2aBKIwbrY1jj1kZK-qZEJtkekeExI75gMShozUubgSskddcnJ4HJXh0oHaYZLYS1Wa--NTj8bJNIAjiTJSm_JEG16T9vLGoofgpqIpEZcMFkf_rgGZA4ezX7aqfV20q19BeEf3XLI9M-NfEH3O04sUFo938X_wTIiPqkxCF5sJ6Na5cA7ux1NIj7XRNN1VLpGoV9nc5MSzpxDanVDZoluFo0gyLOn5OzxI2APMlwvA21dvRtiJmhftQVaEqbcnBqH_eneFnWZyLFlQ"
                const data = {}
                // console.log("ghgh", token);
                Services.Userinfo(token)
                    .then(response => {
                        if (response.status === 200) {
                            let bodyjson = { email: response.data.data.email, name: response.data.data.username,unique_id:response.data.data.unique_id };

                            Services.IvpLogin(bodyjson)
                                .then(response => {
                                    setTimeout(() => {
                                    if (response.status === 200) {

                                        sessionStorage.setItem("token", response.data.id);
                                        sessionStorage.setItem("LoggedIn", true);
                                        const flag = sessionStorage.getItem("flag");
                                        const token = sessionStorage.getItem("token");
                                        const username = getUsernameFromToken(token);
                                        const roles = getRolesFromToken(token);

                                        function getUsernameFromToken(token) {
                                            // Decode the JWT token
                                            const decodedToken = jwtDecode(token);

                                            // Extract the username from the decoded token
                                            const username = decodedToken ? decodedToken.sub : null;

                                            return username;
                                        }

                                        function getRolesFromToken(token) {
                                            // Decode the JWT token
                                            const decodedToken = jwtDecode(token);

                                            // Extract the roles from the decoded token
                                            const roles = decodedToken ? decodedToken.roles : null;

                                            return roles;
                                        }

                                        if (roles === '[ROLE_ADMIN]') {
                                            navigate('/dashboard');
                                            window.location.reload();
                                        } else if (roles === '[ROLE_USER]') {
                                            navigate(`/quiz/user-dashboard`);
                                             window.location.reload();
                                        } 
                                    
                                } 
                                setIsLoading(false);
                            }, 2000);
                        })
            }

        }).catch(err => {
            if (err.response && err.response.status === 401) {
                swal("Error", err.response.data.error, "error");
            } else if (err.response && err.response.data && err.response.data.errors) {
                let errorMessages = Object.values(err.response.data.errors).join('\n');
                swal("Message!", errorMessages, "warning");                
                //setFieldValue('captcha', '');
            } else if (err.response && err.response.data && err.response.data.msg) {
                swal("Message!", err.response.data.msg, "warning");
            } else {
                swal("Message!", "Server error occurred", "warning");
            }
        })

    }
        })

    }, []);

return (
    isLoading ? <Spinner /> : 
   <div></div>
)
}

export default Ivp;