import '../assets/Style.css'
import '../assets/css/style.css'
import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import jwtDecode from 'jwt-decode';
import Badge from 'react-bootstrap/Badge';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import certificate from '../assets/img/certificate.png'


const UserQuizDashboard = () => {
    let navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useState('AssignedQuizzes');
    const [show1, setShow1] = useState(false);
    const [listUserResponse, setlistUserResponse] = useState([]);
    const [quizData, setQuizData] = useState([]);
    const [pdfData, setPdfData] = useState(null);
    const [error, setError] = useState(null);
    const [getCertificateName, setCertificateName] = useState("");
    const [getQuizName, setQuizName] = useState("");
    const handleClose1 = () => {
        setShow1(false);

    }
    const handleShow1 = () => setShow1(true);




    const LoggedIn = sessionStorage.getItem("LoggedIn");
    let email = "";
    let username = "";
    if (LoggedIn === "true") {
        const token = sessionStorage.getItem("token");
        email = getEmailFromToken(token);
        username = getUsernameFromToken(token);

        function getEmailFromToken(token) {

            const decodedToken = jwtDecode(token);

            const email = decodedToken ? decodedToken.sub : null;

            return email;
        }


        function getUsernameFromToken(token) {

            const decodedToken = jwtDecode(token);

            const username = decodedToken ? decodedToken.username : null;

            return username;
        }

    }







    const onCertificatePreview = (certFileName) => {
        console.log("Name", certFileName);
        setShow1(true);

        if (certFileName !== "File not found" && certFileName !== "notissued") {
            Services.GetCertificate(certFileName)
                .then(response => {
                    const data = response.data.Certificate;
                    setPdfData(data);
                })
                .catch(error => {
                    if (error.response && error.response.status === 500) {
                        swal("Server Error", error.response.data.msg, "error");
                    } else {
                        const errorMsg = error.response.data.msg;
                        const errors = error.response.data.errors;
                        let errorMessage = errorMsg + "\n\n";

                        for (const key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                errorMessage += key + ": " + errors[key] + "\n";
                            }
                        }

                        swal("Error occurred", errorMessage, "warning");
                    }
                });
        } else {
            setError("Error while generating Certificate")
            // let formData = new FormData();
            // formData.append("quizName", JSON.stringify(getQuizName));
            // formData.append("userName",JSON.stringify( username));
            // formData.append("certFileName", JSON.stringify(certFileName));

            // Services.GenerateCertificate(formData)
            //     .then(response => {
            //      console.log("generated");
            //     })
            //     .catch(error => {
            //         if (error.response && error.response.status === 500) {
            //             swal("Server Error", error.response.data.msg, "error");
            //         } else {
            //             const errorMsg = error.response.data.msg;
            //             const errors = error.response.data.errors;
            //             let errorMessage = errorMsg + "\n\n";

            //             for (const key in errors) {
            //                 if (errors.hasOwnProperty(key)) {
            //                     errorMessage += key + ": " + errors[key] + "\n";
            //                 }
            //             }

            //             swal("Error occurred", errorMessage, "warning");
            //         }
            //     });
        }

    }



    const renderNoCertificate = (props) => (
        <Tooltip id="noCertificate" {...props}>
            No Certificate issued
        </Tooltip>
    );

    const customStyles = {

        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: '500',
                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: 'darkgray',
                color: 'black'
            },
        },
    };

    const columns = [
        {
            name: 'Sr No',
            selector: (row, index) => index + 1,
            center: true,

        },
        {
            name: 'Quiz Name',
            selector: (row) => row.selectedQuiz,
            cell: row => <span className="text-capitalize">{row.selectedQuiz}</span>,
            center: true,
        },

        {
            name: 'Total Questions',
            selector: (row) => row.totalQuestions,
            center: true,
        },
        {
            name: 'Attempted',
            selector: (row) => row.attempted,
            center: true,
        },
        {
            name: 'Unattempted',
            selector: (row) => row.unattempted,
            center: true,
        },
        {
            name: 'Correct',
            selector: (row) => row.correct,
            center: true,
        },
        {
            name: 'Wrong',
            selector: (row) => row.wrong,
            center: true,
        },
        // {
        //     name: 'Total Marks',
        //     selector: (row) => row.totalMarks,
        // },
        {
            name: 'Total Score',
            selector: (row) => row.totalScore,
            center: true,
        },
        {
            name: 'Status',
            selector: (row) => (
                <Badge pill bg={row.status === 'passed' ? 'success' : 'danger'}>
                    {row.status}
                </Badge>
            ),
            center: true,
        },

        {
            name: 'Certificate',
            selector: (row) => {
                if (row.status === 'passed') {
                    return <img src={certificate} width={50} height={50} className='p-2' onClick={() => onCertificatePreview(row.certFileName)} />;
                } else {

                    return (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderNoCertificate}
                        >
                            <i className="ri-error-warning-fill ri-2x"></i>
                        </OverlayTrigger>
                    );

                }
            },
            center: true,
        },

    ]





    useEffect(() => {
        $(document).ready(function () {
            $(".btn-toggle-menu").click(function () {
                console.log("Button clicked"); // Debugging line
                $("#wrapper").toggleClass("toggled");
            });
        });
    }, []);


    // useEffect(() => {

    //     if (selectedMenu === 'AttemptedQuizzes') {

    //         Services.GetUserResponse()
    //             .then(response => {
    //                 setlistUserResponse(response.data);
    //                 const responseData = response.data;
    //                 const certFileName = responseData[0]?.certFileName;
    //                 const QuizName = responseData[0]?.selectedQuiz;
    //                 setCertificateName(certFileName);
    //                 setQuizName(QuizName);
    //             }).catch(err => {
    //                 if (err.response && err.response.status === 401) {
    //                     const errorMessage = err.response.data.errors.UnAuthorized;

    //                     swal("Message!", errorMessage, "warning");
    //                     Services.Logout()
    //                         .then(response => {
    //                             if (response.status === 200) {
    //                                 sessionStorage.removeItem("token");
    //                                 sessionStorage.removeItem("LoggedIn");
    //                                 navigate("/");
    //                             }
    //                         })
    //                         .catch(err => {
    //                             // Handle error if needed
    //                         });
    //                 }
    //             })

    //     }

    // }, [selectedMenu]);

const handleCardClick = (quizId) =>{
    sessionStorage.setItem("refreshKey",quizId);
    navigate(`/quiz/${quizId}`);

}


    useEffect(() => {


        if (selectedMenu === 'AssignedQuizzes') {

            Services.GetQuizByWhitelistedEmails()
                .then(response => {
                    console.log("Response", response.data);

                  
                    setQuizData(response.data);
                }).catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        Services.Logout()
                            .then(response => {
                                if (response.status === 200) {
                                    sessionStorage.removeItem("token");
                                    sessionStorage.removeItem("LoggedIn");
                                    navigate("/");
                                }
                            })
                            .catch(err => {
                                // Handle error if needed
                            });
                    }
                })

        }


    }, [selectedMenu]);


    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container">

                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li aria-current="page">
                            <Link to="/quiz/user-dashboard">
                                Dashboard
                            </Link>
                        </li>
                        <li>Quiz</li>

                    </ol>
                    <h2>Assigned Quizzes</h2>


                </div>
            </section>
            <div className='container-fluid'>

                <div id="wrapper" className="wrapper-content">

                    <div id="sidebar-wrapper">
                        <ul className="sidebar-nav">
                            <li className="sidebar-brand">
                                DashBoard
                            </li>
                            <li className={selectedMenu === 'AssignedQuizzes' ? 'active' : ''}>
                                <a href="#" onClick={() => setSelectedMenu('AssignedQuizzes')} className='m-3'>Assigned Quizzes</a>
                            </li>
                            <li className={selectedMenu === 'AttemptedQuizzes' ? 'active' : ''}>
                                <a href="#" onClick={() => { navigate("/quiz/quiz-attempted-challenges") }} className='m-3'>Attempted Quizzes</a>
                            </li>
                        </ul>
                    </div>

                    <div id="page-content-wrapper">
                        <nav className="navbar navbar-default">
                            <div className="container-fluid">
                                <div className="navbar-header py-3">
                                    <button
                                        className="btn-menu btn btn-secondary btn-toggle-menu"
                                        type="button"
                                    >
                                        <i className="fa fa-bars" />
                                    </button>
                                </div>
                            </div>
                        </nav>
                      

                        <div className="col-lg-12">
                            {selectedMenu === 'AssignedQuizzes' ? (
                                <div id="assigned-quiz-cards" className="assigned-quiz-cards">
                                    <div className="container">
                                        <div className="row">
                                            {quizData.length > 0 ? (
                                                quizData.map((quiz, index) => (
                                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"  key={quiz.quizId}  onClick={() => handleCardClick(quiz.quizId)}>
                                                        
                                                            <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>

                                                                <h4 className="title my-2">
                                                                    <a href="">{quiz.quizTitle}</a>
                                                                </h4>
                                                                <div className="quiz-info d-flex fw-bold text-black text-center my-4">
                                                                    <div className="questions border-end w-50 hover-effect">
                                                                        <p className="w-100 mb-0">{quiz.quizSize}</p>
                                                                        <small className="text-secondary hover-effect">Questions</small>
                                                                    </div>
                                                                    <div className="timer w-50 hover-effect">
                                                                        <p className="mb-0">{quiz.totalDuration} &nbsp;<small className="text-secondary hover-effect">min</small></p>
                                                                        <small className="text-secondary hover-effect">Duration</small>
                                                                    </div>
                                                                </div>
                                                                <div className="quiz-info d-flex fw-bold text-black text-center my-2 p-2">
                                                                    <div className="questions border-end w-50">
                                                                        <small className="w-100 mb-0 text-primary hover-effect">{quiz.startDate}</small>
                                                                        <div>
                                                                            <small className="text-secondary hover-effect">Start Date</small>
                                                                        </div>
                                                                    </div>

                                                                    <div className="timer w-50">
                                                                        <small className="w-100 mb-0 text-primary hover-effect">{quiz.endDate}</small>
                                                                        <div>
                                                                            <small className="text-secondary hover-effect">End Date</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                      
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="col-lg-12">
                                                    <div className="alert alert-info text-center">
                                                        <h6 className='fw-bold'>No assigned quizzes available.</h6>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                    </div>
                </div>

            </div>



            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-danger'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='fw-bold fs-5 mt-3 text-dark text-center'>
                    <div className="text-center">
                        {pdfData ? (
                            !error ? (
                                <embed src={pdfData} type="application/pdf" width="350px" height="300px" />
                            ) : (
                                <p className="fs-5 text-danger">{error}</p>
                            )
                        ) : (
                            <p className="fs-5 text-danger">Certificate has not been issued.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>




        </div>
    )
}

export default UserQuizDashboard