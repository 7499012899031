import axios from "axios"
import { useState, useEffect, useRef } from 'react'
import swal from "sweetalert"
import Services from '../../src/Service/Service';
import { useParams } from 'react-router-dom'
import API_BASE_URL from '../config/config';
import PDFViewer from 'pdfviewer';
import { Document, Page } from 'react-pdf';
// import { PDFViewer } from 'react-pdf';
import pdf from "../File/dummy.pdf"

const QuizResult = () => {
    const { uId } = useParams();
    const [listResponse, setlistResponse] = useState({});
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfData, setPdfData] = useState(null);
    const [error, setError] = useState(null);
    const [numPages, setNumPages] = useState(0);
    let token = sessionStorage.getItem("token");


    useEffect(() => {
        // Services.GetResult(uId)
        //     .then(response => {
        //         setlistResponse(response.data);
        //         let certificateName = response.data.certFileName;
        //         let Status = response.data.status;

        //         if (Status === "passed" && certificateName !== "File not found") {
        //             Services.GetCertificate(certificateName)
        //                 .then(response => {
        //                     const data = response.data.Certificate;
        //                     setPdfData(data);
        //                 })
        //                 .catch(error => {
        //                     if (error.response && error.response.status === 500) {
        //                         swal("Server Error", error.response.data.msg, "error");
        //                     } else {
        //                         const errorMsg = error.response.data.msg;
        //                         const errors = error.response.data.errors;
        //                         let errorMessage = errorMsg + "\n\n";

        //                         for (const key in errors) {
        //                             if (errors.hasOwnProperty(key)) {
        //                                 errorMessage += key + ": " + errors[key] + "\n";
        //                             }
        //                         }

        //                         swal("Error occurred", errorMessage, "warning");
        //                     }
        //                 });

        //         } else {
        //             setError("Error while generating certificate")
        //         }
        //     })
        //     .catch(error => {
        //         console.error("Failed to fetch result:", error);
        //     });
        Services.GetResult(uId)
        .then(response => {
          setlistResponse(response.data);
          let certificateName = response.data.certFileName;
          console.log("namads",certificateName)
          let Status = response.data.status;
      
          if (Status === "passed") {
            if (certificateName !== "File not found" && certificateName !== "notissued") {
              Services.GetCertificate(certificateName)
                .then(response => {
                  const data = response.data.Certificate;
                  setPdfData(data);
                })
                .catch(error => {
                  if (error.response && error.response.status === 500) {
                    swal("Server Error", error.response.data.msg, "error");
                  } else {
                    const errorMsg = error.response.data.msg;
                    const errors = error.response.data.errors;
                    let errorMessage = errorMsg + "\n\n";
      
                    for (const key in errors) {
                      if (errors.hasOwnProperty(key)) {
                        errorMessage += key + ": " + errors[key] + "\n";
                      }
                    }
      
                    swal("Error occurred", errorMessage, "warning");
                  }
                });
            } else {
              // Display some other text in the UI when the certificate is not found
              // Example:
             setError("Error while generating Certificate") // Reset PDF data to null if needed
              // setOtherText("Certificate not found"); // Set some other state for displaying the text
            }
          } else {
            // Handle other status scenarios if needed
          }
        })
        .catch(error => {
          console.error("Failed to fetch result:", error);
        });
      


    }, []);


    return (
        <div className="container quiz-score wrapper-home">
            <div className="col-md-12">
                <div className="alert alert-info mt-4" role="alert">
                    <h3 className="text-center">Quiz Result</h3>
                </div>

                <div className="alert alert-warning mt-4" role="alert">
                    <h5 className="text-center">Total Questions: {listResponse.totalQuestions}</h5>
                    <h5 className="text-center">Unattempted Questions: {listResponse.unattempted}</h5>
                    <h5 className="text-center">Attempted Questions: {listResponse.attempted}</h5>
                    <h5 className="text-center">Correct Answers: {listResponse.correct}</h5>
                    <h5 className="text-center">Wrong Answers: {listResponse.wrong}</h5>
                    <h5 className="text-center">Percentage: {listResponse.percentage} %</h5>
                    {listResponse.status === "passed" ? <h2 className='text-success fw-semibold text-center mt-2'>Congratulations!! You Passed The Quiz</h2> : <h2 className='text-danger fw-semibold text-center mt-2'>Sorry!! You Failed The Quiz</h2>}

                    <div className="text-center">
                        {pdfData ? (
                            !error ? (
                                <embed src={pdfData} type="application/pdf" width="350px" height="300px" />
                            ) : (
                                <p className="fs-5 text-danger">{error}</p>
                            )
                        ) : (
                            <p className="fs-5 text-danger">Certificate has not been issued.</p>
                        )}
                    </div>

                </div>
            </div>
        </div >
    )
}

export default QuizResult